import {
  AirDisruptionGuards,
  CartGuards,
  CfarGuards,
  AirChfarGuards,
  ContactInformationGuards,
  FlightPassengerGuards,
  AirPFExerciseGuards,
  ReviewGuards,
  SeatGuards,
  getObjectKeysAsObject,
  CardPaymentGuards,
  WalletGuards,
  RewardsPaymentGuards,
} from "@capone/checkout";
import { PackagesCommonGuards } from "./common";

export const Guards = {
  ...AirDisruptionGuards,
  ...CartGuards,
  ...CfarGuards,
  ...AirChfarGuards,
  ...ContactInformationGuards,
  ...FlightPassengerGuards,
  ...AirPFExerciseGuards,
  ...ReviewGuards,
  ...SeatGuards,
  ...CardPaymentGuards,
  ...RewardsPaymentGuards,
  ...WalletGuards,
  ...PackagesCommonGuards,
};

export const GuardTypes = getObjectKeysAsObject(Guards);
