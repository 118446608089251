import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { IStoreState } from "../../../../reducers/types";
import { ChangeItineraryModal } from "./component";
import {
  getHotelShopChosenProduct,
  getPackageSelectedLodging,
  getSelectedPackageByLodgingId,
} from "../../../hotel-shop/reducer";
import {
  getDepartureDate,
  getDestination,
  getFareclassOptionFilter,
  getOrigin,
  getReturnDate,
  getStopsOption,
  getTravelers,
} from "../../../search/reducer";
import { getPackageHotelQueryParams } from "../../../availability/reducer";
import { setPackagesFlightShopProgress } from "../../../flight-shop/actions/actions";

const mapStateToProps = (state: IStoreState) => {
  return {
    selectedLodging: getPackageSelectedLodging(state),
    returnDate: getReturnDate(state),
    departureDate: getDepartureDate(state),
    chosenProduct: getHotelShopChosenProduct(state),
    selectedPackageByLodging: getSelectedPackageByLodgingId(state),
    searchedLocation: getDestination(state),
    packageHotelQueryParams: getPackageHotelQueryParams(state),
    origin: getOrigin(state),
    travelers: getTravelers(state),
    stopsOptions: getStopsOption(state),
    fareClassOptionFilter: getFareclassOptionFilter(state),
  };
};

export const mapDispatchToProps = { setPackagesFlightShopProgress };

const connector = connect(mapStateToProps, mapDispatchToProps);
export type ChangeItineraryModalConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedChangeItineraryModal = withRouter(
  connector(ChangeItineraryModal)
);
