import React from "react";
import { twoDecimalFormatter } from "halifax";

export const HEADER_MAIN_TEXT = "Confirm and book";
export const HEADER_SUB_TEXT = "Enter all required details";

export const FLIGHT_TRAVELERS_HEADING = "Step 1: Who's flying?";
export const HOTEL_TRAVELERS_HEADING = "Step 2: Who’s checking in?";
export const SEAT_SELECTION_HEADING = "Step 3: Seat selection";
export const PAYMENT_HEADING = "Step 4: Rewards and payment";
export const PAYMENT_SUBHEADING =
  "Combine travel credits, a travel offer, rewards, and the payment method you add to book this trip.";

export const LOADING_TEXT = "Loading";

export const CHANGE_CTA_TEXT = "Change";

export const SELECTED_SEATS_COMPLETED_TEXT = (
  numPassengers: number,
  seatsTotal: number
) => (
  <>
    You’ve reserved seats for ({numPassengers}) passenger
    {numPassengers === 1 ? "" : "s"} for{" "}
    <strong>${twoDecimalFormatter(seatsTotal)}</strong>
  </>
);
export const SKIPPED_SEATS_COMPLETED_TEXT = "You've skipped seat selection";
export const SEAT_SELECTION_NOT_AVAILABLE =
  "Seat selection is currently unavailable for your journey.";
