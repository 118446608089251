import { LocationQueryEnum, ILocationQueryLabel } from "redmond";
import { delay, put } from "redux-saga/effects";
import Logger from "../../../utils/logger";
import { actions } from "../actions";
import { fetchHotelAutocomplete } from "../../../api/v0/search/fetchHotelAutocomplete";

export function* fetchDistanceFromLocations(
  action: actions.IFetchDistanceFromLocationCategories
) {
  yield delay(300);
  try {
    const requestBody: ILocationQueryLabel = {
      LocationQuery: LocationQueryEnum.Label,
      l: action.queryString,
    };

    const { categories } = yield fetchHotelAutocomplete(requestBody);
    yield put(actions.setDistanceFromLocationCategories(categories));
  } catch (e) {
    Logger.debug(e);
    yield put(actions.setDistanceFromLocationCategories([]));
  }
}
