import React from "react";
import {
  FlightPassengerSelectors,
  FlightSelectors,
  GordianSeatSegment,
  SeatSelection,
  SeatSelectors,
  useCheckoutStateSelector,
  GordianSeatWithRewards,
  useCheckoutState,
  SeatEventTypes,
} from "@capone/checkout";
import { personToIPerson } from "../../../../utils/personToIPerson";
import { RouteComponentProps } from "react-router-dom";
import { PackagesSeatSelectionConnectorProps } from "./container";
import { convertUsdToAllRewards } from "../../../../api/v0/rewards/convertUsdToAllRewards";
import { TEvent } from "../../state/events";
import { PackagesMachineContext } from "../../state/types";
import { PATH_HOME } from "../../../../utils/paths";
import { ActionButton } from "halifax";
import {
  CONTINUE_CTA_TEXT,
  SEAT_SELECTION_VALIDATION_MESSAGE,
} from "./textConstants";
import { PackagesPriceBreakdown } from "../PriceBreakdown";
import { Typography } from "@material-ui/core";

export interface IPackagesSeatSelectionProps
  extends RouteComponentProps,
    PackagesSeatSelectionConnectorProps {
  isMobile?: boolean;
  hasValidationError?: boolean;
  setHasValidationError?: (hasError: boolean) => void;
}

export const PackagesSeatSelection = ({
  selectedRewardsAccountId,
  history,
  isMobile,
  hasValidationError,
  setHasValidationError,
}: IPackagesSeatSelectionProps) => {
  const [_state, send] = useCheckoutState<TEvent, PackagesMachineContext>();

  const tripDetails = useCheckoutStateSelector(
    FlightSelectors.getSelectedTripDetailsParent
  );
  const airportMap = useCheckoutStateSelector(FlightSelectors.getAirportMap);
  const passengers = useCheckoutStateSelector(
    FlightPassengerSelectors.getAllSelectedUserPassengersParent
  );

  const isLoading = useCheckoutStateSelector(
    SeatSelectors.getIsSeatSelectionLoading
  );
  const selectedSeats = useCheckoutStateSelector(
    SeatSelectors.getSelectedSeats
  );
  const cheapestSeat = useCheckoutStateSelector(SeatSelectors.getCheapestSeat);
  const seatMapHtml = useCheckoutStateSelector(SeatSelectors.getSeatMapHtml);
  const totalSeatPricing = useCheckoutStateSelector(
    SeatSelectors.getSeatTotalPricing
  );
  const skippedSeatSelection = useCheckoutStateSelector(
    SeatSelectors.getSkipSeatSelection
  );
  const seatMapAvailability = useCheckoutStateSelector(
    SeatSelectors.getSeatMapAvailability
  );
  const seatSlices = useCheckoutStateSelector(SeatSelectors.getSeatSlices);
  const seatSelectionErrorModalOpen = useCheckoutStateSelector(
    SeatSelectors.getSeatSelectionErrorOpen
  );
  const seatSelectionError = useCheckoutStateSelector(
    SeatSelectors.getSeatSelectionError
  );

  const handleSetSelectedSeats = (
    seats: GordianSeatWithRewards[],
    seatSegments: GordianSeatSegment[]
  ) => send({ type: SeatEventTypes.SELECT_SEATS, seats, seatSegments });

  const handleSkipSeatSelection = () =>
    send({ type: SeatEventTypes.SKIP_SEAT_SELECTION });

  const searchAgain = () => {
    history.push(PATH_HOME);
  };

  const canContinue =
    isMobile || !!selectedSeats.length || skippedSeatSelection;

  const handleContinue = () => {
    if (canContinue) {
      setHasValidationError?.(false);
      send({ type: SeatEventTypes.NEXT });
    } else {
      setHasValidationError?.(true);
    }
  };

  const handleBack = () => {
    send({ type: SeatEventTypes.PREVIOUS });
  };

  React.useEffect(() => {
    if (canContinue) {
      setHasValidationError?.(false);
    }
  }, [canContinue]);

  return (
    <>
      <SeatSelection
        tripDetails={tripDetails}
        airports={airportMap || {}}
        passengers={passengers.map(personToIPerson)}
        seatSelectionLoading={isLoading}
        selectedSeats={selectedSeats}
        cheapestSeat={cheapestSeat}
        seatMapHtml={seatMapHtml}
        totalSeatPricing={totalSeatPricing}
        skippedSeatSelection={skippedSeatSelection}
        seatMapAvailability={seatMapAvailability}
        selectedRewardsAccountId={selectedRewardsAccountId || undefined}
        seatSlices={seatSlices || []}
        seatSelectionErrorModalOpen={seatSelectionErrorModalOpen}
        seatSelectionError={seatSelectionError}
        setSelectedSeats={handleSetSelectedSeats}
        handleSearchAgain={searchAgain}
        handleSkipSeatSelection={handleSkipSeatSelection}
        convertUsdToAllRewards={convertUsdToAllRewards}
        isMobile={isMobile}
        mobileHeaderElement={<PackagesPriceBreakdown isMobile dropdown />}
        onContinue={handleContinue}
        onBack={handleBack}
      />
      {hasValidationError && (
        <Typography className="seat-selection-validation-message">
          {SEAT_SELECTION_VALIDATION_MESSAGE}
        </Typography>
      )}
      {!isMobile && (
        <ActionButton
          onClick={handleContinue}
          message={CONTINUE_CTA_TEXT}
          className="packages-continue-button seats"
        />
      )}
    </>
  );
};
