import dayjs from "dayjs";
import {
  formatInterval,
  IFlightSummaryPanelProps,
  removeTimezone,
} from "halifax";
import { Airport, TripDetails } from "redmond";
import { REVIEW_CARD_HEADER_TEXT, STOPS_STRING } from "./textConstants";
import { getPlusDays } from "@capone/checkout";

export const getSliceDetails = (
  isOutgoing: boolean,
  isMobile: boolean,
  airports: { [key: string]: Airport },
  tripDetails: TripDetails
): IFlightSummaryPanelProps | undefined => {
  const slice = tripDetails.slices.find((s) => s.outgoing === isOutgoing);
  if (!slice) {
    return undefined;
  }

  const { bold, standard } = REVIEW_CARD_HEADER_TEXT(
    isOutgoing,
    airports[slice.destinationCode]
      ? airports[slice.destinationCode].cityName
      : slice.destinationName,
    removeTimezone(slice.departureTime)
  );

  return {
    airlineCode: slice.segmentDetails[0].airlineCode,
    airline: slice.segmentDetails[0].airlineName,
    departureDescriptionBold: bold,
    departureDescription: standard,
    formattedDepartureTime: dayjs(removeTimezone(slice.departureTime)).format(
      "h:mm A"
    ),
    formattedArrivalTime: dayjs(removeTimezone(slice.arrivalTime)).format(
      "h:mm A"
    ),
    duration: formatInterval(slice.totalDurationMinutes ?? 0),
    stopString: STOPS_STRING(slice.stops),
    plusDays: getPlusDays({
      ...slice,
      zonedDepartureTime: slice.zonedDepartureTime || "",
      segmentDetails: slice.segmentDetails.map((details) => ({
        ...details,
        zonedDepartureTime: details.zonedDepartureTime || "",
        zonedArrivalTime: details.zonedArrivalTime || "",
        plusDays: details.plusDays || 0,
      })),
      totalDurationMinutes: slice.totalDurationMinutes || 0,
    }),
    isMobile,
  };
};

export const postBookingSurveyPageId = () => {
  return window.__mclean_env__.ENV === "production"
    ? "prod-capital-one-travel-web-embed"
    : "test-capital-one-travel-web-embed";
};
