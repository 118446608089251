import { put, putResolve, select } from "redux-saga/effects";
import { actions } from "../actions";
import Logger from "../../../utils/logger";
import { FetchFlightTripDetailsResponse, PackagedLodging } from "redmond";
import { fetchPackagesTripDetails } from "../../../api/v0/flight-shop/fetchPackagesTripDetails";
import { getSelectedPackageByLodgingId } from "../../hotel-shop/reducer";
import { IStoreState } from "../../../reducers/types";

export default function* fetchPackagesTripDetailsSaga({
  tripId,
}: actions.IFetchPackagesTripDetails) {
  try {
    const state: IStoreState = yield select();
    let selectedPackageByLodgingId: PackagedLodging | undefined =
      yield getSelectedPackageByLodgingId(state);

    const shopRequestId = selectedPackageByLodgingId?.opaqueShopRequest;
    const shopPackageId =
      selectedPackageByLodgingId?.packageDetails.opaquePackageToken;

    if (!shopRequestId || !shopPackageId) {
      throw new Error("Shop Request Id must be present.");
    }

    const tripDetailsResults: FetchFlightTripDetailsResponse =
      yield fetchPackagesTripDetails(tripId, shopPackageId);

    yield putResolve(actions.setPackagesTripDetails(tripDetailsResults));
  } catch (e) {
    Logger.debug(e);
    yield put(actions.setPackagesTripDetailsCallStateFailed(e));
  }
}
