import {
  CardPaymentEventTypes,
  CardPaymentServices,
  CartQuoteGuardTypes,
  ContactEventType,
  FlightPassengerEventTypes,
  GenericChildState,
  ParentState,
  PassengerInformationChildState,
  PassengerInformationState,
  PaymentInformationChildState,
  PaymentInformationState,
  RewardsPaymentEventTypes,
  WalletEventTypes,
  getCardPaymentMachine,
  getCartFulfillMachine,
  getCartQuoteMachine,
  getCartUpdateMachine,
  getContactInformationMachine,
  getFlightPassengerInformationMachine,
  getPassportMachineState,
  getPriceFreezeMachineState,
  getReviewMachineState,
  getRewardsPaymentMachine,
  getSeatMachine,
  getWalletMachine,
} from "@capone/checkout";
import { MachineConfig } from "xstate";
import { ActionTypes } from "./actions";
import {
  addPackageDiscount,
  addPackageProduct,
  setPaymentFulfillParams,
} from "./actions/common";
// import { getAirDisruptionMachine } from "./childStateMachine/AirDisruption";
import { Event, TEvent } from "./events";
import { getInitialPackagesMachineContext } from "./initialContext";
import { Services } from "./services";
import { PackagesMachineContext } from "./types";
import { LodgingActionTypes } from "./actions/lodging";
import { PackagesCommonGuards } from "./guards/common";

export const packagesMachineId = "capone-packages-checkout";

const originalCardPaymentMachine = getCardPaymentMachine({
  parentMachineId: packagesMachineId,
  nextState: `#${packagesMachineId}.${ParentState.cartUpdateBeforeFulfill}`,
  previousState: `#${packagesMachineId}.${ParentState.seatSelection}`,
});
const cardPaymentMachine = {
  ...originalCardPaymentMachine,
  states: {
    ...originalCardPaymentMachine.states,
    [PaymentInformationState.add]: {
      initial: PaymentInformationChildState.idle,
      states: {
        [PaymentInformationChildState.idle]: {},
        [PaymentInformationChildState.verify]: {
          invoke: {
            src: CardPaymentServices.verifyPayment,
            onDone: {
              actions: [ActionTypes.setPaymentMethodId],
              target: `#${packagesMachineId}.${ParentState.cardPayment}.${PaymentInformationState.idle}`,
            },
            onError: {
              actions: ActionTypes.setPaymentError,
              target: `#${packagesMachineId}.${ParentState.cardPayment}.${PaymentInformationState.error}`,
            },
          },
        },
      },
    },
  },
  on: {
    ...originalCardPaymentMachine.on,
    [CardPaymentEventTypes.NEXT]: [
      {
        target: `#${packagesMachineId}.${ParentState.cartUpdateBeforeFulfill}`,
        cond: PackagesCommonGuards.isDesktop,
      },
      {
        target: `#${packagesMachineId}.${ParentState.review}`,
        cond: PackagesCommonGuards.isMobile,
      },
    ],
  },
};

export const packagesBookStateMachine: MachineConfig<
  PackagesMachineContext,
  any,
  TEvent
> = {
  schema: {
    context: getInitialPackagesMachineContext(),
    events: {} as TEvent,
  },

  // Machine identifier
  id: packagesMachineId,

  // Initial state
  initial: ParentState.loading,

  // State definitions

  states: {
    [ParentState.priceFreeze]: {
      ...getPriceFreezeMachineState({
        parentMachineId: packagesMachineId,
        nextState: `#${packagesMachineId}.${ParentState.loading}`,
      }),
    },
    [ParentState.loading]: {
      initial: "listPaymentMethods",
      states: {
        listPaymentMethods: {
          invoke: {
            src: Services.listPaymentMethods,
            onDone: {
              target: `fetchContactInfo`,
              actions: ActionTypes.handleListPayments,
            },
            onError: {
              target: `fetchContactInfo`,
              actions: ActionTypes.setPaymentError,
            },
          },
        },
        fetchContactInfo: {
          invoke: {
            src: Services.fetchContactInfoService,
            onDone: {
              target: `fetchTripPricing`,
              actions: ActionTypes.setContactInfo,
            },
            onError: {
              target: `fetchTripPricing`,
            },
          },
        },
        fetchTripPricing: {
          invoke: {
            src: Services.fetchTripPricingService,
            onDone: {
              target: `#${packagesMachineId}.${ParentState.passengerInformation}`,
              actions: ActionTypes.setTripPricingFromData,
            },
            onError: {
              actions: ActionTypes.setTripPricingError,
              target: `#${packagesMachineId}.${ParentState.passengerInformation}`,
            },
          },
        },
      },
    },
    [ParentState.passengerInformation]: {
      ...getFlightPassengerInformationMachine({
        parentMachineId: packagesMachineId,
        nextState: `#${packagesMachineId}.${ParentState.lodgingPassengerInformation}`,
        nextStateFromReview: `#${packagesMachineId}.${ParentState.lodgingPassengerInformation}`,
      }),
    },
    [ParentState.contactInformation]: {
      ...getContactInformationMachine({
        parentMachineId: packagesMachineId,
        previousState: `#${packagesMachineId}.${ParentState.lodgingPassengerInformation}`,
        onDone: [
          {
            cond: PackagesCommonGuards.isDesktop,
            target: `#${packagesMachineId}.${ParentState.lodgingPassengerInformation}`,
          },
          {
            cond: PackagesCommonGuards.isMobile,
            target: `#${packagesMachineId}.${ParentState.cartQuote}`,
          },
        ],
        onError: [
          {
            cond: PackagesCommonGuards.isDesktop,
            target: `#${packagesMachineId}.${ParentState.lodgingPassengerInformation}`,
          },
          {
            cond: PackagesCommonGuards.isMobile,
            target: `#${packagesMachineId}.${ParentState.cartQuote}`,
          },
        ],
      }),
    },
    [ParentState.passport]: {
      ...getPassportMachineState({
        parentMachineId: packagesMachineId,
        nextState: `#${packagesMachineId}.${ParentState.passengerInformation}`,
        previousState: `#${packagesMachineId}.${ParentState.passengerInformation}`,
      }),
    },
    [ParentState.lodgingPassengerInformation]: {
      initial: PassengerInformationState.passengerPicker,
      states: {
        [PassengerInformationState.passengerPicker]: {
          initial: PassengerInformationChildState.idle,
          states: {
            [PassengerInformationChildState.idle]: {},
          },
        },
        [PassengerInformationState.error]: {},
      },
      on: {
        [Event.NEXT]: [
          {
            target: `#${packagesMachineId}.${ParentState.cartQuote}`,
            cond: PackagesCommonGuards.isDesktop,
          },
          {
            target: `#${packagesMachineId}.${ParentState.contactInformation}`,
            cond: PackagesCommonGuards.isMobile,
          },
        ],
        [Event.PREVIOUS]: [
          {
            target: `#${packagesMachineId}.${ParentState.passengerInformation}`,
          },
        ],
        [FlightPassengerEventTypes.SELECT_PASSENGER]: [
          {
            actions: LodgingActionTypes.selectPassenger,
          },
        ],
      },
    },
    [ParentState.cartQuote]: {
      entry: [
        ActionTypes.addMultiProviderProduct,
        LodgingActionTypes.addLodgingProduct,
        addPackageProduct,
        addPackageDiscount,
      ],
      ...getCartQuoteMachine({
        parentMachineId: packagesMachineId,
        nextState: `#${packagesMachineId}.${ParentState.seatSelection}`,
      }),
    },
    // Add Products to Cart
    // [ParentState.cancelForAnyReason]: {
    //   ...getCancelForAnyReasonMachine({
    //     parentMachineId: packagesMachineId,
    //     nextState: `#${packagesMachineId}.${ParentState.disruption}`,
    //   }),
    // },
    // [ParentState.changeForAnyReason]: {
    //   ...getChfarMachine({
    //     parentMachineId: packagesMachineId,
    //     nextState: `#${packagesMachineId}.${ParentState.disruption}`,
    //   }),
    // },
    // [ParentState.disruption]: {
    //   ...getAirDisruptionMachine({
    //     parentMachineId: packagesMachineId,
    //     nextState: `#${packagesMachineId}.${ParentState.priceDrop}`,
    //   }),
    // },
    // [ParentState.priceDrop]: {
    //   ...getAirPriceDropMachine({
    //     parentMachineId: packagesMachineId,
    //     nextState: `#${packagesMachineId}.${ParentState.vipSupport}`,
    //   }),
    // },
    // [ParentState.vipSupport]: {
    //   ...getVipSupportMachine({
    //     parentMachineId: packagesMachineId,
    //     nextState: `#${packagesMachineId}.${ParentState.priceFreezeDiscount}`,
    //   }),
    // },
    //Add Discounts to Cart
    // [ParentState.priceFreezeDiscount]: {
    //   ...getPriceFreezeDiscountMachineState({
    //     parentMachineId: packagesMachineId,
    //     nextState: `#${packagesMachineId}.${ParentState.wallet}`,
    //   }),
    // },
    [ParentState.wallet]: {
      ...getWalletMachine({
        parentMachineId: packagesMachineId,
        nextState: `#${packagesMachineId}.${ParentState.cartUpdate}`,
      }),
    },

    // Update cart with Products and Discounts
    [ParentState.cartUpdate]: {
      ...getCartUpdateMachine({
        parentMachineId: packagesMachineId,
        nextState: `#${packagesMachineId}.${ParentState.cardPayment}`,
      }),
      exit: ActionTypes.checkForPriceChange,
    },
    [ParentState.seatSelection]: {
      ...getSeatMachine({
        parentMachineId: packagesMachineId,
        nextState: `#${packagesMachineId}.${ParentState.wallet}`,
        previousState: `#${packagesMachineId}.${ParentState.contactInformation}`,
      }),
    },
    [ParentState.rewardsPayment]: {
      ...getRewardsPaymentMachine({
        parentMachineId: packagesMachineId,
        nextState: `#${packagesMachineId}.${ParentState.cardPayment}`,
        previousState: `#${packagesMachineId}.${ParentState.seatSelection}`,
      }),
    },
    [ParentState.cardPayment]: cardPaymentMachine,
    [ParentState.review]: {
      initial: GenericChildState.idle,
      states: {
        [GenericChildState.loading]: {
          //   invoke: {
          //     src: ServiceTypes.trackReviewDetails,
          //     onDone: `#${packagesMachineId}.${ParentState.review}.${GenericChildState.idle}`,
          //     onError: `#${packagesMachineId}.${ParentState.review}.${GenericChildState.idle}`,
          //   },
        },
        [GenericChildState.idle]: {},
      },
      ...getReviewMachineState({
        parentMachineId: packagesMachineId,
        nextState: `#${packagesMachineId}.${ParentState.cartUpdateBeforeFulfill}`,
        previousState: `#${packagesMachineId}.${ParentState.cardPayment}`,
      }),
      //   exit: ActionTypes.removeVipProductFromCart,
    },
    [ParentState.cartUpdateBeforeFulfill]: {
      ...getCartUpdateMachine({
        parentMachineId: packagesMachineId,
        nextState: `#${packagesMachineId}.${ParentState.cartFulfill}`,
      }),
    },
    [ParentState.cartFulfill]: {
      entry: setPaymentFulfillParams,
      ...getCartFulfillMachine<PackagesMachineContext>({
        parentMachineId: packagesMachineId,
        nextState: `#${packagesMachineId}.${ParentState.bookingConfirmation}`,
        // trackPollSuccessAction: trackPollSuccess({
        //   eventName: COMPLETE_BUY_AIR,
        //   // trackProperties returned in pollfulfill response will be also added
        //   getAdditionalProperties: (context, event) => {
        //     return {
        //       credit_amount_used_aud: getWalletCreditPayAmount({ context })
        //         ?.value,
        //       customer_credit_balance_aud: getWalletCreditBalance({ context })
        //         ?.value,
        //       cfar_choice: AirCfarSelectors.getIsCfarAttached({ context }),
        //       total_card_amount_aud: getAmountDue({ context }),
        //       total_rewards_amount_aud:
        //         RewardsPaymentSelectors.getRewardsCashAmountToApply({ context }),
        //       card_product_used: getSelectedPaymentCardName({ context }),
        //       rewards_product_used: RewardsPaymentSelectors.getSelectedAccount({
        //         context,
        //       })?.cardProductType,
        //       awards_balance: RewardsPaymentSelectors.getSelectedAccount({
        //         context,
        //       })?.awardsBalance?.value,
        //       agent_locator:
        //         cartFulfillSelectors.getFulfilledProductsReservationId({
        //           context,
        //         }),
        //     };
        //   },
        // }),
        // trackPollFailureAction: trackPollFailure({
        //   eventName: COMPLETE_BUY_AIR,
        //   // trackProperties returned in pollfulfill response will be also added
        //   getAdditionalProperties: (context, event) => {
        //     return {
        //       credit_amount_used_aud: getWalletCreditPayAmount({ context })
        //         ?.value,
        //       customer_credit_balance_aud: getWalletCreditBalance({ context })
        //         ?.value,
        //       cfar_choice: AirCfarSelectors.getIsCfarAttached({ context }),
        //       total_card_amount_aud: getAmountDue({ context }),
        //       total_rewards_amount_aud:
        //         RewardsPaymentSelectors.getRewardsCashAmountToApply({ context }),
        //       card_product_used: getSelectedPaymentCardName({ context }),
        //       rewards_product_used: RewardsPaymentSelectors.getSelectedAccount({
        //         context,
        //       })?.cardProductType,
        //       awards_balance: RewardsPaymentSelectors.getSelectedAccount({
        //         context,
        //       })?.awardsBalance?.value,
        //     };
        //   },
        // }),
      }),
    },
    [ParentState.bookingConfirmation]: {},
  },
  on: {
    [Event.OPEN_PASSENGER_PICKER]: `#${packagesMachineId}.${ParentState.passengerInformation}`,
    // wallet credit Payment Events,
    [WalletEventTypes.SET_SELECTED_OFFER]: {
      actions: ActionTypes.addDiscounts,
    },
    [WalletEventTypes.REMOVE_SELECTED_OFFER]: {
      actions: ActionTypes.removeDiscounts,
    },
    [WalletEventTypes.SET_CREDIT_AMOUNT_TO_APPLY]: {
      actions: ActionTypes.setCreditAmountToApply,
    },
    // Rewards Payment Events,
    [RewardsPaymentEventTypes.SET_SELECTED_REWARDS_ACCOUNT_ID]: {
      actions: ActionTypes.setSelectedRewardsAccountId,
    },
    [RewardsPaymentEventTypes.SET_REWARDS_FIAT_AMOUNT_TO_PAY]: {
      actions: ActionTypes.setRewardsFiatAmountToPay,
    },
    [RewardsPaymentEventTypes.SET_REWARDS_AMOUNT_TO_PAY]: {
      actions: ActionTypes.setRewardsAmountToPay,
    },
    [RewardsPaymentEventTypes.SET_REWARDS_PAYMENT_VISITED]: {
      actions: ActionTypes.setRewardsPaymentVisited,
    },
    [RewardsPaymentEventTypes.SET_REWARDS_ACCOUNTS]: {
      actions: ActionTypes.setRewardsAccounts,
    },
    [RewardsPaymentEventTypes.SET_EARN_BY_REWARDS_ACCOUNT_ID]: {
      actions: ActionTypes.setEarnValueForAccount,
    },
    // Card Payment Events
    [CardPaymentEventTypes.SET_SELECTED_PAYMENT_METHOD_ID]: {
      actions: ActionTypes.setSelectedPaymentMethodId,
    },
    [CardPaymentEventTypes.DELETE_PAYMENT_METHOD]: `#${packagesMachineId}.${ParentState.cardPayment}.${PaymentInformationState.delete}`,
    [CardPaymentEventTypes.OPEN_PAYMENT_FORM]: `#${packagesMachineId}.${ParentState.cardPayment}.${PaymentInformationState.add}`,
    [CardPaymentEventTypes.CLOSE_PAYMENT_FORM]: `#${packagesMachineId}.${ParentState.cardPayment}.${PaymentInformationState.idle}`,
    [CardPaymentEventTypes.VERIFY_PAYMENT_METHOD]: {
      actions: ActionTypes.setSpreedlyToken,
      target: `#${packagesMachineId}.${ParentState.cardPayment}.${PaymentInformationState.add}.${PaymentInformationChildState.verify}`,
    },
    // Contact Information Events
    [ContactEventType.CHANGE]: {
      actions: ActionTypes.setContactInfo,
    },
    // Events below are used for browser navigation
    [Event.GO_TO_PASSENGER_SELECT]: `#${packagesMachineId}.${ParentState.passengerInformation}`,
    [Event.GO_TO_CONTACT_INFORMATION]: `#${packagesMachineId}.${ParentState.contactInformation}`,
    [Event.GO_TO_HOTEL_PASSENGER_SELECT]: `#${packagesMachineId}.${ParentState.lodgingPassengerInformation}`,
    [Event.GO_TO_CART_QUOTE]: `#${packagesMachineId}.${ParentState.cartQuote}`,
    [Event.GO_TO_REVIEW]: `#${packagesMachineId}.${ParentState.review}`,
    [Event.GO_TO_CARD_PAYMENT]: `#${packagesMachineId}.${ParentState.cardPayment}`,
    [Event.GO_TO_BOOKING_CONFIRMATION]: `#${packagesMachineId}.${ParentState.bookingConfirmation}`,
    [Event.GO_TO_SEAT_SELECTION]: [
      {
        target: `#${packagesMachineId}.${ParentState.cartQuote}`,
        cond: CartQuoteGuardTypes.isCartLocked,
      },
      {
        target: `#${packagesMachineId}.${ParentState.seatSelection}`,
      },
    ],
    // [Event.CBA_SKIP_SEAT]: {
    //   actions: [
    //     ActionTypes.setSkipSeatSelection,
    //     ActionTypes.resetSelectedSeats,
    //   ],
    // },
    // [Event.CBA_SELECT_SEAT]: {
    //   actions: [ActionTypes.selectSeat, ActionTypes.resetSkippedSeatSelection],
    // },
    [FlightPassengerEventTypes.OPEN_FORM_AND_SET_PASSENGER]: {
      target: `#${packagesMachineId}.${ParentState.passengerInformation}`,
      actions: ActionTypes.openFormAndSetCurrentPassenger,
    },
    [Event.INITIALIZE_CHECKOUT_STATE]: {
      actions: ActionTypes.initializeCheckoutState,
    },
    [Event.SET_PLATFORM]: {
      actions: ActionTypes.setPlatform,
    },
  },
};
