import { connect } from "react-redux";
import { IResult } from "redmond";
import { B2BSearchAutocomplete, IB2BSearchAutocompleteProps } from "halifax";
import { IStoreState } from "../../../../reducers/types";

import { actions } from "../../actions";
import {
  getDistanceFromLocation,
  getDistanceFromLocationCategories,
  getDistanceFromLocationCategoriesLoading,
} from "../../reducer/selectors";

export const mapDispatchToProps = {
  setValue: actions.setDistanceFromLocation,
  fetchValueCategories: actions.fetchDistanceFromLocationCategories,
};

export const mapStateToProps = (state: IStoreState) => {
  let loading = !!getDistanceFromLocationCategoriesLoading(state);

  return {
    value: getDistanceFromLocation(state),
    valueCategories: getDistanceFromLocationCategories(state),
    loading,
  };
};

export const ConnectedDistanceFromLocationAutocomplete = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  B2BSearchAutocomplete as (
    props: IB2BSearchAutocompleteProps<IResult | null>
  ) => JSX.Element
);
