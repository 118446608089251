import React from "react";

export const EARN_ENHANCEMENT_SUBTITLE = (
  earnRate: string | number,
  productDisplayName: string
) => (
  <>
    <span className="font-bold">Earn {earnRate}X miles</span> on flights with
    your {productDisplayName} account.
  </>
);
