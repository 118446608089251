import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { PackagesInfoModalConnectorProps } from "./container";
import "./styles.scss";
import {
  ButtonWrap,
  CloseButtonIcon,
  DesktopPopupModal,
  Icon,
  IconName,
  MobilePopoverCard,
} from "halifax";
import { Box, Typography } from "@material-ui/core";
import {
  PACKAGES_INFO_MODAL_CTA_TEXT,
  PACKAGES_INFO_MODAL_HEADING_TEXT,
  PACKAGES_INFO_MODAL_POINTS,
  PACKAGES_INFO_MODAL_POINTS_HEADING,
  PACKAGES_INFO_MODAL_SUBHEADING_TEXT,
} from "./textConstants";
import clsx from "clsx";

export interface IPackagesInfoModalProps
  extends PackagesInfoModalConnectorProps,
    RouteComponentProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  isMobile?: boolean;
}

export const PackagesInfoModal = ({
  open,
  setOpen,
  isMobile,
}: IPackagesInfoModalProps) => {
  const ModalContent = React.useCallback(
    () => (
      <Box className="packages-info-modal-content">
        <Box className="packages-info-modal-header-content">
          <Icon name={IconName.CaponeTravelWhiteText} />
          <Box className="new-tag">New</Box>
          <Box className="packages-info-modal-heading-container">
            <Box className="packages-info-modal-heading-icons">
              <Icon name={IconName.HotelFunnelIcon} />
              <Typography className="packages-icon-separator">+</Typography>
              <Icon name={IconName.FlightFunnelIcon} />
            </Box>
            <Typography
              variant="h3"
              className="packages-info-modal-heading-text"
            >
              {PACKAGES_INFO_MODAL_HEADING_TEXT}
            </Typography>
          </Box>

          <Typography className="packages-info-modal-subheading-text">
            {PACKAGES_INFO_MODAL_SUBHEADING_TEXT}
          </Typography>
        </Box>
        <Box className="packages-info-modal-body-content">
          {isMobile && (
            <Typography className="packages-info-point-heading" variant="h4">
              {PACKAGES_INFO_MODAL_POINTS_HEADING}
            </Typography>
          )}

          {PACKAGES_INFO_MODAL_POINTS.map(({ icon, text }) => {
            return (
              <Box className="packages-info-point">
                <Icon name={icon} className="packages-info-point-icon" />
                <Typography className="packages-info-point-text">
                  {text}
                </Typography>
              </Box>
            );
          })}

          <Box
            className={clsx("packages-info-modal-cta-container", {
              mobile: isMobile,
            })}
          >
            <ButtonWrap
              onClick={() => setOpen(false)}
              className="packages-info-modal-cta"
            >
              {PACKAGES_INFO_MODAL_CTA_TEXT}
            </ButtonWrap>
          </Box>
        </Box>
      </Box>
    ),
    []
  );

  if (isMobile)
    return (
      <MobilePopoverCard
        className="packages-info-mobile-popover"
        open={open}
        onClose={() => setOpen(false)}
        topRightButton={<CloseButtonIcon onClick={() => setOpen(false)} />}
      >
        <ModalContent />
      </MobilePopoverCard>
    );

  return (
    <DesktopPopupModal
      open={open}
      className="packages-info-modal"
      onClose={() => setOpen(false)}
      invisibleBackdrop={false}
    >
      <ModalContent />
    </DesktopPopupModal>
  );
};
