export const PRICE_FILTER_TITLE = "Max Price";
export const PRICE_FILTER_SUBTITLE = "Round-trip, per traveler";

export const FILTER_MODAL_TITLES = {
  clearAllFiltersText: "Clear All Filters",
  resetFiltersText: "Reset Filters",
  applyFiltersText: (count: number) =>
    `Apply Filters${count ? ` (${count})` : ""}`,
  applyText: "Apply",
  fareclassText: "Fare class",
  sortText: "Sort by",
  numOfStopsText: "Number of Stops",
  airlineText: "Airline",
  baggageTransfers: "Baggage transfers",
  outboundTimesText: "Outbound Times",
  returnTimesText: "Return Times",
  priceRangeText: PRICE_FILTER_TITLE,
  priceRangeSubtitleText: PRICE_FILTER_SUBTITLE,
  outboundAirportText: "Outbound Airport",
  flightNumberText: "Flight Number",
  policyFilter: "Policy Status",
};

export const FILTERS_MODAL_CTA_TEXT = (appliedFiltersCount: number) =>
  `All filters${appliedFiltersCount > 0 ? ` (${appliedFiltersCount})` : ""}`;

export const AIRLINE_SELECTION_SUBTITLE =
  "<strong>Step 1:</strong> Select an airline";

export const FLIGHT_NUMBER_SELECTION_SUBTITLE =
  "<strong>Step 2:</strong> Select flight number(s)";

export const BAGGAGE_FILTER_LABEL =
  "View flights that require you to claim and re-check your own baggage between layovers. This may save time or money by improving search results.";
