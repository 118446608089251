import React from "react";

export const FARE_CLASSES = {
  basic: "Basic",
  standard: "Standard",
  enhanced: "Enhanced",
  premium: "Premium",
  luxury: "Luxury",
};

export const FARECLASS_DROPDOWN_LABEL = (appliedLabel?: string) => {
  if (!!appliedLabel) {
    return <span className="filter-name">{FARE_CLASSES[appliedLabel]}</span>;
  } else {
    return <span className="filter-name">Fare class</span>;
  }
};
