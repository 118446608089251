import { FareDetails, TripDetails, TripSlice, FareSliceDetails } from "redmond";

export const getSliceIndex = (
  departure: boolean,
  details: TripDetails | FareDetails
): number => {
  return details.slices.findIndex((slice: TripSlice | FareSliceDetails) =>
    departure ? slice.outgoing : !slice.outgoing
  );
};
