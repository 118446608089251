import {
  AmenityEnum,
  HotelStarRatingEnum,
  BoundingBox,
  LodgingLocation,
  LodgingId,
  IResult,
  ICategorizedResponse,
  UserHotelPreferencesPayload,
  CallState,
  Payment,
  SearchPackagesResponse,
  ITripTerminus,
  FareclassOptionFilter,
} from "redmond";

export enum PackagesHotelsAvailabilitySortOption {
  Recommended = "Recommended",
  StarRating = "StarRating",
  Pricing = "Pricing",
}

export enum PackagesAvailabilityCallState {
  NotCalled = "NotCalled",
  InitialMapSearchCallInProcess = "InitialMapSearchCallInProcess",
  InitialSearchCallInProcess = "InitialSearchCallInProcess",
  FollowUpSearchCallInProcess = "FollowUpSearchCallInProcess",
  InitialSearchCallSuccess = "InitialSearchCallSuccess",
  InitialMapSearchCallSuccess = "InitialMapSearchCallSuccess",
  FollowUpSearchCallSuccess = "FollowUpSearchCallSuccess",
  // when a FollowUpSearch returns empty lodgings
  Complete = "Complete",
  Failed = "Failed",
}

export interface IHotelFilterState {
  filtersBannerMessage?: string;
  amenities: AmenityEnum[];
  starRatings: HotelStarRatingEnum[];
  maxPrice: number;
  hotelName: string;
  freeCancel: boolean;
  isInPolicy: boolean;
  loyaltyPrograms: string[];
}

export interface IPackagesAvailabilityState extends IHotelFilterState {
  availabilityResponse?: SearchPackagesResponse;
  packagesAvailabilityCallState: PackagesAvailabilityCallState;
  mapBound: BoundingBox | null;
  searchLocation: LodgingLocation | null;
  searchLodgingIds: LodgingId[] | null;
  searchFromDate: Date | null;
  searchUntilDate: Date | null;
  searchDestinationResult: IResult | null;
  searchOriginResult: ITripTerminus | null;
  lodgingIdInFocus: LodgingId | null;
  lodgingIdHovered: LodgingId | null;
  hasViewedUnavailableHotel: boolean;
  sortOption: PackagesHotelsAvailabilitySortOption;
  // The datesPicker popup needs to be controllable through different components
  openDatesModal: boolean;
  selectedLodgingIndex: number | null;
  searchAdultsCount: number;
  searchChildren: number[];
  searchInfants: { age: number; inSeat: boolean }[];
  searchRoomsCount: number;
  fareclassOptionFilter: FareclassOptionFilter;
  totalPropertyCount: number | null;
  viewHotelsNearLocation: IResult | null;
  viewHotelsNearLocationCategories: ICategorizedResponse[];
  viewHotelsNearLocationCategoriesLoading: boolean | null;
  userHotelPreferences?: UserHotelPreferencesPayload;
  userHotelPreferencesCallState: CallState;
  paymentMethods: Payment[];
  listPaymentMethodCallState: CallState;
}
