import { ParentState } from "@capone/checkout";
import { CheckoutStepperProps } from "halifax/build/CheckoutStepper/component";

export const getCheckoutSteps = (
  parentState: ParentState
): CheckoutStepperProps["steps"] => {
  return [
    {
      name: "Who's flying?",
      status: (() => {
        switch (parentState) {
          case ParentState.passengerInformation:
          case ParentState.passport:
          case ParentState.contactInformation:
          case ParentState.loading:
            return 1;
          default:
            return 2;
        }
      })(),
    },
    {
      name: "Who's checking in?",
      status: (() => {
        switch (parentState) {
          case ParentState.passengerInformation:
          case ParentState.passport:
          case ParentState.contactInformation:
          case ParentState.loading:
            return 0;
          case ParentState.lodgingPassengerInformation:
            return 1;
          default:
            return 2;
        }
      })(),
    },
    {
      name: "Seat selection",
      status: (() => {
        switch (parentState) {
          case ParentState.passengerInformation:
          case ParentState.passport:
          case ParentState.contactInformation:
          case ParentState.loading:
          case ParentState.lodgingPassengerInformation:
          case ParentState.cartQuote:
            return 0;
          case ParentState.seatSelection:
            return 1;
          default:
            return 2;
        }
      })(),
    },
    {
      name: "Rewards & payment",
      status: (() => {
        switch (parentState) {
          case ParentState.passengerInformation:
          case ParentState.passport:
          case ParentState.contactInformation:
          case ParentState.loading:
          case ParentState.lodgingPassengerInformation:
          case ParentState.cartQuote:
          case ParentState.seatSelection:
            return 0;
          case ParentState.cardPayment:
            return 1;
          default:
            return 2;
        }
      })(),
    },
  ];
};
