export const ADD_PAYMENT_METHOD_ERROR_TITLE =
  "We are unable to add your payment method";
export const TRY_AGAIN_CTA = "Try Again";
export const CAP_ONE_INVALID_CREDIT_CARD_TITLE =
  "It looks like you're trying to add an invalid credit card.";
export const CAP_ONE_INVALID_CREDIT_CARD_SUBTITLE =
  "Ensure you are using an eligible Capital One credit card.";
export const EDIT_PAYMENT_METHOD = "Edit Payment Method";
export const CARD_VERIFY_TEXT = "Adding your card";

export const REVIEW_TRIP_CTA_TEXT = "Review my trip";
export const MOBILE_HEADING_TITLE_TEXT = "Rewards and payment";
export const MOBILE_HEADING_SUBTITLE_TEXT =
  "Combine your travel credits, a travel offer, rewards, and the payment method you add to book this trip.";

export const NO_SELECTED_REWARDS_TEXT =
  "Apply your rewards or select pay with your Capital One credit card to continue.";
export const NO_SELECTED_CARD_TEXT =
  "Select a payment method to complete your booking.";
