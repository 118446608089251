import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../../reducers/types";
import { HotelBookPassengerSelection } from "./component";

const mapStateToProps = (_state: IStoreState) => {
  return {};
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type HotelBookPassengerSelectionConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedHotelBookPassengerSelection = connector(
  HotelBookPassengerSelection
);
