export const SEARCH_AGAIN_BUTTON = "Search again";
export const GO_BACK_BUTTON = "Go back";
export const SHOP_ERROR_TITLE =
  "Uh oh! We were unable to process your shop request.";
export const SHOP_MISSING_ROOMS_ERROR =
  "Uh oh! This hotel has no rooms available for your search criteria.";
export const SHOP_ERROR_SUBTITLE = "Something went wrong.";
export const UNAVAILABLE_DATES_ERROR_TITLE =
  "This hotel is unavailable for these dates.";
export const CHANGE_DATES_BUTTON = "Change dates";
export const BACK_TO_RESULTS_BUTTON = "Back to results";
