import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../../reducers/types";

import { getPackageSelectedLodging } from "../../reducer/selectors";
import { MobileHotelPhotoModal } from "./component";

const mapStateToProps = (state: IStoreState) => {
  return {
    lodging: getPackageSelectedLodging(state) || undefined,
  };
};

export const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MobileHotelPhotoModalConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedMobileHotelPhotoModal = connector(MobileHotelPhotoModal);
