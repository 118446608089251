import { actions } from "../actions";
import {
  FETCH_PACKAGE_HOTEL_SHOP,
  SET_PACKAGE_HOTEL_SHOP_RESULTS,
  ACKNOWLEDGE_PACKAGE_HOTEL_SHOP_FAILURE,
  SET_DATE_RANGE,
  SET_PACKAGE_HOTEL_SHOP_CALL_STATE_FAILED,
  SET_PACKAGE_HOTEL_SHOP_PROGRESS,
  SELECT_LODGING,
  SELECT_PACKAGE_BY_LODGING_ID,
  FETCH_DISTANCE_FROM_LOCATION_CATEGORIES,
  SET_DISTANCE_FROM_LOCATION,
  SET_DISTANCE_FROM_LOCATION_CATEGORIES,
  SELECT_ROOM_TYPE,
} from "../actions/constants";
import {
  IPackageHotelShopState,
  PackageHotelShopCallState,
  PackageHotelShopStep,
} from "./state";

const initialState: IPackageHotelShopState = {
  selectedLodging: null,
  dateRange: null,
  roomInfoProducts: [],
  chosenRoomInfoIndex: null,
  chosenProductIndex: null,
  hotelShopCallState: PackageHotelShopCallState.NotCalled,
  hotelShopCallError: null,
  progress: PackageHotelShopStep.HotelInfo,
  packagesByRateId: {},
  distanceFromLocation: null,
  distanceFromLocationCategories: [],
  distanceFromLocationCategoriesLoading: null,
  cancellationSummary: null,
};

export const reducer = (
  state: IPackageHotelShopState = initialState,
  action: actions.PackageHotelShopActions
): IPackageHotelShopState => {
  switch (action.type) {
    case FETCH_PACKAGE_HOTEL_SHOP:
      return {
        ...state,
        roomInfoProducts: [],
        hotelShopCallState: PackageHotelShopCallState.InProcess,
        hotelShopCallError: null,
      };

    case SET_PACKAGE_HOTEL_SHOP_RESULTS:
      const { lodgingShopResponse, packagesByRateId } = action.payload ?? {};

      return {
        ...state,
        chosenRoomInfoIndex: null,
        chosenProductIndex: null,
        hotelShopCallState: action.hotelShopCallState,
        hotelShopCallError: action.hotelShopCallError,
        roomInfoProducts:
          lodgingShopResponse?.value.roomInfoProducts ?? state.roomInfoProducts,
        packagesByRateId: packagesByRateId ?? state.packagesByRateId,
        cancellationSummary:
          lodgingShopResponse?.value.cancellationSummary || null,
      };

    case SET_PACKAGE_HOTEL_SHOP_CALL_STATE_FAILED:
      return {
        ...state,
        hotelShopCallState: PackageHotelShopCallState.Failed,
        hotelShopCallError: action.hotelShopCallError,
      };

    case SELECT_ROOM_TYPE:
      return {
        ...state,
        chosenRoomInfoIndex: action.roomInfoIndex,
        chosenProductIndex: action.productIndex,
        chosenRateId: action.rateId,
      };

    case SELECT_LODGING:
      return {
        ...state,
        selectedLodging: action.lodging,
      };
    case SELECT_PACKAGE_BY_LODGING_ID:
      return {
        ...state,
        selectedPackageByLodgingId: action.packageByLodgingId,
      };
    case SET_DATE_RANGE:
      return {
        ...state,
        dateRange: action.dateRange,
      };

    case ACKNOWLEDGE_PACKAGE_HOTEL_SHOP_FAILURE:
      return {
        ...state,
        hotelShopCallState: PackageHotelShopCallState.FailureAcknowledged,
      };
    case SET_PACKAGE_HOTEL_SHOP_PROGRESS: {
      return {
        ...state,
        progress: action.progress,
      };
    }

    case SET_DISTANCE_FROM_LOCATION:
      const { location } = action;

      return {
        ...state,
        distanceFromLocation: location ? { ...location } : null,
        distanceFromLocationCategoriesLoading: false,
      };

    case SET_DISTANCE_FROM_LOCATION_CATEGORIES:
      const { categories } = action;
      return {
        ...state,
        distanceFromLocationCategories: categories,
        distanceFromLocationCategoriesLoading: false,
      };

    case FETCH_DISTANCE_FROM_LOCATION_CATEGORIES:
      return {
        ...state,
        distanceFromLocationCategories: [],
        distanceFromLocationCategoriesLoading: true,
      };

    default:
      return { ...state };
  }
};

export * from "./selectors";
