import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { BookingErrorModal } from "./component";
import { IStoreState } from "../../../../reducers/types";

const mapStateToProps = (_state: IStoreState) => ({});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type BookingErrorModalConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedBookingErrorModal = withRouter(
  connector(BookingErrorModal)
);
