import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../../reducers/types";
import {
  getHotelAvailabilityLodgingIdInFocus,
  getPackageAvailabilitySearchDestinationLocationResult,
  getPackageHotelQueryParams,
  getPackagesAvailabilityLodgings,
  getPackagesByLodgingId,
  getTravelersCount,
} from "../../reducer/selectors";
import { actions } from "../../actions";
import { MobileAvailabilityMapRollingGallery } from "./component";
import { getNightCount, getOrigin } from "../../../search/reducer";
import {
  getRewardsAccounts,
  getSelectedAccountReferenceIdIfRedemptionEnabled,
} from "../../../rewards/reducer";
import { withRouter } from "react-router-dom";

const mapStateToProps = (state: IStoreState) => {
  return {
    lodgings: getPackagesAvailabilityLodgings(state),
    lodgingIdInFocus: getHotelAvailabilityLodgingIdInFocus(state),
    nightCount: getNightCount(state),
    selectedAccountId: getSelectedAccountReferenceIdIfRedemptionEnabled(state),
    isVentureX: !!getRewardsAccounts(state).find((acc) =>
      acc.productDisplayName.includes("Venture X")
    ),
    travelersCount: getTravelersCount(state),
    packagesByLodgingId: getPackagesByLodgingId(state),
    packageHotelQueryParams: getPackageHotelQueryParams(state),
    origin: getOrigin(state),
    searchedLocation:
      getPackageAvailabilitySearchDestinationLocationResult(state),
  };
};

const mapDispatchToProps = {
  setLodgingIdInFocus: actions.setLodgingIdInFocus,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MobileAvailabilityMapRollingGalleryConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedMobileAvailabilityMapRollingGallery = withRouter(
  connector(MobileAvailabilityMapRollingGallery)
);
