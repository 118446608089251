import { connect } from "react-redux";
import { getReturnDate, getOrigin, getDestination } from "../../../../reducer";
import { withRouter } from "react-router";
import { ActionButtonWithRoute } from "halifax";
import { IStoreState } from "../../../../../../reducers/types";

interface IOwnProps {
  enabled?: boolean;
  return?: Date | null;
  departure?: Date;
}
const mapStateToProps = (state: IStoreState, ownProps: IOwnProps) => {
  const origin = getOrigin(state);
  const destination = getDestination(state);

  const returnDate = getReturnDate(state) || ownProps.return;

  const areTerminiSet = origin !== null && destination !== null;
  const areJuncturesSet = returnDate !== null;
  return {
    disabled: ownProps.enabled
      ? !ownProps.enabled
      : !(areTerminiSet && areJuncturesSet),
  };
};

const mapDispatchToProps = {};

export const ConnectedPackagesSearchButton = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ActionButtonWithRoute));
