import { connect, ConnectedProps } from "react-redux";
import { IResult, ITripTerminus, SliceStopCountFilter } from "redmond";
import { IStoreState } from "../../../../reducers/types";
import {
  ISetOrigin,
  ISetDestination,
  ISetDepartureDate,
  ISetReturnDate,
  ISetFareclassOptionFilter,
  setOrigin,
  setDestination,
  setDepartureDate,
  setReturnDate,
  setStopsOption,
  setFareclassOptionFilter,
  ISetStopsOption,
  setTravelers,
  ISetTravelers,
} from "../../actions/actions";

import {
  getDestination,
  getOrigin,
  getDepartureDate,
  getReturnDate,
  FareclassOptionFilter,
  getFareclassOptionFilter,
  getHasSetFareClassFilter,
  getStopsOption,
  getTravelers,
} from "../../reducer";
import { PackagesSearchControl } from "./component";
import { Dispatch } from "@reduxjs/toolkit";
import { withRouter } from "react-router";

interface IDispatchProps {
  setOrigin: (origin: ITripTerminus | null) => ISetOrigin;
  setDestination: (destination: IResult | null) => ISetDestination;
  setDepartureDate: (date: Date | null) => ISetDepartureDate;
  setReturnDate: (date: Date | null) => ISetReturnDate;
  setFareclassOptionFilter: (
    fareclassOptionFilter: FareclassOptionFilter
  ) => ISetFareclassOptionFilter;
  setStopsOption: (stopsOption: SliceStopCountFilter) => ISetStopsOption;
  setTravelers: (travelers: {
    adultsCount: number;
    children: number[];
    infants: { age: number; inSeat: boolean }[];
  }) => ISetTravelers;
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setOrigin: (origin: ITripTerminus | null) => dispatch(setOrigin(origin)),
  setDestination: (destination: IResult | null) =>
    dispatch(setDestination(destination)),
  setDepartureDate: (date: Date | null) => dispatch(setDepartureDate(date)),
  setReturnDate: (date: Date | null) => dispatch(setReturnDate(date)),
  setFareclassOptionFilter: (fareclassOptionFilter: FareclassOptionFilter) =>
    dispatch(setFareclassOptionFilter(fareclassOptionFilter)),
  setStopsOption: (stopsOption: SliceStopCountFilter) =>
    dispatch(setStopsOption(stopsOption)),
  setTravelers: (travelers: {
    adultsCount: number;
    children: number[];
    infants: { age: number; inSeat: boolean }[];
  }) => dispatch(setTravelers(travelers)),
});

interface IStateProps {
  origin: ITripTerminus | null;
  destination: IResult | null;
  departureDate: Date | null;
  returnDate: Date | null;
  fareclassOptionFilter: FareclassOptionFilter;
  hasSetFareclassFilter: boolean;
  stopsOption: SliceStopCountFilter;
  travelers: {
    adultsCount: number;
    children: number[];
    infants: { age: number; inSeat: boolean }[];
  };
}
const mapStateToProps = (state: IStoreState) => {
  const departureDate = getDepartureDate(state);
  const returnDate = getReturnDate(state);

  return {
    origin: getOrigin(state),
    destination: getDestination(state),
    departureDate: departureDate ? new Date(departureDate) : null,
    returnDate: returnDate ? new Date(returnDate) : null,
    fareclassOptionFilter: getFareclassOptionFilter(state),
    hasSetFareclassFilter: getHasSetFareClassFilter(state),
    stopsOption: getStopsOption(state),
    travelers: getTravelers(state),
  };
};

interface IOwnProps {
  isMultiCityEnabled?: boolean;
  isCustomerProfileExperiment?: boolean;
}

const mergeProps = (
  stateProps: IStateProps,
  dispatchProps: IDispatchProps,
  ownProps: IOwnProps
) => {
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps, mergeProps);
export type PackagesSearchControlConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedPackagesSearchControl = connector(
  withRouter(PackagesSearchControl)
);
