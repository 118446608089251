export const FINALIZE_PACKAGE = "packagesItineraryReview/FINALIZE_PACKAGE";
export type FINALIZE_PACKAGE = typeof FINALIZE_PACKAGE;

export const SET_FINALIZE_PACKAGE_RESPONSE =
  "packagesItineraryReview/SET_FINALIZE_PACKAGE_RESPONSE";
export type SET_FINALIZE_PACKAGE_RESPONSE =
  typeof SET_FINALIZE_PACKAGE_RESPONSE;

export const SET_FINALIZE_PACKAGE_RESPONSE_CALL_STATE_FAILED =
  "packagesItineraryReview/SET_FINALIZE_PACKAGE_RESPONSE_CALL_STATE_FAILED";
export type SET_FINALIZE_PACKAGE_RESPONSE_CALL_STATE_FAILED =
  typeof SET_FINALIZE_PACKAGE_RESPONSE_CALL_STATE_FAILED;
