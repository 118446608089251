import axios from "axios";
import { config } from "../../config";

import { ILocationQueryLabel, IResponse } from "redmond";
import { locationAutocompleteApiPrefix } from "../paths";

export const fetchHotelAutocomplete = (
  body: ILocationQueryLabel
): Promise<IResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await axios.post(
        locationAutocompleteApiPrefix,
        {
          query: { ...body, l: body.l || "" },
        },
        {
          baseURL: config.baseURL,
        }
      );
      if (res.status === 499) {
        throw new Error();
      } else {
        resolve(res.data);
      }
    } catch (e) {
      reject(e);
    }
  });
