import axios from "axios";
import { config } from "../../config";

import { WalletDetailsResponse } from "redmond";
import { travelWalletApiPrefix } from "../paths";

const END_POINT: string = `${travelWalletApiPrefix}/details`;

export const fetchTravelWalletDetails = (): Promise<WalletDetailsResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await axios.get(END_POINT, {
        baseURL: config.baseURL,
      });
      resolve(res.data);
    } catch (e) {
      reject(e);
    }
  });
