import { ParentState } from "@capone/checkout";
import { State } from "xstate";
import { PackagesMachineContext } from "../types";
import { Person } from "@b2bportal/air-booking-api";
import { Product } from "@b2bportal/purchase-api";
import {
  PricingV1,
  LodgingPriceQuote,
  LodgingPriceQuoteEnum,
  RoomInfoProducts,
  CheckInPolicy,
  Lodging,
} from "@b2bportal/lodging-api";

type LodgingPassengerStateType = State<PackagesMachineContext>;
type LodgingPassengerStateWithoutValue = Pick<
  LodgingPassengerStateType,
  "context"
>;

export const getSelectedPassengerIdParent = (
  state: LodgingPassengerStateWithoutValue
) => state.context[ParentState.lodgingPassengerInformation].selectedPassengerId;

export const getPassengerError = (state: LodgingPassengerStateWithoutValue) =>
  state.context[ParentState.lodgingPassengerInformation].error;

export const getSelectedUserPassengerParent = (
  state: LodgingPassengerStateWithoutValue
): Person | undefined => {
  const selectedPassengerId = getSelectedPassengerIdParent(state);
  const userPassengers =
    state.context[ParentState.passengerInformation].userPassengers;

  return userPassengers?.find((person) => person.id === selectedPassengerId);
};

export const getQuotedLodgingProduct = (
  state: LodgingPassengerStateWithoutValue
): LodgingPriceQuote | undefined => {
  const breakdown = state.context[ParentState.cartQuote].quoteBreakdown;
  const hotelProduct = breakdown?.products?.find(
    (p) => p.product.type === Product.Hotel
  );
  return hotelProduct?.product?.value;
};

export const getQuotedLodgingProductPricingV1 = (
  state: LodgingPassengerStateWithoutValue
): PricingV1 | undefined => {
  const quotedLodgingProduct = getQuotedLodgingProduct(state);

  switch (quotedLodgingProduct?.LodgingPriceQuote) {
    /*
      export interface PriceQuoteV2 {
        'pricingV1': PricingV1;
        'additionalPolicies': Array<CheckInPolicy>;
        'LodgingPriceQuote': LodgingPriceQuoteEnum;
      }
    */
    case LodgingPriceQuoteEnum.PriceQuoteV2:
      return quotedLodgingProduct?.pricingV1;
    /*
      export interface PriceQuoteV1 {
        'cancellationPolicy': CancellationPolicy;
        'checkInInstructions': CheckInInstructions;
        'priceChange'?: PriceChange;
        'cancellationPolicyV2'?: CancellationPolicyV2;
        'LodgingPriceQuote': LodgingPriceQuoteEnum;
        'pricing': Pricing;
      }
    */
    case LodgingPriceQuoteEnum.PriceQuoteV1:
    case LodgingPriceQuoteEnum.PriceQuote:
    default:
      return undefined;
  }
};

export const getSelectedRoom = (
  state: LodgingPassengerStateWithoutValue
): RoomInfoProducts | undefined => state.context.lodgingShop.selectedRoom;

export const getSelectedRoomRateId = (
  state: LodgingPassengerStateWithoutValue
) => state.context.lodgingShop.selectedRoomRateId;

export const getCheckInPolicies = (
  state: LodgingPassengerStateWithoutValue
): CheckInPolicy[] | undefined => state.context.lodgingShop.checkInPolicies;

export const getSelectedRoomProduct = (
  state: LodgingPassengerStateWithoutValue
) => {
  const selectedRoom = getSelectedRoom(state);
  const roomRateId = getSelectedRoomRateId(state);
  return selectedRoom?.products?.find((p) => p.rateId?.value === roomRateId);
};

export const getSelectedOpaqueRoomRate = (
  state: LodgingPassengerStateWithoutValue
) => {
  const selectedRoomProduct = getSelectedRoomProduct(state);
  return selectedRoomProduct?.opaqueQuoteRequest;
};

export const getHotelFromDate = (state: LodgingPassengerStateWithoutValue) =>
  state.context.lodgingShop.fromDate;

export const getHotelUntilDate = (state: LodgingPassengerStateWithoutValue) =>
  state.context.lodgingShop.untilDate;

export const getSelectedLodging = (
  state: LodgingPassengerStateWithoutValue
): Lodging | undefined => state.context.lodgingShop.selectedLodging;
