import { connect, ConnectedProps } from "react-redux";

import { FlightSummaryPanel } from "./component";
import { IStoreState } from "../../../../../reducers/types";
import {
  getAirportMap,
  getFareTripDetailsById,
  getSelectedTrip,
  ISelectedTrip,
} from "../../../reducer";

const mapStateToProps = (state: IStoreState) => {
  const selectedTrip = getSelectedTrip(state);
  const selectedOWRTrip = selectedTrip as ISelectedTrip;
  const tripId = selectedTrip?.tripId || "";
  const airports = getAirportMap(state);
  const tripDetailsById = getFareTripDetailsById(state);
  const tripDetails = tripDetailsById[tripId];
  const fareDetails = tripDetails
    ? tripDetails.fareDetails.find(
        (f) =>
          f.id ===
          (selectedOWRTrip.returnFareId || selectedOWRTrip.outgoingFareId)
      )
    : undefined;
  return {
    tripDetails: tripDetailsById[tripId],
    fareDetails: fareDetails,
    airports,
  };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type FlightSummaryPanelConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedFlightSummaryPanel = connector(FlightSummaryPanel);
