import { connect, ConnectedProps } from "react-redux";
import { InfantSeatPickerModal } from "./component";
import { IStoreState } from "../../../../reducers/types";

const mapStateToProps = (_: IStoreState) => {
  return {};
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type InfantSeatPickerModalConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedInfantSeatPickerModal = connector(InfantSeatPickerModal);
