export const FETCH_PACKAGE_FLIGHT_SHOP =
  "packagesFlightShop/FETCH_PACKAGE_FLIGHT_SHOP";
export type FETCH_PACKAGE_FLIGHT_SHOP = typeof FETCH_PACKAGE_FLIGHT_SHOP;

export const SET_PACKAGE_FLIGHT_SHOP_RESULTS =
  "packagesFlightShop/SET_PACKAGE_FLIGHT_SHOP_RESULTS";
export type SET_PACKAGE_FLIGHT_SHOP_RESULTS =
  typeof SET_PACKAGE_FLIGHT_SHOP_RESULTS;

export const SET_PACKAGE_FLIGHT_SHOP_CALL_STATE_FAILED =
  "packagesFlightShop/SET_PACKAGE_FLIGHT_SHOP_CALL_STATE_FAILED";
export type SET_PACKAGE_FLIGHT_SHOP_CALL_STATE_FAILED =
  typeof SET_PACKAGE_FLIGHT_SHOP_CALL_STATE_FAILED;

export const FETCH_PACKAGES_TRIP_DETAILS =
  "packagesFlightShop/FETCH_PACKAGES_TRIP_DETAILS";
export type FETCH_PACKAGES_TRIP_DETAILS = typeof FETCH_PACKAGES_TRIP_DETAILS;

export const SET_PACKAGES_TRIP_DETAILS =
  "packagesFlightShop/SET_PACKAGES_TRIP_DETAILS";
export type SET_PACKAGES_TRIP_DETAILS = typeof SET_PACKAGES_TRIP_DETAILS;

export const SET_PACKAGES_TRIP_DETAILS_CALL_STATE_FAILED =
  "packagesFlightShop/SET_PACKAGES_TRIP_DETAILS_CALL_STATE_FAILED";
export type SET_PACKAGES_TRIP_DETAILS_CALL_STATE_FAILED =
  typeof SET_PACKAGES_TRIP_DETAILS_CALL_STATE_FAILED;

export const SET_PACKAGES_CHOSEN_OUTGOING_SLICE =
  "packagesFlightShop/SET_PACKAGES_CHOSEN_OUTGOING_SLICE";
export type SET_PACKAGES_CHOSEN_OUTGOING_SLICE =
  typeof SET_PACKAGES_CHOSEN_OUTGOING_SLICE;

export const SET_PACKAGES_CHOSEN_RETURN_SLICE =
  "packagesFlightShop/SET_PACKAGES_CHOSEN_RETURN_SLICE";
export type SET_PACKAGES_CHOSEN_RETURN_SLICE =
  typeof SET_PACKAGES_CHOSEN_RETURN_SLICE;

export const SET_PACKAGES_CHOSEN_DEPARTURE_SLICE =
  "packagesFlightShop/SET_PACKAGES_CHOSEN_DEPARTURE_SLICE";
export type SET_PACKAGES_CHOSEN_DEPARTURE_SLICE =
  typeof SET_PACKAGES_CHOSEN_DEPARTURE_SLICE;

export const SET_PACKAGES_FLIGHT_SHOP_PROGRESS =
  "packagesFlightShop/SET_PACKAGES_FLIGHT_SHOP_PROGRESS";
export type SET_PACKAGES_FLIGHT_SHOP_PROGRESS =
  typeof SET_PACKAGES_FLIGHT_SHOP_PROGRESS;

export const SET_SORT_OPTION = "packagesFlightShop/SET_SORT_OPTION";
export type SET_SORT_OPTION = typeof SET_SORT_OPTION;

export const SET_MAX_PRICE_FILTER = "packagesFlightShop/SET_MAX_PRICE_FILTER";
export type SET_MAX_PRICE_FILTER = typeof SET_MAX_PRICE_FILTER;

export const SET_AIRLINE_FILTER = "packagesFlightShop/SET_AIRLINE_FILTER";
export type SET_AIRLINE_FILTER = typeof SET_AIRLINE_FILTER;

export const SET_OUTBOUND_DEPARTURE_TIME_RANGE_FILTER =
  "packagesFlightShop/SET_OUTBOUND_DEPARTURE_TIME_RANGE_FILTER";
export type SET_OUTBOUND_DEPARTURE_TIME_RANGE_FILTER =
  typeof SET_OUTBOUND_DEPARTURE_TIME_RANGE_FILTER;

export const SET_OUTBOUND_ARRIVAL_TIME_RANGE_FILTER =
  "packagesFlightShop/SET_OUTBOUND_ARRIVAL_TIME_RANGE_FILTER";
export type SET_OUTBOUND_ARRIVAL_TIME_RANGE_FILTER =
  typeof SET_OUTBOUND_ARRIVAL_TIME_RANGE_FILTER;

export const SET_RETURN_DEPARTURE_TIME_RANGE_FILTER =
  "packagesFlightShop/SET_RETURN_DEPARTURE_TIME_RANGE_FILTER";
export type SET_RETURN_DEPARTURE_TIME_RANGE_FILTER =
  typeof SET_RETURN_DEPARTURE_TIME_RANGE_FILTER;

export const SET_RETURN_ARRIVAL_TIME_RANGE_FILTER =
  "packagesFlightShop/SET_RETURN_ARRIVAL_TIME_RANGE_FILTER";
export type SET_RETURN_ARRIVAL_TIME_RANGE_FILTER =
  typeof SET_RETURN_ARRIVAL_TIME_RANGE_FILTER;

export const SET_DURATION_FILTER = "packagesFlightShop/SET_DURATION_FILTER";
export type SET_DURATION_FILTER = typeof SET_DURATION_FILTER;

export const SET_AIRPORT_FILTER = "packagesFlightShop/SET_AIRPORT_FILTER";
export type SET_AIRPORT_FILTER = typeof SET_AIRPORT_FILTER;

export const SET_FLIGHT_NUMBER_FILTER =
  "packagesFlightShop/SET_FLIGHT_NUMBER_FILTER";
export type SET_FLIGHT_NUMBER_FILTER = typeof SET_FLIGHT_NUMBER_FILTER;
