import { Box, Typography } from "@material-ui/core";
import {
  ButtonWrap,
  FareClassInfoModal,
  Icon,
  IconName,
  RadioDropdown,
} from "halifax";
import React from "react";
import clsx from "clsx";
import "./styles.scss";
import * as textConstants from "./textConstants";
import { FareclassOptionFilter } from "../../../../reducer";

export interface IFareclassOptionSelectionProps {
  fareclassOptionFilter: FareclassOptionFilter;
  setFareclassOptionFilter: (
    fareclassOptionFilter: FareclassOptionFilter
  ) => void;
  hasSetFareclassFilter?: boolean;
  showDropdownContentOnly?: boolean;
  appliedLabel?: string;
  icon?: IconName;
  title?: string;
  labelIcon?: IconName;
  includeClearFilter?: boolean;
  popoverClassName?: string;
}

const FARE_CLASS_VALUES: Record<string, number> = {
  basic: 1,
  standard: 2,
  enhanced: 3,
  premium: 4,
  luxury: 5,
};

export const FareclassOptionSelection = ({
  fareclassOptionFilter,
  setFareclassOptionFilter,
  hasSetFareclassFilter,
  appliedLabel,
  labelIcon,
  popoverClassName,
}: IFareclassOptionSelectionProps) => {
  const [fareclassInfoModalOpen, setFareclassInfoModalOpen] =
    React.useState<boolean>(false);

  const options = Object.keys(fareclassOptionFilter)
    .map((fareclass) => ({
      value: fareclass,
      label: textConstants.FARE_CLASSES[fareclass],
    }))
    .sort((a, b) => FARE_CLASS_VALUES[a.value] - FARE_CLASS_VALUES[b.value]);

  const handleChange = (selected: string) => {
    setFareclassOptionFilter({
      ...Object.keys(fareclassOptionFilter).reduce(
        (prev, curr) => ({ ...prev, [curr]: false }),
        {} as FareclassOptionFilter
      ),
      [selected]: true,
    });
  };

  return (
    <Box className="packages-search-fareclass-filter-container" tabIndex={0}>
      <RadioDropdown
        selected={
          Object.keys(fareclassOptionFilter).filter(
            (fareclass) => fareclassOptionFilter[fareclass]
          )[0]
        }
        dropdownLabel={
          <>
            {labelIcon && <Icon name={labelIcon} />}
            {textConstants.FARECLASS_DROPDOWN_LABEL(
              hasSetFareclassFilter ? appliedLabel : undefined
            )}
            <div className="flex-dummy" />
          </>
        }
        radioLabelPlacement="end"
        options={options}
        setOption={handleChange}
        popoverClassName={clsx(
          "b2b",
          "packages-search-fareclass-popover",
          popoverClassName
        )}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        bottomContent={
          <>
            <hr />
            <ButtonWrap
              className="fareclass-info-cta-button"
              onClick={() => setFareclassInfoModalOpen(true)}
            >
              <Typography className="fareclass-info-cta-text">
                Learn about our fare classes
              </Typography>{" "}
              <Icon name={IconName.InfoCircle} />
            </ButtonWrap>
          </>
        }
        radioButtonSize="small"
      />

      <FareClassInfoModal
        open={fareclassInfoModalOpen}
        setOpen={setFareclassInfoModalOpen}
      />
    </Box>
  );
};
