import React from "react";

import { HotelStarRatingEnum } from "redmond";
import { PackagesHotelsAvailabilitySortOption } from "../../reducer/state";

export const DESKTOP_FILTERS_BUTTON_TEXT = "More filters";
export const MOBILE_FILTERS_BUTTON_TEXT = "Filters";
export const RATING_TEXT = "Rating";
export const PRICE_PER_NIGHT_TEXT = "Price per night";
export const PRICE_RANGE_TEXT = "Price Range";
export const CANCELLATION = "Cancellation";
export const HOTELS_ON_SALE = "Limited-time promotions";

// Corporate
export const HOTELS_LOYALTY_PROGRAMS = "Hotel loyalty program";

export const HOTELS_POLICY_STATUS = "Policy status";

const starRatingIntValue: {
  [key in HotelStarRatingEnum]: number | null;
} = {
  NotAvailable: null,
  One: 1,
  Two: 2,
  Three: 3,
  Four: 4,
  Five: 5,
};

export const PREMIER_COLLECTION_FILTER_LABEL = "Premier Collection";
export const LIFESTYLE_COLLECTION_FILTER_LABEL = "Lifestyle Collection";
export const STAR_RATING_FILTER_LABEL = (rating: HotelStarRatingEnum) =>
  `${starRatingIntValue[rating]} Star`;

export const PRICE_FILTER_LABEL = "Price";
export const PRICE_FILTER_TITLE = "Max Price";
export const PRICE_FILTER_SUBTITLE = "Per night";

export const AMENITIES_FILTER_LABEL = "Amenities";

export const HOTEL_NAME_FILTER_LABEL = (hotelName: string) => (
  <>
    <strong>Hotel:</strong> {hotelName}
  </>
);

export const SORT_DROPDOWN_LABEL_EXPERIMENT = (appliedFilterCount: number) =>
  `All Filters${appliedFilterCount ? ` (${appliedFilterCount})` : ""}`;

export const RESET_INDIVIDUAL_FILTER_TEXT = "Clear";

export const SORT_RECOMMENDED_TEXT = "Recommended";
export const SORT_RATING_TEXT = "Star Rating (High to Low)";
export const SORT_PRICE_PER_NIGHT_TEXT = "Price (Low to High)";

export const sortOptionLabel: {
  [key in PackagesHotelsAvailabilitySortOption]: string;
} = {
  Recommended: SORT_RECOMMENDED_TEXT,
  StarRating: SORT_RATING_TEXT,
  Pricing: SORT_PRICE_PER_NIGHT_TEXT,
};

export const FILTER_MODAL_TITLES = {
  resetFiltersText: "Clear All Filters",
  applyFiltersText: (count: number) =>
    `Apply Filters${count ? ` (${count})` : ""}`,
  applyText: "Apply",
  amenitiesText: "Amenities",
  starRatingsText: "Rating",
  premiumStaysTitleText: "Premium stays",
  priceRangeText: PRICE_FILTER_TITLE,
  priceRangeSubtitleText: PRICE_FILTER_SUBTITLE,
  hotelNameTitleText: "Hotel Name",
  cancellationTitleText: "Cancellation",
  hotelsOnSaleTitleText: "Limited-time promotions",
  hotelNameSubTitleText:
    "Looking for a specific hotel? Enter the name of the hotel you’d like to stay at in this area.",
  hotelNamePlaceholderText: "What hotel are you looking for?",
  sortText: "Sort by",
  policyText: "Policy Status",
  loyaltyProgramsText: "Loyalty Programs",
  stayTypeFiltersText: "Property Type",
};

export const PREMIUM_STAYS_TOOLTIP_TEXT =
  "With your Venture X card, you get access to 2 distinct hotel collections. Book through the Premier or Lifestyle Collections and enjoy premium benefits.";

export const PREMIUM_STAYS_LABEL = "Premium stays";

export const getPremiumStaysToggleText = (premiumStaysLodgingCount: number) =>
  `Premium stays (${premiumStaysLodgingCount})`;
