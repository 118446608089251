import React from "react";

export const DURATION_DROPDOWN_LABEL_TEXT = (appliedLabel?: string) =>
  !!appliedLabel ? (
    <>
      <span className="filter-name">Duration:</span> {appliedLabel}
    </>
  ) : (
    <span className="filter-name">Duration</span>
  );

export const DURATION_POPOVER_HEADING_TEXT = (numberOfFlights: number) => (
  <>
    <strong>Flight duration:</strong> ({numberOfFlights})
  </>
);

export const DURATION_CONTENT_HEADING_TEXT = "Flight duration";
