import { IResponse, ITripTerminus, LocationQueryEnum } from "redmond";
import { fetchHotelAutocomplete } from "./fetchHotelAutocomplete";
export const fetchHotelLocation = async (locationLabel: string) => {
  try {
    const { categories: categorizedLocations }: IResponse =
      await fetchHotelAutocomplete({
        l: locationLabel,
        LocationQuery: LocationQueryEnum.Label,
      });

    const allLocations = categorizedLocations.flatMap(
      (locations) => locations.results
    ) as ReadonlyArray<ITripTerminus>;

    const correspondingLocations = allLocations.filter((loc) =>
      loc.label.toLowerCase().localeCompare(locationLabel.toLowerCase(), "en", {
        sensitivity: "base",
      })
    );

    return correspondingLocations[0];
  } catch {
    return null;
  }
};
