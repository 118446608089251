import { Box, Divider, Typography } from "@material-ui/core";
import { FlightDetailsSummary, Icon, IconContentView, IconName } from "halifax";
import React from "react";
import {
  DullesUta,
  FareDetails,
  FtcType,
  getFtcTypeArr,
  TripDetails,
  UtasPolicyAssessment,
  UtasPolicyCategory,
} from "redmond";
import * as constants from "./constants";
import { startCase } from "lodash-es";
import { ReviewFlightDetailsConnectorProps } from "./container";
import { getSliceIndex } from "../../../../../../utils/flights";
import { RouteComponentProps } from "react-router-dom";

interface IReviewFlightDetailsProps
  extends ReviewFlightDetailsConnectorProps,
    RouteComponentProps {
  tripDetails: TripDetails;
  outgoingFareRating?: number;
  isOutgoing: boolean;
  header?: JSX.Element;
  selectedFareId?: string;
  plusDays?: number;
  fareDetails: FareDetails;
  isMixedCabinClass?: boolean;
}

export const ReviewFlightDetails = (props: IReviewFlightDetailsProps) => {
  const {
    tripDetails,
    outgoingFareRating,
    isOutgoing,
    header,
    selectedFareId,
    plusDays,
    isMultiTicket,
    fareDetails,
    isMixedCabinClass,
  } = props;

  const filteredFareDetails = tripDetails.fareDetails.filter(
    (f) =>
      outgoingFareRating === undefined ||
      f.slices.find((s) => s.outgoing)?.fareShelf?.rating === outgoingFareRating
  );

  const preSelectedFare = tripDetails.fareDetails.find(
    (fd) => fd.id === selectedFareId
  );
  const selectedFare = preSelectedFare ?? tripDetails.fareDetails[0];
  const tripDetailsSliceIndex = isOutgoing ? 0 : tripDetails.slices.length - 1;
  const selectedFareSliceIndex = selectedFare?.slices.findIndex((slice) =>
    isOutgoing ? slice.outgoing : !slice.outgoing
  );

  const sliceIndex = getSliceIndex(isOutgoing, tripDetails);

  return (
    <Box className="pkg-flight-review-details-root">
      <FlightDetailsSummary
        fareDetails={filteredFareDetails}
        isOutgoing={isOutgoing}
        className="review-itinerary-flight-summary"
        showTitle={false}
        header={header}
        departureTime={tripDetails.slices[tripDetailsSliceIndex].departureTime}
        segments={tripDetails.slices[tripDetailsSliceIndex].segmentDetails}
        fareClass={
          selectedFare?.slices[selectedFareSliceIndex].fareShelf
            ?.shortBrandName || ""
        }
        planeInfo={
          selectedFare?.slices[selectedFareSliceIndex].amenitiesUtas?.amenities
            ?.aircraft.info.displayText || ""
        }
        plusDays={plusDays}
        fareSlice={selectedFare?.slices[selectedFareSliceIndex]}
        isMixedCabinClass={isMixedCabinClass}
        //   airlineProps={airlineProps}
        //   showAvailableSeats={isSeatsUXOptimizationExperiment}
      />
      <Divider />
      <Box className="restriction-details">
        <Box className="restriction-image-overlay-with-text">
          <Box className="itinerary-overlay-text">
            <Typography className="title">
              {isMultiTicket
                ? constants.REVIEW_ITINERARY_HEADER_TEXT_COMBINATION
                : constants.READY_TO_BOOK_HEADER_TEXT}
            </Typography>
            <Typography className="subtitle">
              {isMultiTicket
                ? constants.REVIEW_ITINERARY_SUBHEADER_TEXT_COMBINATION
                : constants.REVIEW_ITINERARY_SUBHEADER_TEXT}
            </Typography>
          </Box>
        </Box>

        <Box className="restrictions-section">
          {getRestrictions({
            fareDetails,
            sliceIndex,
          }).map((restriction) => (
            <Restriction
              key={restriction.name}
              symbol={restriction.symbol}
              name={restriction.name}
              description={
                <Typography variant="subtitle2" className="description">
                  {restriction.description}
                </Typography>
              }
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export const getRestrictions = ({
  fareDetails,
  sliceIndex,
}: {
  fareDetails: FareDetails;
  sliceIndex: number;
}): IRestrictionProps[] => {
  const utas = fareDetails.slices[sliceIndex].amenitiesUtas?.utas;
  const changePolicy =
    utas?.changePolicy?.beforeDeparture ?? utas?.changePolicy?.anytime;
  const cancelPolicy =
    utas?.cancellationPolicy?.beforeDeparture ??
    utas?.cancellationPolicy?.anytime;
  const ftcChangeTypes = changePolicy ? getFtcTypeArr([changePolicy]) : [];
  const ftcCancelTypes = cancelPolicy ? getFtcTypeArr([cancelPolicy]) : [];

  let advanceChange: IRestrictionProps | undefined;
  let cancellation: IRestrictionProps | undefined;

  switch (ftcChangeTypes[0]) {
    case FtcType.FtcNoFees:
      if (ftcCancelTypes[0] === FtcType.NoFtc) {
        // If flight is non-refundable, then check changePolicy if it's FTC for free/fee. If refundable, don't overwrite cancellation policy
        cancellation = {
          symbol: FlightShopSymbol.ALERT,
          name: constants.CANCELLATION,
          description: constants.TRAVEL_CREDIT_FOR_FREE,
        };
      }
      advanceChange = {
        symbol: FlightShopSymbol.INCLUDED,
        name: constants.ADVANCE_CHANGE,
        description: constants.ADVANCE_CHANGE_FOR_FREE,
      } as IRestrictionProps;
      break;
    case FtcType.FtcWithFees:
      if (ftcCancelTypes[0] === FtcType.NoFtc) {
        cancellation = {
          symbol: FlightShopSymbol.ALERT,
          name: constants.CANCELLATION,
          description: constants.TRAVEL_CREDIT_FOR_A_FEE,
        };
      }
      advanceChange = {
        symbol: FlightShopSymbol.PURCHASABLE,
        name: constants.ADVANCE_CHANGE,
        description: constants.ADVANCE_CHANGE_FOR_A_FEE,
      } as IRestrictionProps;
      break;
    case FtcType.NoFtc:
      advanceChange = {
        symbol: FlightShopSymbol.UNAVAILABLE,
        name: constants.ADVANCE_CHANGE,
        description: constants.ADVANCE_CHANGE_NOT_ALLOWED,
      } as IRestrictionProps;
      break;
    default:
      break;
  }

  const getUtaSymbol = (uta: DullesUta) => {
    switch (uta.assessment) {
      case UtasPolicyAssessment.Benefit:
        return FlightShopSymbol.INCLUDED;
      case UtasPolicyAssessment.Fee:
        return FlightShopSymbol.PURCHASABLE;
      default:
        return FlightShopSymbol.UNAVAILABLE;
    }
  };

  const restrictions: IRestrictionProps[] =
    utas?.utas.reduce(
      (restrictionsToDisplay: IRestrictionProps[], uta: DullesUta) => {
        let restriction: IRestrictionProps;
        // note: replace "advance-change" from the utas.utas list with changePolicy data
        if (
          uta.category === UtasPolicyCategory.AdvanceChange &&
          advanceChange
        ) {
          restriction = advanceChange;
        } else if (
          uta.category === UtasPolicyCategory.Cancellation &&
          cancellation
        ) {
          restriction = cancellation;
        } else if (uta.headline == "Checked or Carry-on Bags") {
          // Overriding bag allowance for Spirit and Frontier to avoid confusions
          // see: https://hopper-jira.atlassian.net/browse/CMKT-1000
          restriction = {
            symbol: FlightShopSymbol.PURCHASABLE,
            name: uta.headline,
            description: uta.description,
          };
        } else {
          restriction = {
            symbol: getUtaSymbol(uta),
            // TODO: probably need a different type for category
            name: uta.category
              .split("-")
              .reduce(
                (name: string, segment: string) =>
                  `${name}${name.length > 0 ? " " : ""}${startCase(segment)}`,
                ""
              ),
            description: uta.description,
          };
        }
        if (uta.category === "seat-selection") {
          restrictionsToDisplay.unshift(restriction);
        } else {
          restrictionsToDisplay.push(restriction);
        }
        return restrictionsToDisplay;
      },
      [] as IRestrictionProps[]
    ) ?? [];

  // note: when "advance-change" is not found in utas.utas, push in changePolicy data
  if (
    !utas?.utas.find(
      (policy) => policy.category === UtasPolicyCategory.AdvanceChange
    ) &&
    advanceChange
  ) {
    restrictions.push(advanceChange);
  }

  // TODO: Add customer support (find it from data)
  restrictions.push({
    symbol: FlightShopSymbol.INCLUDED,
    name: constants.CUSTOMER_SUPPORT_HEADER_TEXT,
    description: constants.CUSTOMER_SUPPORT_SUBHEADER_TEXT,
  });

  return restrictions;
};

enum FlightShopSymbol {
  ALERT,
  INCLUDED,
  PURCHASABLE,
  UNAVAILABLE,
}

export interface IRestrictionProps {
  symbol: FlightShopSymbol;
  name: string;
  description: string | JSX.Element;
}

const restrictionIcon: { [k in FlightShopSymbol]: JSX.Element } = {
  [FlightShopSymbol.ALERT]: (
    <Icon className="icon-alert" name={IconName.AlertCircleOutline} />
  ),
  [FlightShopSymbol.INCLUDED]: (
    <Icon className="icon-available" name={IconName.CheckCircleTransparent} />
  ),
  [FlightShopSymbol.PURCHASABLE]: (
    <Icon className="icon-paid" name={IconName.MoneyOutlineTransparentIcon} />
  ),
  [FlightShopSymbol.UNAVAILABLE]: (
    <Icon className="icon-unavailable" name={IconName.NotAllowedSign} />
  ),
};

export const Restriction = (props: IRestrictionProps) => {
  const { symbol, name, description } = props;

  return (
    <IconContentView
      className="restriction"
      icon={restrictionIcon[symbol]}
      content={
        <>
          <Typography className="title">{name}</Typography>
          {description}
        </>
      }
    />
  );
};
