import React from "react";
import {
  AirlineIcon,
  ButtonWrap,
  Icon,
  IconName,
  MultiSelectDropdown,
} from "halifax";
import clsx from "clsx";

import "./styles.scss";
import * as textConstants from "./textConstants";
import { Box } from "@material-ui/core";
import {
  IFlightShopFilterSelector,
  initialFilterOptions,
} from "../../../../reducer";
import { AirlineCode } from "redmond";

interface IAirlineFilterProps {
  airlineFilter: AirlineCode[];
  setAirlineFilter: (airlineFilter: AirlineCode[]) => void;
  hasSetAirlineFilter?: boolean;
  showDropdownContentOnly?: boolean;
  appliedLabel?: string;
  icon?: IconName;
  title?: string;
  optionLabelPlacement?: "start" | "end";
  flightShopFilters: IFlightShopFilterSelector;
}

export const AirlineFilterSelection = (props: IAirlineFilterProps) => {
  const {
    airlineFilter,
    setAirlineFilter,
    showDropdownContentOnly,
    hasSetAirlineFilter,
    appliedLabel,
    icon,
    title,
    optionLabelPlacement,
    flightShopFilters,
  } = props;
  const { airlineOptions } = flightShopFilters;

  const allAirlinesToDisplay = React.useMemo(() => {
    return airlineOptions.map((airline) => {
      return {
        ...airline,
        icon: <AirlineIcon airlineCode={airline.value} />,
      };
    });
  }, [airlineOptions]);

  return (
    <Box className="flight-shop-airline-filter-container" tabIndex={0}>
      {!!title && showDropdownContentOnly && (
        <Box className="header-container">
          {icon && <Icon name={icon} />} {title}
        </Box>
      )}
      <MultiSelectDropdown
        currentValue={airlineFilter}
        dropdownLabel={textConstants.AIRLINE(appliedLabel)}
        options={allAirlinesToDisplay}
        setValue={setAirlineFilter}
        showDropdownContentOnly={showDropdownContentOnly}
        className={clsx("b2b-shop-filter", {
          "has-value": hasSetAirlineFilter,
        })}
        popoverClassName={clsx(
          "b2b",
          "flight-availability-airlines-popover",
          "filter-experiment"
        )}
        dropdownIcon={
          hasSetAirlineFilter && appliedLabel ? (
            <ButtonWrap
              onClick={(e) => {
                e.stopPropagation();
                setAirlineFilter(initialFilterOptions.airlineFilter);
              }}
            >
              <Icon name={IconName.XCircle} />
            </ButtonWrap>
          ) : undefined
        }
        optionLabelPlacement={optionLabelPlacement}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      />
    </Box>
  );
};
