import { LocationQueryEnum, ILocationQueryLabel } from "redmond";
import { delay, put } from "redux-saga/effects";
import { actions } from "../actions";
import { fetchFlightAutocomplete } from "../../../api/v0/search/fetchFlightAutocomplete";

export function* fetchOriginCategories(
  action: actions.IFetchDestinationCategories
) {
  yield delay(300);
  try {
    const requestBody: ILocationQueryLabel = {
      LocationQuery: LocationQueryEnum.Label,
      l: action.queryString,
    };
    const { categories } = yield fetchFlightAutocomplete(requestBody);
    yield put(actions.setOriginCategories(categories));
  } catch (e) {
    yield put(actions.setOriginCategories([]));
    yield put(actions.setOriginAutocompleteError(true));
  }
}
