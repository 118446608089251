import { all } from "redux-saga/effects";
import { sagas as packagesSearch } from "../modules/search/";
import { sagas as packagesRewards } from "../modules/rewards";
import { sagas as packagesTravelWallet } from "../modules/travel-wallet";
import { sagas as packagesAvailability } from "../modules/availability";
import { sagas as packageHotelShop } from "../modules/hotel-shop";
import { sagas as packagesFlightShop } from "../modules/flight-shop";
import { sagas as packageItineraryReview } from "../modules/itinerary-review";

export function* rootSaga() {
  yield all([
    packagesRewards.watchFetchRewardsAccounts(),

    packagesTravelWallet.watchFetchTravelWalletDetails(),
    packagesTravelWallet.watchFetchTravelWalletCreditHistorySaga(),

    packagesSearch.watchFetchDestinationCategories(),
    packagesSearch.watchFetchOriginCategories(),

    packagesAvailability.watchFetchPackagesAvailability(),

    packageHotelShop.watchFetchHotelShopSaga(),
    packageHotelShop.watchFetchDistanceFromLocationCategories(),

    packagesFlightShop.watchFetchFlightShopSaga(),
    packagesFlightShop.watchFetchPackagesTripDetailsSaga(),

    packageItineraryReview.watchFinalizePackage(),
  ]);
}
