import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { PackageDiscountBannerConnectorProps } from "./container";
import "./styles.scss";
import {
  BannerSeverity,
  getCurrencySymbol,
  Icon,
  IconName,
  NotificationBanner,
} from "halifax";
import { PACKAGE_BANNER_EARN_TEXT } from "./textConstants";
import {
  CardPaymentSelectors,
  RewardsPaymentSelectors,
  useCheckoutStateSelector,
} from "@capone/checkout";
import { getQuotedPackageDiscount } from "../../state/selectors/common";

export interface IPackageDiscountBannerProps
  extends RouteComponentProps,
    PackageDiscountBannerConnectorProps {}

export const PackageDiscountBanner = ({}: IPackageDiscountBannerProps) => {
  const quotedPackagedDiscount = useCheckoutStateSelector(
    getQuotedPackageDiscount
  );
  const earnForSelectedCard = useCheckoutStateSelector(
    RewardsPaymentSelectors.getEarnForSelectedRewardsAccount
  );
  const earnForHighestEarnAccount = useCheckoutStateSelector(
    RewardsPaymentSelectors.getEarnForHighestEarnRewardsAccount
  );
  const selectedCardPaymentRewardsAccountId = useCheckoutStateSelector(
    CardPaymentSelectors.getSelectedPaymentMethodRewardsAccountId
  );
  const highestEarnRewardsAccount = useCheckoutStateSelector(
    RewardsPaymentSelectors.getHighestEarnAccount
  );

  const rewardsAccountToUse = useCheckoutStateSelector(
    RewardsPaymentSelectors.getRewardsAccounts
  )?.find(
    (account) =>
      account.accountReferenceId ===
      (selectedCardPaymentRewardsAccountId ||
        highestEarnRewardsAccount?.accountReferenceId)
  );

  const earnToUse = earnForSelectedCard || earnForHighestEarnAccount;

  if (!quotedPackagedDiscount || !earnToUse?.value || !rewardsAccountToUse)
    return null;

  return (
    <NotificationBanner
      icon={<Icon name={IconName.PiggyBankCircleOutline} />}
      className="package-discount-banner"
      content={PACKAGE_BANNER_EARN_TEXT(
        {
          value: quotedPackagedDiscount.amount.amount,
          currencyCode: quotedPackagedDiscount.amount.currency,
          currencySymbol: getCurrencySymbol(
            quotedPackagedDiscount.amount.currency
          ),
        },
        earnToUse,
        rewardsAccountToUse
      )}
      severity={BannerSeverity.IMPROVEMENT}
    />
  );
};
