import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { PassportModal } from "./component";
import { IStoreState } from "../../../../reducers/types";

const mapStateToProps = (_: IStoreState) => {
  return {};
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PassportModalConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedPassportModal = withRouter(connector(PassportModal));
