import { assign } from "xstate";
import { cartHelpers, ParentState } from "@capone/checkout";
import { ProductOpaqueValue } from "@b2bportal/purchase-api";
import { PackagesMachineContext } from "../types";
import { InitializeCheckoutStateEvent, SetPlatformEvent } from "../events";
import { RoomInfoProducts, Lodging } from "@b2bportal/lodging-api";
import { PaymentOpaqueValue } from "@b2bportal/air-booking-api";
import { getOpaquePayments } from "../selectors/common";

export const setPaymentFulfillParams = assign(
  (ctx: PackagesMachineContext, _event) => {
    const opaquePayments = getOpaquePayments({ context: ctx });

    ctx[ParentState.cartFulfill].fulfillRequestPayments?.push(
      ...opaquePayments
    );

    return ctx;
  }
);

export const initializeCheckoutState = assign(
  (ctx: PackagesMachineContext, event: InitializeCheckoutStateEvent) => {
    ctx.flightShop.selectedTrip = event.payload.selectedTrip;
    ctx.flightShop.tripDetails = event.payload.tripDetails;
    ctx.lodgingShop.fromDate = event.payload.hotelFromDate;
    ctx.lodgingShop.untilDate = event.payload.hotelUntilDate;
    ctx.lodgingShop.selectedLodging = event.payload
      .selectedLodging as unknown as Lodging;
    ctx.lodgingShop.selectedRoom = event.payload
      .selectedRoom as unknown as RoomInfoProducts;
    ctx.lodgingShop.selectedRoomRateId =
      event.payload.selectedRoom.products[0]?.rateId?.value;
    ctx.finalizePackageResponse = event.payload.finalizePackageResponse;
    ctx.packagePricing = event.payload.packagePricing;
    ctx.lodgingShop.guests = {
      adults: event.payload.searchedAdultsCount,
      children: [
        ...event.payload.searchedChildren,
        ...event.payload.searchedInfants,
      ],
    };
    ctx.searchedDestination = event.payload.searchedDestination;

    return ctx;
  }
);

export const addPackageProduct = assign(
  (ctx: PackagesMachineContext, _event: unknown) => {
    const packageProduct: ProductOpaqueValue = {
      type: "Package",
      value: {
        destinationName:
          ctx.searchedDestination?.label ||
          `${ctx[ParentState.lodgingShop].selectedLodging?.lodging.city}, ${
            ctx[ParentState.lodgingShop].selectedLodging?.lodging.country
          }`,
        savings:
          ctx.packagePricing?.totalPackageSavings?.fiat
      },
    };
    const ctxWithPackage = cartHelpers.addQuoteProduct(packageProduct, ctx);

    return ctxWithPackage;
  }
);

export const addPackageDiscount = assign(
  (ctx: PackagesMachineContext, _event: unknown) => {
    const packageDiscount: PaymentOpaqueValue = {
      type: "PackageSyntheticDiscount" as any, // TODO: remove 'any' cast once Payment model is updated with PackageSyntheticDiscount
      value: {},
    };

    ctx[ParentState.cartUpdate].addPayments = [
      ...(ctx[ParentState.cartUpdate].addPayments.filter(
        (payment) => payment.type !== ("PackageSyntheticDiscount" as any)
      ) ?? []),
      packageDiscount,
    ];

    return ctx;
  }
);

export const setPlatform = assign(
  (ctx: PackagesMachineContext, event: SetPlatformEvent) => {
    ctx.platform = event.platform;

    return ctx;
  }
);
