export const CUSTOMER_SUPPORT_HEADER_TEXT = "Customer Support";
export const CUSTOMER_SUPPORT_SUBHEADER_TEXT =
  "Guaranteed assistance with your trip";

export const READY_TO_BOOK_HEADER_TEXT = "Ready to book?";
export const REVIEW_ITINERARY_SUBHEADER_TEXT =
  "Here are some details you should know about.";

export const REVIEW_ITINERARY_HEADER_TEXT_COMBINATION =
  "Your trip is a combination of 2 one-way fares.";
export const REVIEW_ITINERARY_SUBHEADER_TEXT_COMBINATION =
  "Restrictions may differ between airlines.";

export const ADVANCE_CHANGE = "Advance Change";
export const ADVANCE_CHANGE_FOR_FREE =
  "Advance flight change allowed for free.";
export const ADVANCE_CHANGE_FOR_A_FEE =
  "Advance flight change allowed for a fee.";
export const ADVANCE_CHANGE_NOT_ALLOWED = "Advance flight change not allowed.";

export const CANCELLATION = "Cancellation";
export const TRAVEL_CREDIT_FOR_FREE =
  "Non-refundable, but an airline travel credit is available.";
export const TRAVEL_CREDIT_FOR_A_FEE =
  "Non-refundable, but an airline travel credit is available for a fee.";
export const TRAVEL_CREDIT_NOT_ALLOWED = "Non-refundable.";
export const AIRLINE_SPECIFIC_RESTRICTIONS =
  "Compare airline-specific restrictions";
