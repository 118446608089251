import axios from "axios";
import { config } from "../../config";

import { flightLodgingFinalizeApiPrefix } from "../paths";
import { FinalizePackageRequest, FinalizePackageResponse } from "redmond";

export const finalizeFlightLodging = (
  body: FinalizePackageRequest
): Promise<FinalizePackageResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await axios.post(flightLodgingFinalizeApiPrefix, body, {
        baseURL: config.baseURL,
      });

      resolve(res.data.value);
    } catch (e) {
      reject(e);
    }
  });
