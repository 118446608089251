import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { RouteComponentProps } from "react-router";
import { IStoreState } from "../../../../reducers/types";

import { fetchTravelWalletDetails } from "../../../travel-wallet/actions/actions";
import { DesktopFlightShop } from "./component";
import { fetchPackagesFlightShop } from "../../actions/actions";

import { getDestination, getOrigin } from "../../../search/reducer";
import { actions } from "../../actions";
import {
  getActiveFiltersCount,
  sortOptionSelector,
  getIsOutgoing,
} from "../../reducer";

const mapStateToProps = (state: IStoreState) => {
  return {
    origin: getOrigin(state),
    destination: getDestination(state),
    sortOption: sortOptionSelector(state),
    activeFiltersCount: getActiveFiltersCount(state),
    isOutgoing: getIsOutgoing(state),
  };
};

export const mapDispatchToProps = {
  fetchTravelWalletDetails: fetchTravelWalletDetails,
  fetchPackagesFlightShop: fetchPackagesFlightShop,
  setSortOption: actions.setSortOption,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type DesktopShopConnectorProps = ConnectedProps<typeof connector>;

export interface IDesktopFlightShopProps
  extends DesktopShopConnectorProps,
    RouteComponentProps {}

export const ConnectedDesktopFlightShop = withRouter(
  connector(DesktopFlightShop)
);
