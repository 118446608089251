import { takeEvery, takeLatest } from "redux-saga/effects";

import {
  FETCH_PACKAGE_FLIGHT_SHOP,
  FETCH_PACKAGES_TRIP_DETAILS,
} from "../actions/constants";

import fetchPackagesFlightShopSaga from "./fetchPackagesFlightShopSaga";
import fetchPackagesTripDetailsSaga from "./fetchPackagesTripDetailsSaga";

export function* watchFetchFlightShopSaga() {
  yield takeLatest(FETCH_PACKAGE_FLIGHT_SHOP, fetchPackagesFlightShopSaga);
}

export function* watchFetchPackagesTripDetailsSaga() {
  yield takeEvery(FETCH_PACKAGES_TRIP_DETAILS, fetchPackagesTripDetailsSaga);
}
