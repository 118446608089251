import dayjs from "dayjs";
import { formatInterval } from "halifax";
import { FareSliceDetails, getIsMixedClassByProps, TripSlice } from "redmond";

const OUTBOUND = "Outbound";
const RETURN = "Return";
// const FLIGHT = "Flight";

export const getReviewCardHeader = (
  isDeparture: boolean,
  location: string,
  date: Date | string,
  parsable = false,
  airportCode?: string
) =>
  //Added semicolon to parse into two phrases
  `${isDeparture ? OUTBOUND : RETURN} to ${location} ${
    airportCode ? `(${airportCode})` : ""
  } ${parsable ? ":" : ""} on ${dayjs(date).format("ddd, MMM D")}`;

export const CHANGE = "Change";
export const DEPARTURE_KEY = "departure";
export const RETURN_KEY = "return";

export const bestFlightText = "Best Overall";
export const cheapestFlightText = "Best Price";
export const bestQualityText = "Best Quality";
export const fastestText = "Fastest";

export const createStopoverString = (trip: TripSlice) => {
  let layoverString = "";
  const stopoverSegments = trip.segmentDetails.filter(
    (s) => s.stopoverDurationMinutes && s.stopoverDurationMinutes > 0
  );
  stopoverSegments.forEach((segment, index) => {
    layoverString = segment.stopoverDurationMinutes
      ? `${layoverString}
         ${formatInterval(segment.stopoverDurationMinutes)}
        ${segment.destinationCode}`
      : "";

    if (index < stopoverSegments.length - 1) {
      layoverString = layoverString + " / ";
    }
  });
  return layoverString;
};

export const VIEW_DETAILS = "View details";

export const getIsMixedClass = (slice: FareSliceDetails): boolean => {
  return getIsMixedClassByProps({
    fareShelfRating: slice.fareShelf?.rating,
    fareShelfBrandName: slice.fareShelf?.brandName,
    fareShelfShortBrandName: slice.fareShelf?.shortBrandName,
    cabinClassNames: slice.fareDetails.segments.map(
      (segment) => segment.cabinClassName
    ),
  });
};

export const COMBINATION_FLIGHT_WARNING =
  "This fare is part of a flight combination";
export const COMBINATION_FLIGHT_TOOLTIP =
  "Restrictions may differ between outbound and return flights. Cancellations or changes to one of your flights will not alter the other automatically.";

export const getStopsString = (stops: number) =>
  stops === 0 ? "Nonstop" : stops > 1 ? `${stops} stops` : "1 stop";

export const getPlusDays = (trip: TripSlice): number => {
  return trip.segmentDetails
    .filter((s) => s.plusDays && s.plusDays > 0)
    .reduce((total, segment) => total + (segment.plusDays ?? 0), 0);
};

export const CHANGE_FLIGHT = "Change flight";
