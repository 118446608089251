import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography, useScrollTrigger } from "@material-ui/core";
import clsx from "clsx";
import dayjs from "dayjs";
import { ActionLink } from "halifax";
import React, { useMemo } from "react";
import { RouteComponentProps } from "react-router";
import { MobileShopHeaderConnectorProps } from "./container";
import "./styles.scss";
import { FlightShopStep } from "redmond";

export interface IMobileShopHeaderProps
  extends RouteComponentProps,
    MobileShopHeaderConnectorProps {
  onClickBackButton?: () => void;
}

export const MobileShopHeader = (props: IMobileShopHeaderProps) => {
  const {
    origin,
    destination,
    isOutgoing,
    fromDate,
    untilDate,
    history,
    setPackagesFlightShopProgress,
  } = props;

  const scrollTrigger = useScrollTrigger({ disableHysteresis: true });

  const defaultOnClickBackButton = useMemo(() => {
    if (!isOutgoing) {
      // if is selecting return flight, back button takes you to select outgoing flight
      return () =>
        setPackagesFlightShopProgress(FlightShopStep.ChooseDeparture);
    }

    return () => history.goBack();
    // return returnToAvailability;
  }, [isOutgoing]);

  const MiddleSection = () => {
    return (
      <Box
        className={clsx("locations-and-dates-section", "header-middle-section")}
      >
        <>
          <Typography variant="body1" className="locations-section">
            {isOutgoing
              ? // doing origin?.label.split("(")[0] because the label for the origin includes the airport code
                `${origin?.label.split("(")[0]} to ${destination?.label}`
              : `${destination?.label} to ${origin?.label.split("(")[0]}`}
          </Typography>
          <Box className="dates-section">
            <Typography variant="body2">
              {isOutgoing
                ? `${dayjs(fromDate).format("MMM DD")}`
                : `${dayjs(untilDate).format("MMM DD")}`}
            </Typography>
          </Box>
        </>
      </Box>
    );
  };

  return (
    <>
      <Box
        className={clsx("flight-shop-progress-header-root global-mobile-nav", {
          scrolled: scrollTrigger,
        })}
      >
        <Box className="flight-shop-progress-header-container">
          <Box className="go-back-button-section">
            <ActionLink
              className={clsx("go-back-button")}
              onClick={defaultOnClickBackButton}
              content={<FontAwesomeIcon icon={faChevronLeft} />}
            />
          </Box>
          <MiddleSection />
        </Box>
      </Box>
    </>
  );
};
