import { connect, ConnectedProps } from "react-redux";

import { FlightShopHeader } from "./component";
import {
  getDestination,
  getOrigin,
  getDepartureDate,
  getReturnDate,
} from "../../../search/reducer/selectors";
import {
  getActiveFiltersCount,
  getAirportMap,
  getIsOutgoing,
  sortOptionSelector,
} from "../../reducer/selectors";

import { IStoreState } from "../../../../reducers/types";
import { fetchTravelWalletDetails } from "../../../travel-wallet/actions/actions";
import { fetchPackagesFlightShop, setSortOption } from "../../actions/actions";

const mapStateToProps = (state: IStoreState) => {
  return {
    isOutgoing: getIsOutgoing(state),
    destination: getDestination(state),
    origin: getOrigin(state),
    departureDate: getDepartureDate(state),
    returnDate: getReturnDate(state),
    sortOption: sortOptionSelector(state),
    activeFiltersCount: getActiveFiltersCount(state),
    airportMap: getAirportMap(state),
  };
};

const mapDispatchToProps = {
  fetchTravelWalletDetails: fetchTravelWalletDetails,
  fetchPackagesFlightShop: fetchPackagesFlightShop,
  setSortOption: setSortOption,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type FlightShopHeaderConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedFlightShopHeader = connector(FlightShopHeader);
