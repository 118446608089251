import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { PackagesRewardsAndPayment } from "./component";
import { IStoreState } from "../../../../reducers/types";
import { getRewardsAccounts } from "../../../rewards/reducer";

export const mapStateToProps = (state: IStoreState) => {
  return {
    rewardsAccounts: getRewardsAccounts(state),
  };
};

export const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PackagesRewardsAndPaymentConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedPackagesRewardsAndPayment = withRouter(
  connector(PackagesRewardsAndPayment)
);
