import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../../../../reducers/types";
import {
  setDepartureDate,
  setReturnDate,
  ISetReturnDate,
  ISetDepartureDate,
} from "../../../../actions/actions";
import { getDepartureDate, getReturnDate } from "../../../../reducer";
import { MobileCalendarPicker } from "./component";
interface IDispatchProps {
  setDepartureDate: (date: Date | null) => ISetDepartureDate;
  setReturnDate: (date: Date | null) => ISetReturnDate;
}

const mapDispatchToProps = {
  setDepartureDate,
  setReturnDate,
};

interface IStateProps {
  readyToSearch: false | Date | null;
}

const mapStateToProps = (state: IStoreState) => {
  const departureDate = getDepartureDate(state);
  const returnDate = getReturnDate(state);

  const readyToSearch = departureDate && returnDate;

  return {
    readyToSearch,
  };
};

interface IOwnProps {}

const mergeProps = (
  stateProps: IStateProps,
  dispatchProps: IDispatchProps,
  ownProps: IOwnProps
) => {
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps, mergeProps);

export type MobileCalendarPickerConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedMobileCalendarPicker = connector(MobileCalendarPicker);
