import axios from "axios";
import { ILocationQueryLabel, IResponse } from "redmond";
import { airShoppingApi } from "@b2bportal/air-shopping-api";

export const fetchFlightAutocomplete = (
  body: ILocationQueryLabel
): Promise<IResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await airShoppingApi(
        axios as any
      ).apiV0AutocompleteLocationPost({ ...body, l: body.l || "" });
      if (res.status === 499) {
        throw new Error();
      } else {
        resolve(res.data as any);
      }
    } catch (e) {
      reject(e);
    }
  });
