export const MORE_FILTERS_TEXT = "More Filters";
export const getFiltersAppliedText = (count: number) =>
  `(${count}) Filter${count > 1 ? "s" : ""} applied`;
export const EDIT_TRAVELERS_TITLE = "Travelers";

export const TRAVELERS_SELECT_TITLES = {
  modalTitle: "Edit Travelers",
  adultTitle: "Adults",
  adultSubtitle: "",
  childrenTitle: "Children",
  chilrenSubtitle: "Ages 2 to 17",
  childAgeInputSubtitle:
    "To ensure the most accurate rates, please enter the exact age of the children on this booking.",
  infantLapTitle: "Infants on lap",
  infantLapSubtitle: "Under the age of 2",
  infantSeatTitle: "Infants in seat",
  infantSeatSubtitle: "Under the age of 2",
  infantTitle: "Infants",
  infantSubtitle: "Younger than 2",
};
