import {
  FlightPassengerSelectors,
  getParentState,
  ParentState,
  PassengerErrorModalTypes,
  useCheckoutState,
  useChildMachineSelector,
  useChildMachineState,
} from "@capone/checkout";
import { GenericInfoPopup, Icon, IconName, useDeviceTypes } from "halifax";
import React from "react";
import { Event, TEvent } from "../../state/events";
import {
  GENERIC_ERROR_SUBTITLE,
  GENERIC_ERROR_TITLE,
  INVALID_PASSENGER_TITLE,
  LAP_INFANT_TOO_OLD_SUBTITLE,
  MIN_AGE_NOT_MET_SUBTITLE,
  NO_PASSENGERS_SUBTITLE,
  SEATED_INFANTS_UNSUPPORTED_SUBTITLE,
  TOO_MANY_LAP_INFANTS_SUBTITLE,
  TOO_MANY_PASSENGERS_SUBTITLE,
  TOO_MANY_PASSENGERS_TITLE,
  UPDATE_TRAVELERS_CTA_TEXT,
} from "./constants";
import { IGenericInfoPopupProps } from "halifax/build/GenericInfoPopup";
import { PackagesMachineContext } from "../../state/types";
import { RouteComponentProps } from "react-router-dom";
import { PassengerErrorModalConnectorProps } from "./container";
import clsx from "clsx";

export interface IPassengerErrorModalProps
  extends RouteComponentProps,
    PassengerErrorModalConnectorProps {}

export const PassengerErrorModal = () => {
  const { matchesMobile } = useDeviceTypes();

  const [state] = useCheckoutState<TEvent, PackagesMachineContext>();

  const parentState = getParentState(state.value) as ParentState;

  const passportModalOpen = parentState === ParentState.passport;

  const [, passengerChildMachineSend] = useChildMachineState<
    TEvent,
    PackagesMachineContext
  >(ParentState.passengerInformation);

  const modalOpen = useChildMachineSelector(
    ParentState.passengerInformation,
    FlightPassengerSelectors.getPassengerErrorOpen
  );
  const passengerError = useChildMachineSelector(
    ParentState.passengerInformation,
    FlightPassengerSelectors.getPassengerError
  );

  const infantOnlyError = useChildMachineSelector(
    ParentState.passengerInformation,
    FlightPassengerSelectors.getInfantOnlyError
  );

  const clearError = () =>
    passengerChildMachineSend(Event.CLEAR_PASSENGER_INFORMATION_ERROR);

  const openPassengerPicker = () => {
    passengerChildMachineSend(Event.OPEN_PASSENGER_PICKER);
    clearError();
  };

  const openPassportForm = () => {
    passengerChildMachineSend(Event.OPEN_PASSPORT_FORM);
  };

  if (
    passengerError?.type === PassengerErrorModalTypes.PassportValidationFail
  ) {
    if (!passportModalOpen) {
      openPassportForm();
    }

    return null;
  }

  const getErrorModalProps: () => Pick<
    IGenericInfoPopupProps,
    "title" | "subtitle" | "buttons"
  > = () => {
    const buttons: IGenericInfoPopupProps["buttons"] = [
      {
        buttonText: UPDATE_TRAVELERS_CTA_TEXT,
        onClick: openPassengerPicker,
      },
    ];
    if (infantOnlyError) {
      return {
        title: INVALID_PASSENGER_TITLE,
        subtitle: MIN_AGE_NOT_MET_SUBTITLE,
        buttons,
      };
    }
    switch (passengerError?.type) {
      case PassengerErrorModalTypes.NoPassengers:
        return {
          title: INVALID_PASSENGER_TITLE,
          subtitle: NO_PASSENGERS_SUBTITLE,
          buttons,
        };

      case PassengerErrorModalTypes.ADTRequired:
        return {
          title: INVALID_PASSENGER_TITLE,
          subtitle: MIN_AGE_NOT_MET_SUBTITLE,
          buttons,
        };
      case PassengerErrorModalTypes.INSNotAllowed:
        return {
          title: INVALID_PASSENGER_TITLE,
          subtitle: SEATED_INFANTS_UNSUPPORTED_SUBTITLE,
          buttons,
        };
      case PassengerErrorModalTypes.InSufficientADTCount:
      case PassengerErrorModalTypes.UnaccompaniedMinor:
        return {
          title: INVALID_PASSENGER_TITLE,
          subtitle: TOO_MANY_LAP_INFANTS_SUBTITLE,
          buttons,
        };
      case PassengerErrorModalTypes.TooManyPassengers:
        return {
          title: TOO_MANY_PASSENGERS_TITLE,
          subtitle: TOO_MANY_PASSENGERS_SUBTITLE,
          buttons,
        };
      case PassengerErrorModalTypes.InfantIsMinor:
        return {
          title: INVALID_PASSENGER_TITLE,
          subtitle: LAP_INFANT_TOO_OLD_SUBTITLE,
          buttons,
        };
      case PassengerErrorModalTypes.TripPricing:
      case PassengerErrorModalTypes.UpdatePassenger:
      case PassengerErrorModalTypes.FetchPassengers:
      case PassengerErrorModalTypes.ValidatePassengers:
      case PassengerErrorModalTypes.DeletePassenger:
      case PassengerErrorModalTypes.ValidateAndTripPricing:
      default:
        return {
          title: GENERIC_ERROR_TITLE,
          subtitle: GENERIC_ERROR_SUBTITLE,
          buttons,
        };
    }
  };

  return (
    <GenericInfoPopup
      open={modalOpen}
      image={<Icon className="error-icon" name={IconName.ErrorState} />}
      className={clsx("packages-passenger-error-modal", {
        mobile: matchesMobile,
      })}
      {...getErrorModalProps()}
    />
  );
};
