import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import { AvailabilitySort } from "./component";
import { setPackagesHotelsAvailabilitySortOption } from "../../actions/actions";
import { IStoreState } from "../../../../reducers/types";
import { getPackagesAvailabilityLodgingsSortOption } from "../../reducer";

const mapStateToProps = (state: IStoreState) => {
  return {
    sortOption: getPackagesAvailabilityLodgingsSortOption(state),
  };
};

const mapDispatchToProps = {
  setPackagesHotelsAvailabilitySortOption,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type AvailabilitySortConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedAvailabilitySort = withRouter(
  connector(AvailabilitySort)
);
