import { connect, ConnectedProps } from "react-redux";

import { getDepartureMonths, getCurrency } from "../../../../reducer";
import { MonthAndDatePicker } from "./component";
import { IStoreState } from "../../../../../../reducers/types";

const mapStateToProps = (state: IStoreState) => {
  return {
    months: getDepartureMonths(state),
    currency: getCurrency(state),
  };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MonthAndDatePickerConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedMonthAndDatePicker = connector(MonthAndDatePicker);
