import React from "react";
import { MobileHotelShopRoomTypePickerPanelRedesign } from "halifax";
import { MobileHotelShopRoomTypePickerPanelConnectorProps } from "./container";
import { RouteComponentProps } from "react-router";
import { PackageHotelShopStep } from "../../reducer/state";
import { CorpRoomInfoProducts } from "redmond/build/capone-corporate";
import { getConfigTenant } from "@capone/common";
import { getRoomPolicyComplianceType } from "@capone/common/src/utils/hotelShopPolicyCompliance";

export interface MobileHotelShopRoomTypePickerPanelProps
  extends MobileHotelShopRoomTypePickerPanelConnectorProps,
    RouteComponentProps {
  onContinueClicked?: () => void;
}

export const MobileHotelShopRoomTypePickerPanel = (
  props: MobileHotelShopRoomTypePickerPanelProps
) => {
  const {
    setPackageHotelShopProgress,
    rewardsKey,
    onContinueClicked,
    roomInfoProducts,
  } = props;

  React.useEffect(() => {
    const footer = document.querySelector(
      ".mclean-generic-footer"
    ) as HTMLElement;
    const fixedComponent = document.querySelector(
      ".mobile-hotel-shop-price-panel-redesign"
    ) as HTMLElement;
    const travelWalletDrawerButton = document.querySelector(
      ".travel-wallet-button"
    ) as HTMLElement;
    if (fixedComponent && footer) {
      footer.style.paddingBottom = `${fixedComponent.clientHeight}px`;
    }
    if (fixedComponent && travelWalletDrawerButton) {
      travelWalletDrawerButton.style.bottom = `${
        fixedComponent.clientHeight + 20
      }px`;
    }
    return () => {
      if (footer) {
        footer.style.paddingBottom = "85px";
      }
      if (travelWalletDrawerButton) {
        travelWalletDrawerButton.style.bottom = "100px";
      }
    };
  }, [rewardsKey]);
  return (
    <MobileHotelShopRoomTypePickerPanelRedesign
      {...props}
      onClickContinue={() => {
        setPackageHotelShopProgress(PackageHotelShopStep.ChooseRoom);
        if (onContinueClicked) {
          onContinueClicked();
        }
      }}
      className="b2b"
      policyComplianceType={getRoomPolicyComplianceType(
        roomInfoProducts as CorpRoomInfoProducts[]
      )}
      tenant={getConfigTenant()}
      showPrice={false}
    ></MobileHotelShopRoomTypePickerPanelRedesign>
  );
};
