import { createSelector } from "reselect";
import { IStoreState } from "../../../../reducers/types";
import { initialFilterOptions } from "../index";
import dayjs from "dayjs";

export const getOpenDatesModal = (state: IStoreState) =>
  state.packagesSearch.openDatesModal;

export const getOrigin = (state: IStoreState) => state.packagesSearch.origin;

export const getDestination = (state: IStoreState) =>
  state.packagesSearch.destination;

export const getOriginCategories = (state: IStoreState) =>
  state.packagesSearch.originCategories;

export const getOriginCategoriesLoading = (state: IStoreState) =>
  state.packagesSearch.originCategoriesLoading;

export const getDestinationCategories = (state: IStoreState) =>
  state.packagesSearch.destinationCategories;

export const getDestinationCategoriesLoading = (state: IStoreState) =>
  state.packagesSearch.destinationCategoriesLoading;

export const getCurrency = (state: IStoreState) =>
  state.packagesSearch.currency;

export const getCalendarQueryOrigin = (state: IStoreState) =>
  state.packagesSearch.calendarQueryOrigin;

export const getCalendarQueryDestination = (state: IStoreState) =>
  state.packagesSearch.calendarQueryDestination;

export const getDepartureDate = (state: IStoreState) =>
  state.packagesSearch.departureDate;

export const getReturnDate = (state: IStoreState) =>
  state.packagesSearch.returnDate;

export const getDepartureMonths = (state: IStoreState) =>
  state.packagesSearch.departureMonths;

export const getAwaitingRefetch = (state: IStoreState) =>
  state.packagesSearch.awaitingRefetch;

export const getStopsOption = (state: IStoreState) =>
  state.packagesSearch.stopsOption;

export const getMaxPriceFilter = (state: IStoreState) =>
  state.packagesSearch.maxPriceFilter;

export const getFareclassOptionFilter = (state: IStoreState) =>
  state.packagesSearch.fareclassOptionFilter;

export const getHasSetStopsOption = (state: IStoreState) =>
  state.packagesSearch.stopsOption !== initialFilterOptions.stopsOption;

export const getStopsOptionFilter = (state: IStoreState) =>
  state.packagesSearch.stopsOption;

export const getHasSetFareClassFilter = (state: IStoreState) =>
  Object.values(state.packagesSearch.fareclassOptionFilter).includes(true);

export const hasSelectedDates = createSelector(
  getDepartureDate,
  getReturnDate,
  (departureDate, returnDate): boolean => {
    return !!departureDate && !!returnDate;
  }
);

export const getHasOriginAutocompleteError = (state: IStoreState) =>
  state.packagesSearch.hasOriginAutocompleteError;

export const getHasDestinationAutocompleteError = (state: IStoreState) =>
  state.packagesSearch.hasDestinationAutocompleteError;

export const getTravelers = (state: IStoreState) => {
  const { adultsCount, children, infants } = state.packagesSearch;

  return { adultsCount, children, infants };
};

export const getNightCount = createSelector(
  getDepartureDate,
  getReturnDate,
  (departureDate, returnDate): number | null => {
    if (departureDate && returnDate) {
      return dayjs(returnDate).diff(dayjs(departureDate), "day");
    } else {
      return null;
    }
  }
);

export const getAdultsCount = (state: IStoreState) =>
  state.packagesSearch.adultsCount;

export const getChildren = (state: IStoreState) =>
  state.packagesSearch.children;

export const getInfants = (state: IStoreState) => state.packagesSearch.infants;
