import React, { useEffect, useState } from "react";
import "./styles.scss";
import "./styles.scss";
import { RouteComponentProps } from "react-router-dom";
import {
  ActionButton,
  ActionLink,
  CloseButtonIcon,
  DesktopPopupModal,
  GenericInfoPopup,
  Icon,
  IconName,
  MobileFloatingButton,
  MobilePopoverCard,
  PassportInfoForm,
  useDeviceTypes,
} from "halifax";
import { INationality } from "redmond";
import { PassportModalConnectorProps } from "./container";
import {
  getPassengerName,
  PASSPORT_MODAL_ENHANCEMENT_SUBTITLE,
  PASSPORT_MODAL_ENHANCEMENT_TITLE,
} from "./textConstants";
import clsx from "clsx";
import {
  FlightPassengerSelectors,
  getChildState,
  getParentState,
  ParentState,
  PassportSelectors,
  PassportState,
  useCheckoutState,
  useCheckoutStateSelector,
  useChildMachineState,
} from "@capone/checkout";
import { Event, TEvent } from "../../state/events";
import { PackagesMachineContext } from "../../state/types";
import { Passport, Person } from "@b2bportal/air-booking-api";
import {
  GENERIC_ERROR_SUBTITLE,
  GENERIC_ERROR_TITLE,
  UPDATE_TRAVELERS_CTA_TEXT,
} from "../PassengerErrorModal/constants";

export interface PassportModalProps
  extends PassportModalConnectorProps,
    RouteComponentProps {
  onCloseMobileModal?: () => void;
}

export const PassportModal = ({ onCloseMobileModal }: PassportModalProps) => {
  const [, passengerChildMachineSend] = useChildMachineState<
    TEvent,
    PackagesMachineContext
  >(ParentState.passengerInformation);

  const [state, send] = useCheckoutState<TEvent, PackagesMachineContext>();

  const parentState = getParentState(state.value) as ParentState;
  const childState = getChildState(state.value) as PassportState;

  const selectedPassengers = useCheckoutStateSelector(
    FlightPassengerSelectors.getAllSelectedUserPassengersParent
  );

  const openModal = parentState === ParentState.passport;
  const { matchesDesktop } = useDeviceTypes();
  const [userIndex, setUserIndex] = useState(0);

  const currentPassenger: Person = selectedPassengers[userIndex];

  const [passportInfo, setPassportInfo] = useState<Passport | undefined>(
    currentPassenger?.passport
  );
  const [nationality, setNationality] = useState<INationality | undefined>(
    currentPassenger?.nationality
  );
  const [saveAndContinuePressed, setSavedAndContinuePressed] = useState(false);
  const [showErrors, setShowErrors] = useState(false);

  const passportError = useCheckoutStateSelector(
    PassportSelectors.getPassportError
  );

  useEffect(() => {
    setSavedAndContinuePressed(false);
  }, [openModal]);

  useEffect(() => {
    if (passportError) {
      setShowErrors(true);
    }
  }, [passportError]);

  useEffect(() => {
    if (currentPassenger) {
      setPassportInfo(currentPassenger.passport);
      setNationality(currentPassenger.nationality);
    }
  }, [currentPassenger]);

  const updatePassengerWithPassport = (
    person: Person,
    onContinue?: boolean
  ) => {
    send({
      type: onContinue
        ? Event.UPDATE_PASSPORT_AND_CONTINUE
        : Event.UPDATE_PASSENGER,
      person,
    });
  };

  const handleAddPassportInfo = () => {
    const person = selectedPassengers[userIndex];

    if (isDisabled()) {
      setShowErrors(true);
      return;
    }
    person.passport = passportInfo;
    person.nationality = nationality;
    if (userIndex < selectedPassengers.length - 1) {
      updatePassengerWithPassport(person);
      setPassportInfo(selectedPassengers[userIndex + 1].passport);
      setUserIndex(userIndex + 1);
    }
    if (userIndex === selectedPassengers.length - 1) {
      updatePassengerWithPassport(person, true);
      setPassportInfo(undefined);
      passengerChildMachineSend(Event.CLEAR_PASSENGER_INFORMATION_ERROR);
    }
  };

  const handleSaveAndContinue = () => {
    setSavedAndContinuePressed(true);

    handleAddPassportInfo();
  };

  const handleClose = () => {
    send(Event.PREVIOUS);
  };

  const isDisabled = () => {
    return (
      !passportInfo ||
      !passportInfo?.countryOfIssue ||
      !passportInfo?.expiration ||
      !passportInfo?.number
    );
  };

  if (selectedPassengers.length < 1) {
    return <></>;
  }
  return (
    <>
      {matchesDesktop ? (
        <DesktopPopupModal
          className="packages-passport-form-popup"
          open={openModal}
          hideXButton
          onClose={() => {
            handleClose();
            setSavedAndContinuePressed(false);
          }}
        >
          <PassportInfoForm
            title={
              userIndex === 0 ? PASSPORT_MODAL_ENHANCEMENT_TITLE : undefined
            }
            subtitle={
              userIndex === 0 ? PASSPORT_MODAL_ENHANCEMENT_SUBTITLE : undefined
            }
            subtitle2={getPassengerName(
              `${currentPassenger.givenName} ${currentPassenger.surname}`,
              userIndex + 1
            )}
            passportInfo={currentPassenger.passport ?? null}
            onPassportInfoChange={setPassportInfo}
            onNationalityChange={setNationality}
            nationality={currentPassenger.nationality}
            useEnhancedPassportForm
            hasErrors={saveAndContinuePressed && showErrors}
          />

          <ActionButton
            className="save-passport-button enhanced"
            message={
              childState === PassportState.idle
                ? "Save and Continue"
                : "Saving..."
            }
            onClick={handleSaveAndContinue}
            defaultStyle="h4r-primary"
            disabled={childState !== PassportState.idle}
          />
        </DesktopPopupModal>
      ) : (
        <MobilePopoverCard
          className={clsx("packages-passport-form-popup", "enhanced")}
          open={openModal}
          centered={true}
          topRightButton={
            <ActionLink
              className="popup-close-button"
              content={<CloseButtonIcon />}
              label="Close"
              onClick={() => {
                handleClose();
                setSavedAndContinuePressed(false);
                onCloseMobileModal && onCloseMobileModal();
              }}
            />
          }
        >
          <PassportInfoForm
            className={
              userIndex === selectedPassengers.length - 1
                ? "last-pax"
                : undefined
            }
            title={
              userIndex === 0 ? PASSPORT_MODAL_ENHANCEMENT_TITLE : undefined
            }
            subtitle={
              userIndex === 0 ? PASSPORT_MODAL_ENHANCEMENT_SUBTITLE : undefined
            }
            subtitle2={getPassengerName(
              `${currentPassenger.givenName} ${currentPassenger.surname}`,
              userIndex + 1
            )}
            passportInfo={currentPassenger.passport ?? null}
            onPassportInfoChange={setPassportInfo}
            onNationalityChange={setNationality}
            nationality={currentPassenger.nationality}
            useEnhancedPassportForm
            isMobile
            hasErrors={saveAndContinuePressed && showErrors}
          />

          <MobileFloatingButton
            className="save-passport-button b2b"
            onClick={handleSaveAndContinue}
          >
            Save and Continue
          </MobileFloatingButton>
        </MobilePopoverCard>
      )}

      <GenericInfoPopup
        open={!!passportError}
        image={<Icon className="error-icon" name={IconName.ErrorState} />}
        title={GENERIC_ERROR_TITLE}
        subtitle={GENERIC_ERROR_SUBTITLE}
        buttons={[
          {
            buttonText: UPDATE_TRAVELERS_CTA_TEXT,
            onClick: handleClose,
          },
        ]}
      />
    </>
  );
};
