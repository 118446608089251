import React from "react";
import { FiatPrice, RewardsAccount, RewardsPrice } from "redmond";
import { formatCurrency, formatRewards } from "../../state/utils";
import { Typography } from "@material-ui/core";

export const PACKAGE_BANNER_EARN_TEXT = (
  packageDiscount: FiatPrice,
  earn: RewardsPrice,
  selectedCardPaymentRewardsAccount: RewardsAccount
) => (
  <Typography className="package-discount-banner-content">
    <strong>Save a total of {formatCurrency(packageDiscount)}</strong> and{" "}
    <strong>earn {formatRewards(earn)}</strong> with{" "}
    {selectedCardPaymentRewardsAccount.productDisplayName} when you book this
    hotel and flight package through Capital One Travel.
  </Typography>
);
