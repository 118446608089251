import { connect, ConnectedProps } from "react-redux";

import { RecommendedFlightsCard } from "./component";
import { withRouter } from "react-router";
import { actions } from "../../../search/actions";
import { IStoreState } from "../../../../reducers/types";
import { getRecommendedFlights } from "../../reducer";

const mapDispatchToProps = {
  setDepartureDate: actions.setDepartureDate,
  setReturnDate: actions.setReturnDate,
};
const mapStateToProps = (state: IStoreState) => {
  return {
    recommendedFlights: getRecommendedFlights(state),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type RecommendedFlightsCardConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedRecommendedFlightsCard = withRouter(
  connector(RecommendedFlightsCard)
);
