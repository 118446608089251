import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { PackagesSeatSelection } from "./component";
import { IStoreState } from "../../../../reducers/types";
import { getSelectedAccountReferenceId } from "../../../rewards/reducer";

export const mapStateToProps = (state: IStoreState) => {
  return {
    selectedRewardsAccountId: getSelectedAccountReferenceId(state),
  };
};

export const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PackagesSeatSelectionConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedPackagesSeatSelection = withRouter(
  connector(PackagesSeatSelection)
);
