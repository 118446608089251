import {
  ActionLink,
  B2BButton,
  ButtonWrap,
  CloseButtonIcon,
  FareClassInfoModal,
  Icon,
  IconName,
  MobilePopoverCard,
} from "halifax";
import React from "react";
import "./styles.scss";
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import {
  FareclassOptionFilter,
  initialFareclassOptionFilter,
} from "../../../../reducer";
import { isEqual } from "lodash-es";
import {
  FARECLASS_BUTTON_LABEL,
  FARECLASS_HEADER_TEXT,
  POPOVER_CTA_LABEL,
  RESET_CTA_TEXT,
} from "./textConstants";
import { FARE_CLASSES } from "../../../PackagesSearchControl/components/FareclassOptionSelection/textConstants";

interface MobileFareClassSelectionProps {
  fareclassOptionFilter: FareclassOptionFilter;
  setFareclassOptionFilter: (
    fareclassOptionFilter: FareclassOptionFilter
  ) => void;
}

export const MobileFareClassSelection = ({
  fareclassOptionFilter,
  setFareclassOptionFilter,
}: MobileFareClassSelectionProps) => {
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  const [localFareclassFilter, setLocalFareclassFilter] =
    React.useState<FareclassOptionFilter>(fareclassOptionFilter);
  const [fareclassInfoModalOpen, setFareclassInfoModalOpen] =
    React.useState<boolean>(false);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const handleChange = (
    _: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    setLocalFareclassFilter({
      ...Object.keys(fareclassOptionFilter).reduce(
        (prev, curr) => ({ ...prev, [curr]: false }),
        {} as FareclassOptionFilter
      ),
      [value]: true,
    });
  };

  const handleApply = () => {
    setFareclassOptionFilter(localFareclassFilter);
    closeModal();
  };

  const handleReset = () => {
    setLocalFareclassFilter(initialFareclassOptionFilter);
  };

  const options = Object.keys(fareclassOptionFilter).map((fareclass) => ({
    value: fareclass,
    label: FARE_CLASSES[fareclass],
  }));

  const selectedFareClass = Object.keys(localFareclassFilter).find(
    (key) => !!localFareclassFilter[key]
  );

  const canApply = !isEqual(fareclassOptionFilter, localFareclassFilter);

  React.useEffect(() => {
    setLocalFareclassFilter(fareclassOptionFilter);
  }, [fareclassOptionFilter, modalOpen]);

  return (
    <>
      <B2BButton
        variant="b2b"
        onClick={openModal}
        className="mobile-packages-fareclass-selection-button"
      >
        <div className="icon-label">
          <Icon name={IconName.FareIconFilled} />{" "}
          <Typography className="mobile-fareclass-selection-button-label">
            {FARECLASS_BUTTON_LABEL(selectedFareClass)}
          </Typography>
        </div>
        <Icon name={IconName.Dropdown} />
      </B2BButton>
      <MobilePopoverCard
        className="mobile-packages-fareclass-selection-popover"
        open={modalOpen}
        onClose={closeModal}
        centered={true}
        topLeftButton={
          !isEqual(localFareclassFilter, initialFareclassOptionFilter) ? (
            <ActionLink content={RESET_CTA_TEXT} onClick={handleReset} />
          ) : undefined
        }
        topRightButton={<CloseButtonIcon onClick={closeModal} />}
        headerElement={
          <Typography
            variant="h2"
            className="mobile-fareclass-popover-header-text"
          >
            {FARECLASS_HEADER_TEXT}
          </Typography>
        }
      >
        <div className="mobile-fareclass-selection-popover-content">
          <RadioGroup value={selectedFareClass} onChange={handleChange}>
            {options.map((option) => (
              <FormControlLabel
                control={<Radio size="small" />}
                label={option.label}
                labelPlacement="start"
                className="mobile-fareclass-option-label"
                value={option.value}
                checked={selectedFareClass === option.value}
              />
            ))}
          </RadioGroup>
          <ButtonWrap
            className="fareclass-info-cta-button"
            onClick={() => setFareclassInfoModalOpen(true)}
          >
            <Typography className="fareclass-info-cta-text">
              Learn about our fare classes
            </Typography>{" "}
            <Icon name={IconName.InfoCircle} />
          </ButtonWrap>
        </div>
        <FareClassInfoModal
          open={fareclassInfoModalOpen}
          setOpen={setFareclassInfoModalOpen}
          isMobile
        />
        {canApply && (
          <div className="apply-fareclass-cta-container">
            <B2BButton
              variant="b2b"
              onClick={handleApply}
              className="mobile-fareclass-selection-cta"
            >
              {POPOVER_CTA_LABEL}
            </B2BButton>
          </div>
        )}
      </MobilePopoverCard>
    </>
  );
};
