import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router-dom";
import { IStoreState } from "../../../../../../reducers/types";

import { setPackagesFlightShopProgress } from "../../../../../flight-shop/actions/actions";
import {
  getSelectedTrip,
  tripDetailsSelector,
  isOutgoingMultiTicketSelector,
  isReturnMultiTicketSelector,
} from "../../../../../flight-shop/reducer";

import { finalizePackage } from "../../../../actions/actions";
import { ReviewFlightDetails } from "./component";

const mapStateToProps = (state: IStoreState) => {
  const selectedTrip = getSelectedTrip(state);

  const tripId = selectedTrip?.tripId || "";
  const tripDetails = tripDetailsSelector(state, tripId);

  return {
    tripDetails,

    isMultiTicket:
      isOutgoingMultiTicketSelector(state) ||
      isReturnMultiTicketSelector(state),
  };
};

export const mapDispatchToProps = {
  finalizePackage,
  setPackagesFlightShopProgress,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type ReviewFlightDetailsConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedReviewFlightDetails = withRouter(
  connector(ReviewFlightDetails)
);
