import { createSelector } from "@reduxjs/toolkit";
import { IStoreState } from "../../../../reducers/types";
import { CallState } from "../../../rewards/reducer";
import {
  AirlineCode,
  FareclassOptionFilter,
  FlightFare,
  Flights,
  FlightSortOption,
  IFlightNumberFilter,
  ITimeRange,
  SliceStopCountFilter,
  OutboundFares,
  SafePackageDetails,
  FlightShopStep,
  Outbound,
  TripDetails,
  ISelectedTrip,
  FareDetails,
  CorpFareDetails,
  containsTravelFusionFareSegmentBrand,
} from "redmond";
import * as sorters from "../utils/processSort";
import * as filters from "../utils/processFilters";
import { isEqual, min, max, uniq } from "lodash-es";
import { initialFilterOptions } from "../index";
import {
  getFareclassOptionFilter,
  getHasSetFareClassFilter,
  getHasSetStopsOption,
  getStopsOption,
} from "../../../search/reducer";
import dayjs from "dayjs";

export const getFlightShopCallState = (state: IStoreState) =>
  state.packagesFlightShop.packagesFlightShopCallState;

export const getFlightShopCallResults = (state: IStoreState) =>
  state.packagesFlightShop.packagesFlightShopResults;

export const getAirShopSummary = createSelector(
  getFlightShopCallResults,
  (flightShopCallResults) => {
    return flightShopCallResults?.airShopSummary.value;
  }
);

export const flightsSelector = createSelector(
  getAirShopSummary,
  (airShopSummary) => {
    return airShopSummary ? airShopSummary.flights : {};
  }
);

export const getAirportMap = createSelector(flightsSelector, (flights) => {
  return flights ? flights.airports : {};
});

export const getRecommendedFlights = createSelector(
  getFlightShopCallResults,
  (flightShopCallResults) => {
    return flightShopCallResults?.benchmarkSliceIds;
  }
);
export const getSelectedTrip = (state: IStoreState) =>
  state.packagesFlightShop.selectedTrip;

export const getFlightShopProgress = (state: IStoreState) =>
  state.packagesFlightShop.packagesFlightShopProgress;

export const getOutboundFlightsToRender = createSelector(
  getAirShopSummary,
  (airShopSummary) => {
    return airShopSummary ? airShopSummary.flights.outbound : [];
  }
);

export const getReturnFlightsToRender = createSelector(
  flightsSelector,
  getSelectedTrip,
  (flights, selectedTrip) => {
    if (!selectedTrip || !selectedTrip.outgoingSliceId || !flights) return [];

    const getReturnFlights = (
      _selectedOutgoingFareSliceId: string,
      returnTripIds: string[]
    ) => {
      // @ts-ignore

      return returnTripIds.reduce(
        (returnFlightList: IFlightListData[], tripId: string) => {
          const trip = flights.trips[tripId];
          const returnSliceId = flights.trips[tripId].return || "";

          const tripFares = trip.fares.map(
            (fareId: string) => flights.fares[fareId]
          );

          // Logic for filtering return flights
          const getFilteredFares = () => {
            // Logic for filtering for JetBlue (B6 flight):
            return tripFares.filter((tripFare: FlightFare) => {
              if (!tripFare.return) return true;
              const returnFareSlice = flights.fareSlices[tripFare.return];
              const outgoingFareSlice = flights.fareSlices[tripFare.outbound];

              if (
                selectedTrip.outgoingFareRating !== null &&
                selectedTrip.outgoingFareRating !== undefined
              ) {
                // even if the fare doesn't have the selected outgoing fare slice, we will show the same or higher fare classes (upsell)
                // as long as the matching outbound fare is higher or equal to the selected outboound
                return (
                  returnFareSlice.fareShelf.value >=
                    selectedTrip.outgoingFareRating &&
                  outgoingFareSlice.fareShelf.value >=
                    selectedTrip.outgoingFareRating
                );
              } else {
                return false;
              }
            });
          };

          let filteredTripFares = getFilteredFares();

          if (filteredTripFares.length > 0) {
            returnFlightList.push({
              slice: returnSliceId,
              fares: filteredTripFares,
            });
          }

          return returnFlightList;
        },
        []
      );
    };

    const flight = flights.outbound.find(
      (flight: Outbound) => flight.slice == selectedTrip.outgoingSliceId
    );

    //Fall back to the first fare next list if fare is not found.
    //This will happen if there was a refresh in flights object.
    //The fallback can be used as all fares next list are identical on the same outbound flight.
    const fare =
      !!flight &&
      (flight.fares.find(
        (fare: OutboundFares) =>
          fare.example.fare === selectedTrip.outgoingFareId
      ) ??
        flight.fares[0]);

    const selectedOutgoingFare = selectedTrip.outgoingFareId
      ? flights.fares[selectedTrip.outgoingFareId]
      : null;

    const selectedOutgoingFareSliceId = selectedOutgoingFare
      ? flights.fareSlices[selectedOutgoingFare.outbound].id
      : null;

    return !!fare && fare.next
      ? getReturnFlights(selectedOutgoingFareSliceId, fare.next)
      : [];
  }
);

export const getPackagesByOutboundFareSlice = createSelector(
  getFlightShopCallResults,
  (flightShopCallResults) => {
    return flightShopCallResults?.packagesByOutboundFareSlice;
  }
);

export const getParsedPackagesByOutboundFareSlice = createSelector(
  getPackagesByOutboundFareSlice,
  (packagesByOutboundFareSlice) => {
    const parsedPackages: { [key: string]: SafePackageDetails } = {};
    if (packagesByOutboundFareSlice) {
      Object.keys(packagesByOutboundFareSlice).forEach((fareSliceId) => {
        parsedPackages[fareSliceId] =
          packagesByOutboundFareSlice[fareSliceId].cheapestPackage;
      });
    }
    return parsedPackages;
  }
);

export const getPackagesByReturnFareSlice = createSelector(
  getPackagesByOutboundFareSlice,
  getSelectedTrip,
  (packagesByOutboundFareSlice, selectedTrip) => {
    if (
      selectedTrip &&
      selectedTrip.outgoingFareSliceId &&
      packagesByOutboundFareSlice &&
      packagesByOutboundFareSlice[selectedTrip.outgoingFareSliceId]
    ) {
      return packagesByOutboundFareSlice[selectedTrip.outgoingFareSliceId]
        .packagesByReturnFareSliceId;
    } else {
      return {} as { [key: string]: SafePackageDetails };
    }
  }
);

export const getIsFlightShopLoading = createSelector(
  getFlightShopCallState,
  (flightShopCallState) => {
    return flightShopCallState === CallState.InProcess;
  }
);

export const getIsTripDetailsLoading = (state: IStoreState) =>
  state.packagesFlightShop.tripDetailsLoading;

export const getFareTripDetailsById = (state: IStoreState) =>
  state.packagesFlightShop.tripDetailsById;

export const getIsOutgoing = createSelector(
  getFlightShopProgress,
  (flightShopProgress) => {
    return flightShopProgress === FlightShopStep.ChooseDeparture;
  }
);

export const sortOptionSelector = (state: IStoreState): FlightSortOption =>
  state.packagesFlightShop.sortOption;

export const getMaxPriceFilter = (state: IStoreState) =>
  state.packagesFlightShop.maxPriceFilter;

export const hasSetMaxPriceFilterSelector = createSelector(
  getMaxPriceFilter,
  (maxPriceFilter) => {
    return maxPriceFilter !== initialFilterOptions.maxPriceFilter;
  }
);

export const getAirlineFilter = (state: IStoreState) =>
  state.packagesFlightShop.airlineFilter;

export const getHasSetAirlineFilter = (state: IStoreState) =>
  state.packagesFlightShop.airlineFilter.length > 0;

export const getOutboundDepartureTimeRange = (state: IStoreState) =>
  state.packagesFlightShop.outboundDepartureTimeRange;

export const getOutboundArrivalTimeRange = (state: IStoreState) =>
  state.packagesFlightShop.outboundArrivalTimeRange;

export const getReturnDepartureTimeRange = (state: IStoreState) =>
  state.packagesFlightShop.returnDepartureTimeRange;

export const getReturnArrivalTimeRange = (state: IStoreState) =>
  state.packagesFlightShop.returnArrivalTimeRange;

export const getHasSetOutboundTimeRange = createSelector(
  [getOutboundDepartureTimeRange, getOutboundArrivalTimeRange],
  (outboundDepartureTimeRange, outboundArrivalTimeRange) => {
    return (
      !isEqual(
        outboundDepartureTimeRange,
        initialFilterOptions.outboundDepartureTimeRange
      ) ||
      !isEqual(
        outboundArrivalTimeRange,
        initialFilterOptions.outboundArrivalTimeRange
      )
    );
  }
);

export const getHasSetReturnTimeRange = createSelector(
  [getReturnDepartureTimeRange, getReturnArrivalTimeRange],
  (returnDepartureTimeRange, returnArrivalTimeRange) => {
    return (
      !isEqual(
        returnDepartureTimeRange,
        initialFilterOptions.returnDepartureTimeRange
      ) ||
      !isEqual(
        returnArrivalTimeRange,
        initialFilterOptions.returnArrivalTimeRange
      )
    );
  }
);

export const getDurationFilter = (state: IStoreState) =>
  state.packagesFlightShop.durationFilter;

export const getHasSetDurationFilter = (state: IStoreState) =>
  state.packagesFlightShop.durationFilter !==
  initialFilterOptions.durationFilter;

export const getMinMaxFlightDurations = (state: IStoreState) => {
  const flights: Flights = flightsSelector(state);
  const durations = Object.values(flights?.slices || {}).map(
    (slice) => slice.totalDurationMinutes
  );
  const minDuration = min(durations);
  const maxDuration = max(durations);

  return { minDuration, maxDuration };
};

export interface IAirportOptions {
  label: string;
  value: string;
}
export const getAirportFilter = (state: IStoreState) =>
  state.packagesFlightShop.airportFilter;

export const getFlightNumberFilter = (state: IStoreState) =>
  state.packagesFlightShop.flightNumberFilter;

export const getHasSetAirportFilter = (state: IStoreState) =>
  state.packagesFlightShop.airportFilter.length > 0;

export const getHasSetFlightNumberFilter = (state: IStoreState) =>
  state.packagesFlightShop.flightNumberFilter.length > 0;

export const getFlightList = createSelector(
  getOutboundFlightsToRender,
  getReturnFlightsToRender,
  getFlightShopProgress,

  (
    outgoingFlightList,
    returnFlightList,
    flightShopProgress
  ): IFlightListData[] => {
    switch (flightShopProgress) {
      case FlightShopStep.ChooseReturn:
        return returnFlightList;

      default:
        return outgoingFlightList;
    }
  }
);

export interface IFilterState {
  stopsOption: SliceStopCountFilter;
  maxPriceFilter: number;
  fareclassOptionFilter: FareclassOptionFilter;
  outboundDepartureTimeRange: ITimeRange;
  outboundArrivalTimeRange: ITimeRange;
  returnDepartureTimeRange: ITimeRange;
  returnArrivalTimeRange: ITimeRange;
  airlineFilter: AirlineCode[];
  airportFilter: string[];
  flightNumberFilter: IFlightNumberFilter[];
  // baggageTransfersFilter: boolean;
  durationFilter: number;
  // policyFilter: boolean;
}

const allFiltersSelector = createSelector(
  getFareclassOptionFilter,
  getStopsOption,
  getMaxPriceFilter,
  getOutboundDepartureTimeRange,
  getOutboundArrivalTimeRange,
  getReturnDepartureTimeRange,
  getReturnArrivalTimeRange,
  getAirlineFilter,
  getAirportFilter,
  getFlightNumberFilter,
  getDurationFilter,

  (
    fareclassOptionFilter,
    stopsOption,
    maxPriceFilter,
    outboundDepartureTimeRange,
    outboundArrivalTimeRange,
    returnDepartureTimeRange,
    returnArrivalTimeRange,
    airlineFilter,
    airportFilter,
    flightNumberFilter,
    durationFilter
  ): IFilterState => ({
    fareclassOptionFilter,
    stopsOption,
    maxPriceFilter,
    outboundDepartureTimeRange,
    outboundArrivalTimeRange,
    returnDepartureTimeRange,
    returnArrivalTimeRange,
    airlineFilter,
    airportFilter,
    flightNumberFilter,
    durationFilter,
  })
);

export interface IFlightListData {
  slice: string;
  fares: any; //Todo: add type
}

export const filteredFlightsSelector = createSelector(
  allFiltersSelector,

  getHasSetStopsOption,
  hasSetMaxPriceFilterSelector,
  getHasSetOutboundTimeRange,
  getHasSetReturnTimeRange,
  getHasSetAirlineFilter,
  getHasSetDurationFilter,
  getHasSetAirportFilter,
  getHasSetFlightNumberFilter,
  getHasSetFareClassFilter,

  getFlightShopProgress,
  getFlightList,
  flightsSelector,
  getParsedPackagesByOutboundFareSlice,
  getPackagesByReturnFareSlice,
  (
      allFiltersSelector,

      hasSetStopsOption,
      hasSetMaxPriceFilter,
      hasSetOutboundTimeRange,
      hasSetReturnTimeRange,
      hasSetAirlineFilter,
      hasSetDurationFilter,
      hasSetAirportFilter,
      hasSetFlightNumberFilter,
      hasSetFareClassFilter,
      shopStep,
      flightList,
      flights,
      packagesByOutboundFareSlice,
      packagesByReturnFareSlice
    ): ((invertStopsFilter: boolean) => IFlightListData[]) =>
    (invertStopsFilter) => {
      const {
        stopsOption,
        maxPriceFilter,
        outboundDepartureTimeRange,
        outboundArrivalTimeRange,
        returnDepartureTimeRange,
        returnArrivalTimeRange,
        durationFilter,
        airlineFilter,
        airportFilter,
        // policyFilter,
        flightNumberFilter,
        fareclassOptionFilter,
      } = allFiltersSelector;

      const selectedFaresOptionsArray = Object.keys(
        fareclassOptionFilter
      ).filter((key) => fareclassOptionFilter[key] && key);

      // [TO-DO]: Update to actually filter. This is just a hacky temp way to get flights
      const meetsFilterPredicates = (flight: IFlightListData) => {
        let meetsConditions = true;

        const isReturn = shopStep === FlightShopStep.ChooseReturn;
        const packagesByFareSlice = isReturn
          ? packagesByReturnFareSlice
          : packagesByOutboundFareSlice;

        const flightSlice = flights!.slices[flight.slice];
        if (!flightSlice) return false;
        const flightFares = flight.fares;
        // const flightFaresDetails = flightFares.map(
        //   (fare: { example: { fare: string }; id: string }) =>
        //     flights!.fares[fare.example?.fare ?? fare.id]
        // );
        if (hasSetStopsOption) {
          meetsConditions =
            meetsConditions &&
            (invertStopsFilter
              ? !filters.performStopOptionFilterV2(flightSlice, stopsOption)
              : filters.performStopOptionFilterV2(flightSlice, stopsOption));
        }

        if (hasSetOutboundTimeRange && !isReturn) {
          meetsConditions =
            meetsConditions &&
            filters.performTimeRangeFilterV2(
              flightSlice,
              outboundDepartureTimeRange,
              outboundArrivalTimeRange
            );
        }

        if (hasSetReturnTimeRange && isReturn) {
          meetsConditions =
            meetsConditions &&
            filters.performTimeRangeFilterV2(
              flightSlice,
              returnDepartureTimeRange,
              returnArrivalTimeRange
            );
        }

        if (hasSetAirlineFilter) {
          meetsConditions =
            meetsConditions &&
            filters.performAirlineFilterV2(flightSlice, airlineFilter);
        }

        if (hasSetAirportFilter) {
          meetsConditions =
            meetsConditions &&
            filters.performAirportFilterV2(flightSlice, airportFilter);
        }

        if (hasSetFlightNumberFilter) {
          meetsConditions =
            meetsConditions &&
            filters.performFlightNumberFilterV2(
              flightSlice,
              flightNumberFilter
            );
        }

        if (hasSetMaxPriceFilter) {
          meetsConditions =
            meetsConditions &&
            filters.performMaxPriceFilterV2(
              flightFares,
              maxPriceFilter,
              packagesByFareSlice
            );
        }

        if (hasSetFareClassFilter) {
          meetsConditions =
            meetsConditions &&
            filters.performFareClassFilterV2(
              flights!,
              flight,
              selectedFaresOptionsArray
            );
        }

        // if (!hasBaggageTransfersFilter) {
        //   meetsConditions =
        //     meetsConditions &&
        //     filters.performBaggageTransfersFilter(
        //       flight,
        //       flights!.slices as Record<string, Slice>
        //     );
        // }

        if (hasSetDurationFilter) {
          meetsConditions =
            meetsConditions &&
            filters.performDurationFilter(flightSlice, durationFilter);
        }

        return meetsConditions;
      };

      const filteredFlights: IFlightListData[] = [];
      if (flights) {
        flightList.forEach((flight) => {
          if (meetsFilterPredicates(flight)) {
            filteredFlights.push(flight);
          }
        });
      }

      return filteredFlights;
    }
);
export const getSortedAndFilteredFlights = createSelector(
  filteredFlightsSelector,
  flightsSelector,
  sortOptionSelector,
  getParsedPackagesByOutboundFareSlice,
  getPackagesByReturnFareSlice,
  getFlightShopProgress,
  (
    flightList,
    flights,
    sortOption,
    packagesByOutboundFareSlice,
    packagesByReturnFareSlice,
    shopStep
  ): IFlightListData[] => {
    const filteredFlightList = flightList(false);
    switch (sortOption) {
      case "fareScore":
        return [
          ...sorters.orderByRecommendedV2(
            filteredFlightList,
            flights! as Flights
          ),
        ];
      case "price":
        return [
          ...sorters.orderByPriceV2(
            filteredFlightList,
            shopStep === FlightShopStep.ChooseDeparture
              ? packagesByOutboundFareSlice
              : packagesByReturnFareSlice
          ),
        ];
      case "departureTime":
        return sorters.orderByDepartureTimeV2(filteredFlightList, flights!);
      case "arrivalTime":
        return [...sorters.orderByArrivalTimeV2(filteredFlightList, flights!)];
      case "stops":
        return [...sorters.orderByStopsV2(filteredFlightList, flights!)];
      case "duration":
        return [...sorters.orderByDurationV2(filteredFlightList, flights!)];
      default:
        return [
          ...sorters.orderByRecommendedV2(
            filteredFlightList,
            flights! as Flights
          ),
        ];
    }
  }
);

export interface IAirlineOptions {
  label: AirlineCode;
  value: AirlineCode;
  icon?: JSX.Element;
}
export interface IFlightNumbersByAirlineCode {
  [key: string]: string[];
}

export interface IFlightShopFilterSelector {
  airlineOptions: IAirlineOptions[];
  airportOptions: IAirportOptions[];
  flightNumbersByAirline: IFlightNumbersByAirlineCode;
  priceMin: { value: number };
  priceMax: { value: number };
}

export const allFlightShopFilterSelector = createSelector(
  flightsSelector,
  getFlightList,
  getParsedPackagesByOutboundFareSlice,
  getPackagesByReturnFareSlice,
  (
    flights,
    flightList,
    packagesByOutboundFareSlice,
    packagesByReturnFareSlice
  ): IFlightShopFilterSelector => {
    const airlines = new Set<AirlineCode>();
    const allAirlines: IAirlineOptions[] = [];
    const airports = new Set<string>();
    const allAirports: IAirportOptions[] = [];
    const flightNumbersByAirline: IFlightNumbersByAirlineCode = {};

    let min = { value: Number.MAX_SAFE_INTEGER };
    let max = { value: 0 };

    const flightShopFilters = {
      airlineOptions: allAirlines,
      airportOptions: allAirports,
      flightNumbersByAirline,
      priceMin: min,
      priceMax: max,
    };

    if (!flights) return flightShopFilters;

    flightList.forEach((flight: IFlightListData) => {
      const flightSlice = flights.slices[flight.slice];
      if (!flightSlice) return;
      // AIRLINES
      const airlineCode = flightSlice!.marketingAirline;
      if (!airlines.has(airlineCode)) {
        allAirlines.push({
          value: airlineCode,
          label: flights?.airlines[airlineCode]?.displayName || airlineCode,
        });
      }
      airlines.add(airlineCode);

      // AIRPORTS
      const airportCode = flightSlice.origin;
      const airportName = flights.airports[flightSlice.origin].name;
      if (!airports.has(airportCode)) {
        allAirports.push({ value: airportCode, label: airportName });
      }
      airports.add(airportCode);

      // PRICES
      flight.fares?.forEach((fare: any) => {
        const getFareSliceId = (fare: any) => {
          return fare?.fareSlice || fare.return;
        };

        const fareSliceId = getFareSliceId(fare);
        // const fareSlice = flights.fareSlices[fareSliceId];
        const packageByFareSlice = {
          ...packagesByOutboundFareSlice,
          ...packagesByReturnFareSlice,
        }[fareSliceId];

        min.value = Math.min(
          packageByFareSlice?.pricing.pricePerTraveler.fiat?.value ||
            Number.MAX_SAFE_INTEGER,
          min.value
        );
        max.value = Math.max(
          packageByFareSlice?.pricing.pricePerTraveler.fiat?.value || 0,
          max.value
        );
      });

      // FLIGHT NUMBER
      const [{ flightNumber }] = flightSlice.segments;

      if (!flightNumbersByAirline[airlineCode]) {
        flightNumbersByAirline[airlineCode] = [flightNumber];
      } else {
        flightNumbersByAirline[airlineCode] = uniq([
          ...flightNumbersByAirline[airlineCode],
          flightNumber,
        ]);
      }
    });

    return flightShopFilters;
  },
  {
    memoizeOptions: {
      maxSize: 0,
    },
  }
);

export const getActiveFiltersCount = createSelector(
  [
    getStopsOption,
    getFareclassOptionFilter,
    getHasSetOutboundTimeRange,
    getHasSetReturnTimeRange,
    getDurationFilter,
    getHasSetAirlineFilter,
    hasSetMaxPriceFilterSelector,
    getHasSetAirportFilter,
    getHasSetFlightNumberFilter,
  ],
  (
    stopsOption,
    fareclassOptionFilter,
    hasSetOutboundTimeRange,
    hasSetReturnTimeRange,
    durationFilter,
    hasSetAirlineFilter,
    hasSetMaxPriceFilter,
    hasSetAirportFilter,
    hasSetFlightNumberFilter
  ) => {
    let filtersCount = 0;

    if (stopsOption !== initialFilterOptions.stopsOption) {
      filtersCount += 1;
    }

    if (
      !isEqual(
        fareclassOptionFilter,
        initialFilterOptions.fareclassOptionFilter
      )
    ) {
      filtersCount += 1;
    }

    if (hasSetOutboundTimeRange) {
      filtersCount += 1;
    }

    if (hasSetReturnTimeRange) {
      filtersCount += 1;
    }

    if (durationFilter !== initialFilterOptions.durationFilter) {
      filtersCount += 1;
    }

    if (hasSetAirlineFilter) {
      filtersCount += 1;
    }

    if (hasSetMaxPriceFilter) {
      filtersCount += 1;
    }

    if (hasSetAirportFilter) {
      filtersCount += 1;
    }

    if (hasSetFlightNumberFilter) {
      filtersCount += 1;
    }

    return filtersCount;
  }
);

export const getRecommendedOutgoingFlight = createSelector(
  getRecommendedFlights,
  getOutboundFlightsToRender,
  (recommendedFlightsList, outboundFlights) => {
    if (
      !recommendedFlightsList ||
      recommendedFlightsList.length <= 0 ||
      !outboundFlights
    ) {
      return null;
    }
    return outboundFlights.find((flight: Outbound) => {
      return recommendedFlightsList.find((recFlight) => {
        return recFlight.value === flight.slice;
      });
    });
  }
);

export const getRecommendedReturnFlight = createSelector(
  getRecommendedFlights,
  getReturnFlightsToRender,
  (recommendedFlightsList, returnFlights) => {
    if (
      !recommendedFlightsList ||
      recommendedFlightsList.length <= 0 ||
      !returnFlights
    ) {
      return null;
    }
    return returnFlights.find((flight: IFlightListData) => {
      return recommendedFlightsList.find((recFlight) => {
        return recFlight.value === flight.slice;
      });
    });
  }
);

export const getSelectedReturnFlightPackage = createSelector(
  getSelectedTrip,
  getPackagesByReturnFareSlice,
  (selectedTrip, packagesByReturnFareSlice) => {
    if (selectedTrip?.returnFareSliceId) {
      return packagesByReturnFareSlice[selectedTrip?.returnFareSliceId];
    } else {
      return undefined;
    }
  }
);

export const tripDetailsSelector = (
  state: IStoreState,
  tripId: string
): TripDetails => {
  return state.packagesFlightShop.tripDetailsById[tripId];
};

export const selectedTripDetailsSelector = createSelector(
  getFareTripDetailsById,
  getSelectedTrip,
  (tripDetails, selectedTrip): TripDetails | null => {
    if (selectedTrip?.tripId) {
      return tripDetails[selectedTrip.tripId];
    } else {
      return null;
    }
  }
);

export const selectedFareDetailsSelector = createSelector(
  selectedTripDetailsSelector,
  getSelectedTrip,
  (
    selectedTripDetails,
    selectedTrip
  ): FareDetails | CorpFareDetails | undefined => {
    let fareId = "";

    const selectedOWRTrip = selectedTrip as ISelectedTrip;
    fareId = selectedOWRTrip?.returnFareId
      ? (selectedOWRTrip.returnFareId as string)
      : (selectedOWRTrip?.outgoingFareId as string);

    return selectedTripDetails?.fareDetails.find((fare) => fare.id == fareId);
  }
);

// This should always return false until we offer Virtual Interlining (i.e. multi-ticket one-way fare).
export const isOutgoingMultiTicketSelector = createSelector(
  getFareTripDetailsById,
  getSelectedTrip,
  (tripDetailsMap, selectedTrip): boolean => {
    const trip = tripDetailsMap[selectedTrip?.tripId || ""];
    const selectedOWRTrip = selectedTrip as ISelectedTrip;
    if (trip && selectedOWRTrip.outgoingFareId) {
      return !!trip.fareDetails.find(
        (f) => f.id === selectedOWRTrip.outgoingFareId
      )?.multiTicket;
    }
    return false;
  }
);

export const isReturnMultiTicketSelector = createSelector(
  getFareTripDetailsById,
  getSelectedTrip,
  (tripDetailsMap, selectedTrip): boolean => {
    const trip = tripDetailsMap[selectedTrip?.tripId || ""];
    const selectedOWRTrip = selectedTrip as ISelectedTrip;
    if (trip && selectedOWRTrip.returnFareId) {
      return !!trip.fareDetails.find(
        (f) => f.id === selectedOWRTrip.returnFareId
      )?.multiTicket;
    }
    return false;
  }
);

export const hasTravelFusionInSelectedTripDetailsSelector = createSelector(
  selectedTripDetailsSelector,
  (selectedTripDetails) => {
    if (!selectedTripDetails) {
      return false;
    }

    return containsTravelFusionFareSegmentBrand(
      selectedTripDetails.fareDetails
    );
  }
);

export const getIsTripVoidWindowEligible = createSelector(
  flightsSelector,
  selectedTripDetailsSelector,
  hasTravelFusionInSelectedTripDetailsSelector,
  (flights, tripDetails, hasTravelFusionSegment) => {
    if (tripDetails) {
      const airportMap = flights?.airports;
      const airportCodes = tripDetails.slices.flatMap((slice) => [
        slice.originCode,
        slice.destinationCode,
      ]);

      const outgoingSlice = tripDetails.slices.find((slice) => slice.outgoing);

      const hasUSAirport = airportCodes.some(
        (code) => airportMap?.[code]?.geography.countryCode === "US"
      );
      const isWeekBeforeDeparture =
        dayjs(outgoingSlice?.departureTime).diff(new Date(), "days") >= 7;

      return hasUSAirport && isWeekBeforeDeparture && !hasTravelFusionSegment;
    }
    return false;
  }
);
