import React from "react";
import { RouteComponentProps } from "react-router";
import { GenericInfoPopup, Icon, IconName, useDeviceTypes } from "halifax";
import { BookingErrorModalConnectorProps } from "./container";
import "./styles.scss";
import {
  CartFulfillEventType,
  cartFulfillSelectors,
  CartQuoteEventType,
  cartQuoteSelectors,
  getParentState,
  ParentState,
  useCheckoutState,
  useCheckoutStateSelector,
} from "@capone/checkout";
import { Event, TEvent } from "../../state/events";
import { PackagesMachineContext } from "../../state/types";
import { getErrorModalProps } from "./utils";
import { PurchaseError } from "@b2bportal/purchase-api";
import { PATH_HOME } from "../../../../utils/paths";
import { trackEvent } from "../../../../api/v0/trackEvent";
import {
  MODAL_ALERT,
  ModalAlertProperties,
  ModalCategoryType,
  ModalScreens,
} from "redmond";
import clsx from "clsx";

export interface IBookingErrorModalProps
  extends BookingErrorModalConnectorProps,
    RouteComponentProps {}

export const BookingErrorModal = ({ history }: IBookingErrorModalProps) => {
  const { matchesMobile } = useDeviceTypes();

  const quoteErrorOpen = useCheckoutStateSelector(
    cartQuoteSelectors.getOpenCartQuoteErrorModal
  );
  const quoteError = useCheckoutStateSelector(
    cartQuoteSelectors.getCartQuoteError
  );
  const fulfillErrorOpen = useCheckoutStateSelector(
    cartFulfillSelectors.getOpenCartFulfillErrorModal
  );
  const fulfillError = useCheckoutStateSelector(
    cartFulfillSelectors.getCartFulfillError
  );

  const open = fulfillErrorOpen || quoteErrorOpen;
  const error = fulfillError || quoteError;

  const [state, send] = useCheckoutState<TEvent, PackagesMachineContext>();

  const parentState = getParentState(state.value) as ParentState;

  const clearError = () => {
    send(CartQuoteEventType.CLEAR_CART_QUOTE_ERROR);
    send(CartFulfillEventType.CLEAR_CART_FULFILL_ERROR);
  };

  const updateFlightTravelers = () => {
    send(CartQuoteEventType.OPEN_PASSENGER_PICKER);
    clearError();
  };

  const updateHotelTravelers = () => {
    send(Event.GO_TO_HOTEL_PASSENGER_SELECT);
    clearError();
  };

  const searchAgain = () => {
    history.push(PATH_HOME);
  };

  const errorModalProps = getErrorModalProps(
    error?.type,
    error?.data as PurchaseError[],
    updateFlightTravelers,
    searchAgain,
    updateHotelTravelers
  );

  const modalEventProperties: ModalAlertProperties = {
    type: error?.type || "",
    primary_button: errorModalProps.buttons?.[0]?.buttonText || "",
    secondary_button: errorModalProps.buttons?.[1]?.buttonText || "",
    screen: ModalScreens.PACKAGES_CHECKOUT,
    category: ModalCategoryType.TROUBLE,
    modal_subtitle: errorModalProps.subtitle as string,
    modal_title: errorModalProps.title as string,
    agent_subtitle: errorModalProps.subtitle as string,
    agent_title: errorModalProps.title as string,
    funnel: "packages",
    step: parentState,
  };

  React.useEffect(() => {
    if (open)
      trackEvent({
        eventName: MODAL_ALERT,
        properties: {
          ...modalEventProperties,
        },
      });
  }, [open]);

  return (
    <GenericInfoPopup
      open={open}
      image={<Icon className="error-icon" name={IconName.ErrorState} />}
      className={clsx("packages-book-error-modal", { mobile: matchesMobile })}
      {...errorModalProps}
    />
  );
};
