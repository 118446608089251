import axios from "axios";
import { config } from "../../config";

import { ShopFlightsRequest, ShopFlightsResponse } from "redmond";
import { flightShopApiPrefix } from "../paths";

export const fetchPackagesFlightShop = (
  body: ShopFlightsRequest
): Promise<ShopFlightsResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await axios.post(flightShopApiPrefix, body, {
        baseURL: config.baseURL,
      });

      resolve(res.data.value);
    } catch (e) {
      reject(e);
    }
  });
