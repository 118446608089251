import { connect, ConnectedProps } from "react-redux";
import { BookingInProgressModal } from "./component";
import { IStoreState } from "../../../../reducers/types";

const mapStateToProps = (_state: IStoreState) => ({});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type BookingInProgressModalConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedBookingInProgressModal = connector(
  BookingInProgressModal
);
