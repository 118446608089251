export const BASE_PATH_HOME = "/";
export const PATH_HOME = "/packages/";
export const PATH_HOME_SEARCH = PATH_HOME + "search/";
export const PATH_HOTELS_AVAILABILITY = PATH_HOME + "hotels-availability/";

export const PATH_REVIEW = `${PATH_HOME}review/`;
export const PATH_BOOK = `${PATH_HOME}book/`;
export const PATH_BOOK_CONFIRMATION = `${PATH_BOOK}confirm/`;

export const PATH_HOTEL_SHOP = PATH_HOME + "hotel-shop/";
export const PATH_FLIGHT_SHOP = PATH_HOME + "flight-shop/";
