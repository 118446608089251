import React, { useContext } from "react";
import { Box, Typography } from "@material-ui/core";
import { IDesktopFlightShopProps } from "./container";
import clsx from "clsx";
import "./styles.scss";
import { Header } from "halifax";
import { ClientContext } from "../../../../App";
import { RewardsAccountSelection } from "../../../rewards/components";
import { PATH_HOME } from "../../../../utils/paths";
import { PackagesFlightShopStep } from "redmond";

import { TravelWalletDrawer } from "../../../travel-wallet/components";

import {
  CHOOSING_FLIGHT_TEXT,
  REWARDS_BANNER_TEXT,
} from "../FlightList/components/FlightListInfo/textConstants";
import { FlightList } from "../FlightList";
import { FlightShopHeader } from "../FlightShopHeader";

export const DesktopFlightShop = (props: IDesktopFlightShopProps) => {
  const { history, origin, destination, isOutgoing } = props;

  const clientContext = useContext(ClientContext);
  const { logo } = clientContext;

  return (
    <Box className={clsx("desktop-package-flight-shop-root")}>
      <Box className={clsx("packages-flight-list-container")}>
        <Header
          // TODO: Make this common component to be used throughout Packages Funnel?
          className="rewards-components-section"
          left={
            <Box className={"rewards-account-section-left-content"}>
              <Box className={"logo"} onClick={() => history.push(PATH_HOME)}>
                {logo}
              </Box>
              {origin && destination && (
                <Box className={"rewards-account-section-travel-details"}>
                  <Typography variant={"body1"} tabIndex={0}>
                    {CHOOSING_FLIGHT_TEXT(
                      isOutgoing
                        ? PackagesFlightShopStep.ChooseDeparture
                        : PackagesFlightShopStep.ChooseReturn,
                      origin.label,
                      destination.label
                    )}
                  </Typography>
                  <Typography variant={"body2"}>
                    {REWARDS_BANNER_TEXT}
                  </Typography>
                </Box>
              )}
            </Box>
          }
          right={
            <Box className="desktop-flight-list-rewards-account-contents">
              <RewardsAccountSelection
                className={clsx("b2b hide-balance-border")}
                popoverClassName="b2b"
              />
              <TravelWalletDrawer />
            </Box>
          }
        ></Header>
        <FlightShopHeader isMobile={false} />
        <FlightList />
      </Box>
    </Box>
  );
};
