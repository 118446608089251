import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { RouteComponentProps } from "react-router-dom";
import { IStoreState } from "../../../../reducers/types";
import {
  getAdultsCount,
  getChildren,
  getDepartureDate,
  getFareclassOptionFilter,
  getInfants,
  getOrigin,
  getReturnDate,
  getStopsOption,
} from "../../../search/reducer";

import { DesktopRewardsHeader } from "./component";
import {
  getHotelShopCallState,
  getHotelShopChosenProduct,
  getPackageSelectedLodging,
} from "../../reducer";
import { PackageHotelShopCallState } from "../../reducer/state";

const mapStateToProps = (state: IStoreState) => ({
  fromDate: getDepartureDate(state),
  untilDate: getReturnDate(state),
  lodging: getPackageSelectedLodging(state),
  hotelShopCallInProgress:
    getHotelShopCallState(state) === PackageHotelShopCallState.InProcess,
  chosenProduct: getHotelShopChosenProduct(state),
  adultsCount: getAdultsCount(state),
  children: getChildren(state),
  origin: getOrigin(state),
  infants: getInfants(state),
  stopsOption: getStopsOption(state),
  fareClassOptionFilter: getFareclassOptionFilter(state),
});

export const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);
type DesktopRewardsHeaderConnectorProps = ConnectedProps<typeof connector>;

export interface IDesktopRewardsHeaderProps
  extends DesktopRewardsHeaderConnectorProps,
    RouteComponentProps {
  title?: string;
  subtitle?: string;
}

export const ConnectedDesktopRewardsHeader = withRouter(
  connector(DesktopRewardsHeader)
);
