import { Box, Tooltip, Typography } from "@material-ui/core";
import {
  ButtonWrap,
  FareClassInfoModal,
  Icon,
  IconName,
  useDeviceTypes,
} from "halifax";
import React from "react";

import * as constants from "./constants";
import clsx from "clsx";
import "./styles.scss";

export const FareClassesInfo = () => {
  const [fareclassInfoModalOpen, setFareclassInfoModalOpen] =
    React.useState<boolean>(false);

  const { matchesMobile } = useDeviceTypes();

  return (
    <Box
      className={clsx("fare-class-info-container", {
        mobile: matchesMobile,
      })}
    >
      {Object.keys(constants.FARE_CLASSES).map((fareClass) => {
        return (
          <Box
            className={"fare-class-filter"}
            id={`faretype-${fareClass}`}
            key={fareClass}
          >
            {!matchesMobile ? (
              <ButtonWrap
                className="fare-class-info-modal-button-wrap"
                onClick={() => setFareclassInfoModalOpen(true)}
                aria-label="Fare class info modal button"
              >
                <Icon
                  name={`${constants.FARE_CLASSES[
                    fareClass
                  ].toLowerCase()}-fare-class-seat-outline`}
                />
                <Typography className="fare-class-label">
                  {constants.FARE_CLASSES[fareClass]}
                </Typography>
                <Icon name={IconName.InfoCircle} />
              </ButtonWrap>
            ) : (
              <Tooltip
                tabIndex={0}
                key={fareClass}
                classes={{ tooltip: "fare-type-tooltip" }}
                placement="top"
                title={
                  matchesMobile ? (
                    ""
                  ) : (
                    <>
                      <Typography variant="h6" className="fareclass-tooltip">
                        {constants.FARE_CLASSES[fareClass]} Fares
                      </Typography>
                      <Typography
                        variant="caption"
                        className="fareclass-tooltip"
                      >
                        {constants.FARE_CLASS_DETAILS[fareClass]}
                      </Typography>
                    </>
                  )
                }
                id={fareClass}
                enterDelay={500}
                enterNextDelay={500}
              >
                <Box>{constants.FARE_CLASSES[fareClass]}</Box>
              </Tooltip>
            )}
          </Box>
        );
      })}
      <FareClassInfoModal
        open={fareclassInfoModalOpen}
        setOpen={setFareclassInfoModalOpen}
        isMobile={matchesMobile}
      />
    </Box>
  );
};
