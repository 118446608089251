import { fetchDestinationCategories } from "./fetchDestinationCategoriesSaga";
import { fetchOriginCategories } from "./fetchOriginCategoriesSaga";
import { actionTypes } from "../actions";
import { takeLatest } from "redux-saga/effects";

export function* watchFetchOriginCategories() {
  yield takeLatest(actionTypes.FETCH_ORIGIN_CATEGORIES, fetchOriginCategories);
}

export function* watchFetchDestinationCategories() {
  yield takeLatest(
    actionTypes.FETCH_DESTINATION_CATEGORIES,
    fetchDestinationCategories
  );
}
