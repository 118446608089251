import React from "react";
import { AmenityEnum, HotelStarRatingEnum } from "redmond";
import * as textConstants from "./textConstants";
import { mapAmenitiesToTopAmenities } from "halifax";
import { usePrevious } from "../../../../hooks/usePrevious";

/*
  Hook to generate dynamic filter labels
  ratings/phc: '5 Star +2', 'Premier Collection +1', etc
  amenities: 'Free WiFi', 'Swimming Pool +1', etc
*/
export const useFilterLabelValues = ({
  starRatings,
  amenities,
}: {
  starRatings: HotelStarRatingEnum[];
  amenities: AmenityEnum[];
}) => {
  const [ratingLabelValues, setRatingLabelValues] = React.useState<string[]>(
    []
  );

  const [amenityLabelValues, setAmenityLabelValues] = React.useState<
    AmenityEnum[]
  >([]);

  const previousStarRatings = usePrevious(starRatings);

  const previousAmenities = usePrevious(amenities);

  /* ratings/premier collection */

  // use the first selected filter for label (then use 2nd selected, then 3rd, etc.)
  React.useEffect(() => {
    // filter out duplicates and unselected values
    const filteredRatingLabelValues = ratingLabelValues.reduce(
      (filteredValues, currentValue) => {
        // filter out duplicates
        if (filteredValues.includes(currentValue)) {
          return filteredValues;
        }

        // include star rating if it's selected
        const ratingIsSelected = starRatings
          .map((rating) => textConstants.STAR_RATING_FILTER_LABEL(rating))
          .includes(currentValue);

        if (ratingIsSelected) {
          filteredValues.push(currentValue);
        }

        return filteredValues;
      },
      [] as string[]
    );

    if (starRatings?.length !== previousStarRatings?.length) {
      // if a star rating was selected
      setRatingLabelValues([
        ...filteredRatingLabelValues,
        textConstants.STAR_RATING_FILTER_LABEL(
          starRatings[starRatings.length - 1]
        ),
      ]);
    } else if (ratingLabelValues.length !== filteredRatingLabelValues.length) {
      // if there were duplicates or unselected values filtered out
      setRatingLabelValues(filteredRatingLabelValues);
    }
  }, [ratingLabelValues, starRatings, previousStarRatings]);

  const ratingLabel = React.useMemo(() => {
    const additionalAppliedRatingCount = starRatings.length - 1;
    return `${ratingLabelValues[0]}${
      additionalAppliedRatingCount > 0
        ? ` +${additionalAppliedRatingCount}`
        : ""
    }`;
  }, [ratingLabelValues, starRatings]);

  /* amenities */

  const amenitiesMappedToTopAmenities = React.useMemo(
    () => mapAmenitiesToTopAmenities(amenities),
    [amenities]
  );

  React.useEffect(() => {
    if (amenities?.length > previousAmenities?.length) {
      setAmenityLabelValues([
        ...amenityLabelValues,
        amenities[amenities.length - 1],
      ]);
    } else if (amenities?.length < previousAmenities?.length) {
      setAmenityLabelValues(
        amenityLabelValues.filter(
          (amenity) =>
            amenities.includes(amenity) && previousAmenities.includes(amenity)
        )
      );
    }
  }, [amenities, previousAmenities, amenityLabelValues]);

  const amenitiesLabel = React.useMemo(() => {
    return `${amenitiesMappedToTopAmenities[0]?.label}${
      amenitiesMappedToTopAmenities.length > 1
        ? ` +${amenitiesMappedToTopAmenities.length - 1}`
        : ""
    }`;
  }, [amenitiesMappedToTopAmenities]);

  return { ratingLabel, amenitiesLabel };
};
