import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { DesktopPackagesBookWorkflow } from "./component";
import { IStoreState } from "../../../../reducers/types";
import { getFinalizePackageResponse } from "../../../itinerary-review/reducer/selectors";
import {
  getFareTripDetailsById,
  getSelectedTrip,
  selectedFareDetailsSelector,
} from "../../../flight-shop/reducer";
import {
  getHotelShopChosenRoomInfo,
  getHotelShopDateRange,
  getPackageSelectedLodging,
} from "../../../hotel-shop/reducer";
import { getRewardsAccounts } from "../../../rewards/reducer";
import {
  getPackageAvailabilityAdultsCount,
  getPackageAvailabilityChildren,
  getPackageAvailabilityInfants,
} from "../../../availability/reducer";
import { getDestination } from "../../../search/reducer";
import { PackageFareDetails } from "redmond";

export const mapStateToProps = (state: IStoreState) => {
  const selectedTrip = getSelectedTrip(state);
  const hotelDates = getHotelShopDateRange(state);
  const fareDetails = selectedFareDetailsSelector(state) as
    | PackageFareDetails
    | undefined;

  const packagePricing = fareDetails?.packagePricing;

  const searchedAdultsCount = getPackageAvailabilityAdultsCount(state);
  const searchedChildren = getPackageAvailabilityChildren(state);
  const searchedInfants = getPackageAvailabilityInfants(state);
  const searchedDestination = getDestination(state);

  return {
    finalizePackageResponse: getFinalizePackageResponse(state),
    selectedTrip,
    tripDetails: selectedTrip?.tripId
      ? getFareTripDetailsById(state)[selectedTrip.tripId]
      : undefined,
    selectedLodging: getPackageSelectedLodging(state),
    selectedRoom: getHotelShopChosenRoomInfo(state),
    hotelFromDate: hotelDates?.from,
    hotelUntilDate: hotelDates?.until,
    packagePricing,
    rewardsAccounts: getRewardsAccounts(state),
    searchedAdultsCount,
    searchedChildren,
    searchedInfants,
    searchedDestination,
  };
};

export const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type DesktopPackagesBookWorkflowConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedDesktopPackagesBookWorkflow = withRouter(
  connector(DesktopPackagesBookWorkflow)
);
