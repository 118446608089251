import { connect, ConnectedProps } from "react-redux";
import { PassengerErrorModal } from "./component";
import { IStoreState } from "../../../../reducers/types";

const mapStateToProps = (_: IStoreState) => {
  return {};
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PassengerErrorModalConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedPassengerErrorModal = connector(PassengerErrorModal);
