import {
  AirlineCode,
  FlightFare,
  Flights,
  IFlightNumberFilter,
  isSouthwestAirlines,
  ITimeRange,
  OutboundFares,
  SafePackageDetails,
  Slice,
  SliceStopCountFilter,
} from "redmond";
import dayjs from "dayjs";

import { TripSlice, FlightRatingsEnum } from "redmond";
import { initialFilterOptions } from "../../../search/reducer";

import { removeTimezone } from "halifax";
import { MulticityFlights, MulticitySlice } from "@b2bportal/air-shopping-api";

export interface IFlightListData {
  slice: string;
  fares: any; //Todo: add type
}

export const performTimeRangeFilter = (
  flight: TripSlice,
  departureTime: ITimeRange,
  arrivalTime: ITimeRange
) => {
  const flightDeparture = dayjs(removeTimezone(flight.departureTime));
  const flightArrival = dayjs(removeTimezone(flight.arrivalTime));

  const flightDepartureMinutes =
    flightDeparture.hour() * 60 + flightDeparture.minute();
  const flightArrivalMinutes =
    flightArrival.hour() * 60 + flightArrival.minute();

  const validDeparture =
    departureTime.max >= flightDepartureMinutes &&
    flightDepartureMinutes >= departureTime.min;
  const validArrival =
    arrivalTime.max >= flightArrivalMinutes &&
    flightArrivalMinutes >= arrivalTime.min;

  return validDeparture && validArrival;
};

export const performStopOptionFilterV2 = (
  flight: Slice | MulticitySlice,
  stopsOption: SliceStopCountFilter
): boolean => {
  switch (stopsOption) {
    case SliceStopCountFilter.ONE_OR_LESS:
      return flight.stops <= 1;
    case SliceStopCountFilter.TWO_OR_LESS:
      return flight.stops <= 2;
    case SliceStopCountFilter.NONE:
      return flight.stops === 0;
    default:
      return true;
  }
};

export const performMaxPriceFilterV2 = (
  fares: FlightFare[] | OutboundFares[],
  maxPriceFilter: number,
  packagesByFareSlice: {
    [key: string]: SafePackageDetails;
  }
) => {
  if (maxPriceFilter === initialFilterOptions.maxPriceFilter) return true;

  return fares.some((fare) => {
    return (
      (packagesByFareSlice[
        (fare as OutboundFares).fareSlice ?? (fare as FlightFare).return
      ].pricing.pricePerTraveler.fiat.value || 0) <= maxPriceFilter
    );
  });
};

export const performTimeRangeFilterV2 = (
  flight: Slice | MulticitySlice,
  departureTime: ITimeRange,
  arrivalTime: ITimeRange
) => {
  const flightDeparture = dayjs(removeTimezone(flight.departure));
  const flightArrival = dayjs(removeTimezone(flight.arrival));

  const flightDepartureMinutes =
    flightDeparture.hour() * 60 + flightDeparture.minute();
  const flightArrivalMinutes =
    flightArrival.hour() * 60 + flightArrival.minute();

  const validDeparture =
    departureTime.max >= flightDepartureMinutes &&
    flightDepartureMinutes >= departureTime.min;
  const validArrival =
    arrivalTime.max >= flightArrivalMinutes &&
    flightArrivalMinutes >= arrivalTime.min;

  return validDeparture && validArrival;
};

export const performAirlineFilterV2 = (
  flight: Slice | MulticitySlice,
  airlineFilter: AirlineCode[]
) => {
  return airlineFilter.includes(flight.segments[0].marketingAirline);
};

export const doesFlightHaveFilteredAirlines = (
  flight: Slice | MulticitySlice,
  airlineFilter: AirlineCode[]
) => {
  const flightAirlines = flight.segments
    .flatMap((segment) => [segment.marketingAirline, segment.operatingAirline])
    .concat(flight.marketingAirline);
  return flightAirlines.some((airline) => airlineFilter.includes(airline));
};

export const performIsSouthwestAirlines = (flight: Slice) => {
  return isSouthwestAirlines(flight.marketingAirline);
};

export const performAirportFilterV2 = (
  slice: Slice | MulticitySlice,
  airportFilter: string[]
) => {
  return airportFilter.includes(slice.origin);
};

export const performPolicyFilterV2 = (
  fares: any,
  policyFilter: boolean
): boolean => {
  if (policyFilter) {
    return fares.some(
      (fare: any) => fare.corporateTravel.policyCompliance.isInPolicy
    );
  }
  return true;
};

export const performFlightNumberFilterV2 = (
  flight: Slice | MulticitySlice,
  flightNumberFilter: IFlightNumberFilter[]
) => {
  return flightNumberFilter.some((filter) => {
    const [firstSegment] = flight.segments;
    return (
      firstSegment.marketingAirline === filter.airlineCode &&
      firstSegment.flightNumber === filter.flightNumber
    );
  });
};

export const performFareClassFilterV2 = (
  flights: Flights | MulticityFlights,
  flight: IFlightListData,
  fareClassOptionFilter: string[]
) => {
  const getAllFlightRating = () => {
    const getFareSliceId = (fare: any) => fare?.fareSlice || fare.return;
    return flight.fares.reduce((flightRating: any, fare: any) => {
      const rating = flights.fareSlices[getFareSliceId(fare)].fareShelf.value;
      if (rating || rating === 0) flightRating[rating] = true;
      return flightRating;
    }, {});
  };

  // If the flight has *any* of the users selected fare classes, return true.
  return fareClassOptionFilter.some((fare) => {
    return getAllFlightRating()[FlightRatingsEnum[fare.toLocaleLowerCase()]];
  });
};

export const performBaggageTransfersFilter = (
  flight: IFlightListData,
  slices: Record<string, Slice>
) => {
  return !slices[flight.slice].hasSelfTransferLayover;
};

export const performDurationFilter = (
  slice: Slice | MulticitySlice,
  durationFilter: number
) => {
  if (!slice.totalDurationMinutes) return false;
  return slice.totalDurationMinutes < durationFilter;
};
