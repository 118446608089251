import dayjs from "dayjs";
import {
  FareclassOptionFilter,
  ITripTerminus,
  Lodging,
  SliceStopCountFilter,
} from "redmond";
import { transformToStringifiedAvailabilityQuery } from "../../availability/utils/queryStringHelpers";
import queryStringParser from "query-string";
import {
  PATH_BOOK,
  PATH_BOOK_CONFIRMATION,
  PATH_FLIGHT_SHOP,
  PATH_HOME,
  PATH_HOTEL_SHOP,
  PATH_HOTELS_AVAILABILITY,
  PATH_REVIEW,
} from "../../../utils/paths";
import { History } from "history";
import { getSelectedFareClasses } from "../../../utils/fareClass";
export interface IPackageHotelShopParsedQuery {
  lodgingId: string;
  origin: string;
  destination: string;
  fromDate: Date | null;
  untilDate: Date | null;
  adultsCount: number;
  children: number[];
  infants: { age: number; inSeat: boolean }[];
  stopsOption: SliceStopCountFilter;
  fareClassOptionFilter: FareclassOptionFilter;
  searchToken: string;
}

export const transformToStringifiedQuery = ({
  lodgingId,
  origin,
  destination,
  fromDate,
  untilDate,
  adultsCount = 2,
  children,
  infants,
  stopsOption,
  fareClassOptionFilter,
  searchToken,
}: IPackageHotelShopParsedQuery): string => {
  if (fromDate === null || untilDate === null) {
    return `?lodgingId=${lodgingId}`;
  }

  destination = encodeURIComponent(destination);
  const formatFrom = dayjs(fromDate).format("YYYY-MM-DD");
  const formatUntil = dayjs(untilDate).format("YYYY-MM-DD");

  let string = "";
  const childrenParams = children?.reduce(
    (ageString, age) => `${ageString}&children=${age}`,
    ""
  );
  const fareClass = getSelectedFareClasses(fareClassOptionFilter).map(
    (fareClassName) => `&fareClass=${fareClassName}`
  );

  string = `?lodgingId=${lodgingId}&origin=${origin}&destination=${destination}&fromDate=${formatFrom}&untilDate=${formatUntil}&adultsCount=${adultsCount}${childrenParams}${infants?.reduce(
    (infantString, infant) =>
      `${infantString}&infants=${encodeURIComponent(JSON.stringify(infant))}`,
    ""
  )}&stopsOption=${stopsOption}${fareClass.join("")}&searchToken=${searchToken}`;

  return string;
};

export const goToAvailability = ({
  history,
  origin,
  lodging,
  fromDate,
  untilDate,
  adultsCount,
  children,
  infants,
  stopsOption,
  prevSuccessHotelFromDate,
  prevSuccessHotelUntilDate,
  fareClassOptionFilter,
}: {
  history: History;
  origin: ITripTerminus | null;
  lodging: Lodging | null;
  fromDate: Date | string | null;
  untilDate: Date | string | null;
  adultsCount: number;
  children: number[];
  infants: {
    age: number;
    inSeat: boolean;
  }[];
  stopsOption: SliceStopCountFilter;
  prevSuccessHotelFromDate?: Date | null;
  prevSuccessHotelUntilDate?: Date | null;
  fareClassOptionFilter: FareclassOptionFilter;
}) => {
  if (
    !lodging ||
    !origin ||
    (!fromDate && !prevSuccessHotelFromDate) ||
    (!untilDate && !prevSuccessHotelUntilDate)
  ) {
    return history.push(PATH_HOME);
  }

  const parsedQueryStringPrimitive = queryStringParser.parse(
    history.location.search
  );

  const lodgingSelection = parsedQueryStringPrimitive.destination as string;

  const search = transformToStringifiedAvailabilityQuery(
    origin?.id?.code?.code,
    lodgingSelection,
    prevSuccessHotelFromDate ?? fromDate!,
    prevSuccessHotelUntilDate ?? untilDate!,
    adultsCount,
    children,
    infants,
    stopsOption,
    fareClassOptionFilter
  );

  history.push(`${PATH_HOTELS_AVAILABILITY}${search}`);
};

export const goToHotelShop = ({ history }: { history: History }) => {
  history.push(`${PATH_HOTEL_SHOP}${history.location.search}`, {
    fromPage: location.pathname,
  });
};

export const goToFlightShop = ({ history }: { history: History }) => {
  history.push(`${PATH_FLIGHT_SHOP}${history.location.search}`, {
    fromPage: location.pathname,
  });
};

export const goToReview = ({ history }: { history: History }) => {
  history.push(`${PATH_REVIEW}${history.location.search}`, {
    fromPage: location.pathname,
  });
};

export const goToBook = ({ history }: { history: History }) => {
  history.push(`${PATH_BOOK}${history.location.search}`, {
    fromPage: location.pathname,
  });
};

export const goToBookConfirmation = ({ history }: { history: History }) => {
  history.push(`${PATH_BOOK_CONFIRMATION}${history.location.search}`, {
    fromPage: location.pathname,
  });
};
