import React from "react";
import { HotelShopChooseRoom } from "halifax";
import { RouteComponentProps } from "react-router-dom";
import { DesktopHotelShopChooseRoomConnectorProps } from "./container";

import { config } from "../../../../api/config";
import "./styles.scss";
import { getRoomPolicyComplianceType } from "@capone/common/src/utils/hotelShopPolicyCompliance";
import { CalendarPickerButton } from "../../../search/components/PackagesSearchControl/components";
import { CorpRoomInfoProducts, TripCategory } from "redmond";

export interface DesktopHotelShopChooseRoomProps
  extends DesktopHotelShopChooseRoomConnectorProps,
    RouteComponentProps {}

export const DesktopHotelShopChooseRoom = (
  props: DesktopHotelShopChooseRoomProps
) => {
  const {
    departureDate,
    setDepartureDate,
    returnDate,
    setReturnDate,
    packageRatesById,
    travelersCount,
  } = props;
  return (
    <HotelShopChooseRoom
      {...props}
      datepicker={
        <CalendarPickerButton
          classes={["room-calendar-button"]}
          tripCategory={TripCategory.ROUND_TRIP}
          departureDate={departureDate}
          returnDate={returnDate}
          setDepartureDate={setDepartureDate}
          setReturnDate={setReturnDate}
        />
      }
      onClickContinue={() =>
        document
          .getElementsByClassName("reserve-cta")[0]
          ?.scrollIntoView({ behavior: "smooth", block: "end" })
      }
      buttonClassName="b2b"
      policyComplianceType={getRoomPolicyComplianceType(
        props.roomInfoProducts as CorpRoomInfoProducts[]
      )}
      tenant={config.TENANT}
      packagesByRateId={packageRatesById}
      isPackageHotel
      travelersCount={travelersCount}
    />
  );
};
