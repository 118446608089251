import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { PackagesShopProgressBar } from "./component";
import { IStoreState } from "../../../../reducers/types";
import { getPackageSelectedLodging } from "../../../hotel-shop/reducer";
import {
  getDepartureDate,
  getFareclassOptionFilter,
  getOrigin,
  getReturnDate,
  getStopsOption,
  getTravelers,
} from "../../../search/reducer";
import { setPackagesFlightShopProgress } from "../../../flight-shop/actions/actions";
import { getFlightShopProgress } from "../../../flight-shop/reducer";

const mapStateToProps = (state: IStoreState) => {
  return {
    selectedLodging: getPackageSelectedLodging(state),
    departureDate: getDepartureDate(state),
    returnDate: getReturnDate(state),
    travelers: getTravelers(state),
    fareClassOptionFilter: getFareclassOptionFilter(state),
    origin: getOrigin(state),
    stopsOption: getStopsOption(state),
    flightShopProgress: getFlightShopProgress(state),
  };
};

const mapDispatchToProps = {
  setPackagesFlightShopProgress,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PackagesShopProgressBarConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedPackagesShopProgressBar = connector(
  withRouter(PackagesShopProgressBar)
);
