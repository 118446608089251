import { connect, ConnectedProps } from "react-redux";

import {
  fetchInitialPackagesAvailability,
  IFetchPackagesAvailability,
  ISetMapBound,
  ISetViewHotelsNearLocation,
  setAmenitiesFilter,
  setFreeCancelFilter,
  setHotelNameFilter,
  setMapBound,
  setMaxPriceFilter,
  setStarRatingsFilter,
  setViewHotelsNearLocation,
} from "../../actions/actions";
import { AvailabilitySearchControl } from "./component";
import { IStoreState } from "../../../../reducers/types";

import {
  areRequestParametersChanged,
  getMapBound,
  initialFilterState,
} from "../../reducer";
import {
  BoundingBox,
  FareclassOptionFilter,
  IResult,
  ITripTerminus,
  SliceStopCountFilter,
} from "redmond";
import { Dispatch } from "@reduxjs/toolkit";
import H from "history";
import {
  getDepartureDate,
  getReturnDate,
  getDestination,
  getTravelers,
  getOrigin,
  getStopsOption,
  getFareclassOptionFilter,
} from "../../../search/reducer";

interface IStateProps {
  departureDate: Date | null;
  returnDate: Date | null;
  searchLocation: IResult | null;
  travelers: {
    adultsCount: number;
    children: number[];
    infants: { age: number; inSeat: boolean }[];
  };
  origin: ITripTerminus | null;
  stopsOptions: SliceStopCountFilter;
  mapBounds: BoundingBox | null;
  areRequestParametersChanged: boolean;
  fareclassOptionFilter: FareclassOptionFilter;
}
const mapStateToProps = (state: IStoreState) => {
  return {
    departureDate: getDepartureDate(state),
    returnDate: getReturnDate(state),
    searchLocation: getDestination(state),
    travelers: getTravelers(state),
    origin: getOrigin(state),
    stopsOptions: getStopsOption(state),
    mapBounds: getMapBound(state),
    areRequestParametersChanged: areRequestParametersChanged(state),
    fareclassOptionFilter: getFareclassOptionFilter(state),
  };
};

interface IDispatchProps {
  fetchInitialPackagesAvailability: (
    history: H.History
  ) => IFetchPackagesAvailability;
  setMapBound: (mapBound: BoundingBox | null) => ISetMapBound;
  setViewHotelsNearLocation: (
    location: IResult | null
  ) => ISetViewHotelsNearLocation;
  resetFilters: () => void;
}
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchInitialPackagesAvailability: (history: any) =>
      dispatch(fetchInitialPackagesAvailability(history)),
    setMapBound: (mapBound: BoundingBox | null) =>
      dispatch(setMapBound(mapBound)),
    setViewHotelsNearLocation: (location: IResult | null) =>
      dispatch(setViewHotelsNearLocation(location)),
    resetFilters: () => {
      dispatch(setAmenitiesFilter(initialFilterState.amenities));
      dispatch(setFreeCancelFilter(initialFilterState.freeCancel));
      dispatch(setStarRatingsFilter(initialFilterState.starRatings));
      dispatch(setHotelNameFilter(initialFilterState.hotelName));
      dispatch(setMaxPriceFilter(initialFilterState.maxPrice));
    },
  };
};

const mergeProps = (stateProps: IStateProps, dispatchProps: IDispatchProps) => {
  return {
    ...stateProps,
    ...dispatchProps,
  };
};
const connector = connect(mapStateToProps, mapDispatchToProps, mergeProps);

export type AvailabilitySearchControlConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedAvailabilitySearchControl = connector(
  AvailabilitySearchControl
);
