import { PackagesMachineContext } from "../types";

export const PackagesCommonGuards = {
  isMobile: (ctx: PackagesMachineContext) => {
    return ctx.platform === "mobile";
  },
  isDesktop: (ctx: PackagesMachineContext) => {
    return ctx.platform === "desktop";
  },
};
