export const ADD_PAYMENT_METHOD_BUTTON = "Add Payment Method";
export const PAYMENT_METHODS = "Payment Methods";
export const PAYMENTS_SECURE = "Payments are 100% secure.";
export const UNABLED_TO_ADD_PAYMENT =
  "We are unable to add your payment method";
export const ADD_PAYMENT_AGAIN = "Please add your payment method again";
export const TRY_AGAIN = "Try Again";

export const BOOKING_PACKAGE = "Booking your package";
export const CHECKING_AVAILABILITY = "Checking price and availability";
export const SAVING_TRAVELERS_AND_CHECKING_AVAILABILITY =
  "Saving travelers and checking availability";

export const FREEZING_THE_PRICE = "Freezing the price";
