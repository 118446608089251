import React from "react";

export const AIRLINE = (appliedLabel?: string) =>
  !!appliedLabel ? (
    <>
      <span className="filter-name">Airline:</span> {appliedLabel}
    </>
  ) : (
    <span className="filter-name">Airline</span>
  );
