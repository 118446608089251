export const SET_ORIGIN = "packagesSearch/SET_ORIGIN";
export type SET_ORIGIN = typeof SET_ORIGIN;

export const SET_ORIGIN_CATEGORIES = "packagesSearch/SET_ORIGIN_CATEGORIES";
export type SET_ORIGIN_CATEGORIES = typeof SET_ORIGIN_CATEGORIES;

export const FETCH_ORIGIN_CATEGORIES = "packagesSearch/FETCH_ORIGIN_CATEGORIES";
export type FETCH_ORIGIN_CATEGORIES = typeof FETCH_ORIGIN_CATEGORIES;

export const SET_DESTINATION = "packagesSearch/SET_DESTINATION";
export type SET_DESTINATION = typeof SET_DESTINATION;

export const SET_DESTINATION_CATEGORIES =
  "packagesSearch/SET_DESTINATION_CATEGORIES";
export type SET_DESTINATION_CATEGORIES = typeof SET_DESTINATION_CATEGORIES;

export const FETCH_DESTINATION_CATEGORIES =
  "packagesSearch/FETCH_DESTINATION_CATEGORIES";
export type FETCH_DESTINATION_CATEGORIES = typeof FETCH_DESTINATION_CATEGORIES;

export const SET_DEPARTURE_DATE = "packagesSearch/SET_DEPARTURE_DATE";
export type SET_DEPARTURE_DATE = typeof SET_DEPARTURE_DATE;

export const SET_RETURN_DATE = "packagesSearch/SET_RETURN_DATE";
export type SET_RETURN_DATE = typeof SET_RETURN_DATE;

export const FETCH_DEPARTURE_CALENDAR =
  "packagesSearch/FETCH_DEPARTURE_CALENDAR";
export type FETCH_DEPARTURE_CALENDAR = typeof FETCH_DEPARTURE_CALENDAR;

export const SET_DEPARTURE_CALENDAR = "packagesSearch/SET_DEPARTURE_CALENDAR";
export type SET_DEPARTURE_CALENDAR = typeof SET_DEPARTURE_CALENDAR;

export const SET_STOPS_OPTION = "packagesSearch/SET_STOPS_OPTION";
export type SET_STOPS_OPTION = typeof SET_STOPS_OPTION;

export const SET_FARECLASS_OPTION_FILTER =
  "packagesSearch/SET_FARECLASS_OPTION_FILTER";
export type SET_FARECLASS_OPTION_FILTER = typeof SET_FARECLASS_OPTION_FILTER;

export const SET_OPEN_DATES_MODAL = "packagesSearch/SET_OPEN_DATES_MODAL";
export type SET_OPEN_DATES_MODAL = typeof SET_OPEN_DATES_MODAL;

export const SET_ORIGIN_AUTOCOMPLETE_ERROR =
  "packagesSearch/SET_ORIGIN_AUTOCOMPLETE_ERROR";
export type SET_ORIGIN_AUTOCOMPLETE_ERROR =
  typeof SET_ORIGIN_AUTOCOMPLETE_ERROR;

export const SET_DESTINATION_AUTOCOMPLETE_ERROR =
  "packagesSearch/SET_DESTINATION_AUTOCOMPLETE_ERROR";
export type SET_DESTINATION_AUTOCOMPLETE_ERROR =
  typeof SET_DESTINATION_AUTOCOMPLETE_ERROR;

export const SET_TRAVELERS = "packagesSearch/SET_TRAVELERS";
export type SET_TRAVELERS = typeof SET_TRAVELERS;
