import { FinalizePackageResponse } from "redmond";
import * as actionTypes from "./constants";

export interface IFinalizePackage {
  type: actionTypes.FINALIZE_PACKAGE;
}

export const finalizePackage = (): IFinalizePackage => ({
  type: actionTypes.FINALIZE_PACKAGE,
});

export interface ISetFinalizePackageResponse {
  type: actionTypes.SET_FINALIZE_PACKAGE_RESPONSE;
  finalizeResponse: FinalizePackageResponse;
}

export const setFinalizePackageResponse = (
  tripDetailsResponse: FinalizePackageResponse
): ISetFinalizePackageResponse => ({
  type: actionTypes.SET_FINALIZE_PACKAGE_RESPONSE,
  finalizeResponse: tripDetailsResponse,
});

export interface ISetFinalizePackageResponseCallStateFailed {
  type: actionTypes.SET_FINALIZE_PACKAGE_RESPONSE_CALL_STATE_FAILED;
  finalizePackageCallError: any; // Change this to correct error type
}

export const setFinalizePackageResponseCallStateFailed = (
  finalizePackageCallError: any // Change this to correct error type
): ISetFinalizePackageResponseCallStateFailed => ({
  type: actionTypes.SET_FINALIZE_PACKAGE_RESPONSE_CALL_STATE_FAILED,
  finalizePackageCallError,
});

export type PackageItineraryReviewActions =
  | IFinalizePackage
  | ISetFinalizePackageResponse
  | ISetFinalizePackageResponseCallStateFailed;
