import React, { useCallback, useEffect, useMemo, useState } from "react";
import { DesktopAllFiltersModal } from "./DesktopAllFiltersModal/component";
import {
  AirlineCode,
  FlightSortOption,
  IFlightNumberFilter,
  ITimeRange,
} from "redmond";
import { FlightShopAllFiltersModalConnectorProps } from "./container";
import { isEqual } from "lodash-es";
import { initialFilterOptions, initialTimeRange } from "../../reducer";
import { AirlineIcon } from "halifax";
import { RouteComponentProps } from "react-router-dom";
import { MobileAllFiltersModal } from "./MobileAllFiltersModal/component";
import {
  airCXV3SortOptionsToDisplay,
  airCXV4FiltersToDisplay,
} from "../../../../utils/sortAndFilter";

interface IFlightShopAllFiltersModal
  extends FlightShopAllFiltersModalConnectorProps,
    RouteComponentProps {
  openAllFiltersModal: boolean;
  setOpenAllFiltersModal: (arg: boolean) => void;
  isMobile?: boolean;
}
export const FlightShopAllFiltersModal = (
  props: IFlightShopAllFiltersModal
) => {
  const {
    openAllFiltersModal,
    setOpenAllFiltersModal,
    stopsOption,
    airlineFilter,
    setStopsOption,
    setAirlineFilter,
    isMobile,
    flightShopFilters,
    maxPriceFilter,
    setMaxPriceFilter,
    durationFilter,
    setDurationFilter,
    minDuration,
    maxDuration,
    flightCount,
    outboundDepartureTimeRange,
    outboundArrivalTimeRange,
    returnDepartureTimeRange,
    returnArrivalTimeRange,
    setOutboundDepartureTimeRange,
    setOutboundArrivalTimeRange,
    setReturnDepartureTimeRange,
    setReturnArrivalTimeRange,
    origin,
    // destination,
    originAirport,
    // destinationAirport,
    airportFilter,
    setAirportFilter,
    flightNumberFilter,
    setFlightNumberFilter,
    departureDateString,
    sortOption,
    setSortOption,
  } = props;
  const [currSortOption, setCurrSortOption] =
    useState<FlightSortOption>(sortOption);
  const [currStopsOption, setCurrStopsOption] = useState(stopsOption);
  const [currAirlineFilter, setCurrAirlineFilter] =
    useState<AirlineCode[]>(airlineFilter);
  const [currMaxPriceFilter, setCurrMaxPriceFilter] =
    useState<number>(maxPriceFilter);
  const [currDurationFilter, setCurrDurationFilter] = useState(durationFilter);
  const [currOutboundDepartureTimeRange, setCurrOutboundDepartureTimeRange] =
    useState<ITimeRange>(outboundDepartureTimeRange);
  const [currOutboundArrivalTimeRange, setCurrOutboundArrivalTimeRange] =
    useState<ITimeRange>(outboundArrivalTimeRange);
  const [currReturnDepartureTimeRange, setCurrReturnDepartureTimeRange] =
    useState<ITimeRange>(returnDepartureTimeRange);
  const [currReturnArrivalTimeRange, setCurrReturnArrivalTimeRange] =
    useState<ITimeRange>(returnArrivalTimeRange);
  const [currAirportFilter, setCurrAirportFilter] =
    useState<string[]>(airportFilter);

  const [currFlightNumberAirlineFilter, setCurrFlightNumberAirlineFilter] =
    useState<string>("");
  const [currFlightNumberFilter, setCurrFlightNumberFilter] =
    useState<IFlightNumberFilter[]>(flightNumberFilter);

  const { airlineOptions } = flightShopFilters;

  const allAirlinesWithIcon = React.useMemo(() => {
    return airlineOptions.map((airline) => {
      return {
        ...airline,
        icon: <AirlineIcon airlineCode={airline.value} />,
      };
    });
  }, [airlineOptions]);

  useEffect(() => {
    setCurrStopsOption(stopsOption);
  }, [stopsOption, openAllFiltersModal]);

  useEffect(() => {
    setCurrAirlineFilter(airlineFilter);
  }, [airlineFilter, openAllFiltersModal]);

  useEffect(() => {
    setCurrMaxPriceFilter(maxPriceFilter);
  }, [maxPriceFilter, openAllFiltersModal]);

  useEffect(() => {
    setCurrDurationFilter(durationFilter);
  }, [durationFilter, openAllFiltersModal]);

  useEffect(() => {
    setCurrOutboundDepartureTimeRange(outboundDepartureTimeRange);
  }, [outboundDepartureTimeRange, openAllFiltersModal]);

  useEffect(() => {
    setCurrOutboundArrivalTimeRange(outboundArrivalTimeRange);
  }, [outboundArrivalTimeRange, openAllFiltersModal]);

  useEffect(() => {
    setCurrReturnDepartureTimeRange(returnDepartureTimeRange);
  }, [returnDepartureTimeRange, openAllFiltersModal]);

  useEffect(() => {
    setCurrReturnArrivalTimeRange(returnArrivalTimeRange);
  }, [returnArrivalTimeRange, openAllFiltersModal]);

  useEffect(() => {
    setCurrAirportFilter(airportFilter);
  }, [airportFilter, openAllFiltersModal]);

  useEffect(() => {
    setCurrFlightNumberFilter(flightNumberFilter);
  }, [flightNumberFilter, openAllFiltersModal]);

  const optionsChanged = useMemo(
    () =>
      !isEqual(sortOption, currSortOption) ||
      !isEqual(airlineFilter, currAirlineFilter) ||
      !isEqual(airportFilter, currAirportFilter) ||
      !isEqual(flightNumberFilter, currFlightNumberFilter) ||
      maxPriceFilter !== currMaxPriceFilter ||
      !isEqual(outboundDepartureTimeRange, currOutboundDepartureTimeRange) ||
      !isEqual(outboundArrivalTimeRange, currOutboundArrivalTimeRange) ||
      !isEqual(returnDepartureTimeRange, currReturnDepartureTimeRange) ||
      !isEqual(returnArrivalTimeRange, currReturnArrivalTimeRange) ||
      !isEqual(durationFilter, currDurationFilter) ||
      !isEqual(stopsOption, currStopsOption),

    [
      sortOption,
      currSortOption,
      airlineFilter,
      currAirlineFilter,
      airportFilter,
      currAirportFilter,
      flightNumberFilter,
      currFlightNumberFilter,
      maxPriceFilter,
      currMaxPriceFilter,
      outboundDepartureTimeRange,
      currOutboundDepartureTimeRange,
      outboundArrivalTimeRange,
      currOutboundArrivalTimeRange,
      returnDepartureTimeRange,
      currReturnDepartureTimeRange,
      returnArrivalTimeRange,
      currReturnArrivalTimeRange,
      currDurationFilter,
      stopsOption,
      currStopsOption,
    ]
  );

  const handleApply = useCallback(() => {
    isMobile && setSortOption(currSortOption); // sort option is on the all filters modal for mobile only
    setAirlineFilter(currAirlineFilter);
    setAirportFilter(currAirportFilter);
    !isEqual(currFlightNumberFilter, flightNumberFilter) && // only set flight number filter if the curr/redux states are different because this action sets airline filter to be [] (we only want it to override the airline filter if user is actually selecting a flight number filter)
      setFlightNumberFilter(currFlightNumberFilter);
    !currFlightNumberFilter.length && setCurrFlightNumberAirlineFilter("");
    setMaxPriceFilter(currMaxPriceFilter);
    setOutboundDepartureTimeRange(currOutboundDepartureTimeRange);
    setOutboundArrivalTimeRange(currOutboundArrivalTimeRange);
    setReturnDepartureTimeRange(currReturnDepartureTimeRange);
    setReturnArrivalTimeRange(currReturnArrivalTimeRange);
    setDurationFilter(currDurationFilter);
    setStopsOption(currStopsOption);
  }, [
    isMobile,
    sortOption,
    currSortOption,
    airlineFilter,
    currAirlineFilter,
    airportFilter,
    currAirportFilter,
    flightNumberFilter,
    currFlightNumberFilter,
    maxPriceFilter,
    currMaxPriceFilter,
    outboundDepartureTimeRange,
    currOutboundDepartureTimeRange,
    outboundArrivalTimeRange,
    currOutboundArrivalTimeRange,
    returnDepartureTimeRange,
    currReturnDepartureTimeRange,
    returnArrivalTimeRange,
    currReturnArrivalTimeRange,

    currDurationFilter,
    currStopsOption,
  ]);

  const canReset = useMemo(
    () =>
      (isMobile && currSortOption !== FlightSortOption.FareScore) ||
      !isEqual(currAirlineFilter, []) ||
      !isEqual(currAirportFilter, []) ||
      !isEqual(currFlightNumberFilter, []) ||
      currFlightNumberAirlineFilter !== "" ||
      currMaxPriceFilter !== initialFilterOptions.maxPriceFilter ||
      !isEqual(currOutboundDepartureTimeRange, initialTimeRange) ||
      !isEqual(currOutboundArrivalTimeRange, initialTimeRange) ||
      !isEqual(currReturnDepartureTimeRange, initialTimeRange) ||
      !isEqual(currReturnArrivalTimeRange, initialTimeRange) ||
      !isEqual(currDurationFilter, initialFilterOptions.durationFilter) ||
      currStopsOption !== initialFilterOptions.stopsOption,

    [
      isMobile,
      currSortOption,
      currAirlineFilter,
      currAirportFilter,
      currFlightNumberFilter,
      currFlightNumberAirlineFilter,
      currMaxPriceFilter,
      currOutboundDepartureTimeRange,
      currOutboundArrivalTimeRange,
      currReturnDepartureTimeRange,
      currReturnArrivalTimeRange,
      currDurationFilter,
      currStopsOption,
    ]
  );

  const handleReset = useCallback(() => {
    isMobile && setCurrSortOption(FlightSortOption.FareScore);
    setCurrAirlineFilter([]);
    setCurrFlightNumberAirlineFilter("");
    setCurrFlightNumberFilter([]);
    setCurrAirportFilter([]);
    setCurrMaxPriceFilter(initialFilterOptions.maxPriceFilter);
    setCurrOutboundDepartureTimeRange(initialTimeRange);
    setCurrOutboundArrivalTimeRange(initialTimeRange);
    setCurrReturnDepartureTimeRange(initialTimeRange);
    setCurrReturnArrivalTimeRange(initialTimeRange);
    setCurrDurationFilter(initialFilterOptions.durationFilter);
    setCurrStopsOption(initialFilterOptions.stopsOption);
  }, [isMobile]);

  const filtersToApplyCount = useMemo(
    () =>
      [
        isMobile && !isEqual(sortOption, currSortOption),
        !isEqual(airlineFilter, currAirlineFilter),
        !isEqual(airportFilter, currAirportFilter),
        !isEqual(flightNumberFilter, currFlightNumberFilter),
        maxPriceFilter !== currMaxPriceFilter,
        !isEqual(outboundDepartureTimeRange, currOutboundDepartureTimeRange),
        !isEqual(outboundArrivalTimeRange, currOutboundArrivalTimeRange),
        !isEqual(returnDepartureTimeRange, currReturnDepartureTimeRange),
        !isEqual(returnArrivalTimeRange, currReturnArrivalTimeRange),
        !isEqual(durationFilter, currDurationFilter),
        !isEqual(stopsOption, currStopsOption),
      ].filter((hasChanged) => hasChanged).length,

    [
      isMobile,
      sortOption,
      currSortOption,
      airlineFilter,
      currAirlineFilter,
      airportFilter,
      currAirportFilter,
      flightNumberFilter,
      currFlightNumberFilter,
      maxPriceFilter,
      currMaxPriceFilter,
      outboundDepartureTimeRange,
      currOutboundDepartureTimeRange,
      outboundArrivalTimeRange,
      currOutboundArrivalTimeRange,
      returnDepartureTimeRange,
      currReturnDepartureTimeRange,
      returnArrivalTimeRange,
      currReturnArrivalTimeRange,
      durationFilter,
      currDurationFilter,
      stopsOption,
      currStopsOption,
    ]
  );

  return isMobile ? (
    <MobileAllFiltersModal
      openAllFiltersModal={openAllFiltersModal}
      setOpenAllFiltersModal={setOpenAllFiltersModal}
      optionsChanged={optionsChanged}
      handleApply={handleApply}
      handleReset={handleReset}
      readyToReset={canReset}
      stopsOption={currStopsOption}
      setStopsOption={setCurrStopsOption}
      airlineFilter={currAirlineFilter}
      setAirlineFilter={setCurrAirlineFilter}
      allAirlines={allAirlinesWithIcon}
      maxPriceFilter={currMaxPriceFilter}
      setMaxPriceFilter={setCurrMaxPriceFilter}
      flightShopFilters={flightShopFilters}
      durationFilter={currDurationFilter}
      setDurationFilter={setCurrDurationFilter}
      minDuration={minDuration}
      maxDuration={maxDuration}
      filteredFlightCount={flightCount}
      outboundDepartureTimeRange={currOutboundDepartureTimeRange}
      outboundArrivalTimeRange={currOutboundArrivalTimeRange}
      returnDepartureTimeRange={currReturnDepartureTimeRange}
      returnArrivalTimeRange={currReturnArrivalTimeRange}
      setOutboundDepartureTimeRange={setCurrOutboundDepartureTimeRange}
      setOutboundArrivalTimeRange={setCurrOutboundArrivalTimeRange}
      setReturnDepartureTimeRange={setCurrReturnDepartureTimeRange}
      setReturnArrivalTimeRange={setCurrReturnArrivalTimeRange}
      airportFilter={currAirportFilter}
      setAirportFilter={setCurrAirportFilter}
      flightNumberFilter={currFlightNumberFilter}
      setFlightNumberFilter={setCurrFlightNumberFilter}
      flightNumberAirlineFilter={currFlightNumberAirlineFilter}
      setFlightNumberAirlineFilter={setCurrFlightNumberAirlineFilter}
      origin={origin}
      originAirport={originAirport}
      departureDateString={departureDateString}
      filtersToDisplay={airCXV4FiltersToDisplay}
      filtersToApplyCount={filtersToApplyCount}
      sortOption={currSortOption}
      setSortOption={setCurrSortOption}
      sortOptionsToDisplay={airCXV3SortOptionsToDisplay}
    />
  ) : (
    <DesktopAllFiltersModal
      openAllFiltersModal={openAllFiltersModal}
      onCloseModal={() => setOpenAllFiltersModal(false)}
      optionsChanged={optionsChanged}
      handleApply={handleApply}
      handleReset={handleReset}
      readyToReset={canReset}
      stopsOption={currStopsOption}
      setStopsOption={setCurrStopsOption}
      airlineFilter={currAirlineFilter}
      setAirlineFilter={setCurrAirlineFilter}
      allAirlines={allAirlinesWithIcon}
      maxPriceFilter={currMaxPriceFilter}
      setMaxPriceFilter={setCurrMaxPriceFilter}
      flightShopFilters={flightShopFilters}
      durationFilter={currDurationFilter}
      setDurationFilter={setCurrDurationFilter}
      minDuration={minDuration}
      maxDuration={maxDuration}
      filteredFlightCount={flightCount}
      outboundDepartureTimeRange={currOutboundDepartureTimeRange}
      outboundArrivalTimeRange={currOutboundArrivalTimeRange}
      returnDepartureTimeRange={currReturnDepartureTimeRange}
      returnArrivalTimeRange={currReturnArrivalTimeRange}
      setOutboundDepartureTimeRange={setCurrOutboundDepartureTimeRange}
      setOutboundArrivalTimeRange={setCurrOutboundArrivalTimeRange}
      setReturnDepartureTimeRange={setCurrReturnDepartureTimeRange}
      setReturnArrivalTimeRange={setCurrReturnArrivalTimeRange}
      airportFilter={currAirportFilter}
      setAirportFilter={setCurrAirportFilter}
      flightNumberFilter={currFlightNumberFilter}
      setFlightNumberFilter={setCurrFlightNumberFilter}
      flightNumberAirlineFilter={currFlightNumberAirlineFilter}
      setFlightNumberAirlineFilter={setCurrFlightNumberAirlineFilter}
      origin={origin}
      originAirport={originAirport}
      departureDateString={departureDateString}
    />
  );
};
