import { Box, Button, Typography } from "@material-ui/core";
import { GoogleMap } from "@react-google-maps/api";
import clsx from "clsx";
import { ActionButton, Icon, IconName, useDeviceTypes } from "halifax";
import React, { useEffect } from "react";
import { RouteComponentProps } from "react-router";
import {
  BoundingBox,
  ICoordinates,
  IIdLodgings,
  LocationDescriptorEnum,
} from "redmond";

import { AvailabilityMapContent } from "./components/AvailabilityMapContent";
import { AvailabilityMapConnectorProps } from "./container";
import { convertGoogleMapCoordsToICoordinates } from "./googleMapsHelpers";
import "./styles.scss";

export interface IAvailabilityMapProps
  extends AvailabilityMapConnectorProps,
    RouteComponentProps {
  isPreview?: boolean;
  onClick?: () => void;
}

const UPDATED_MAP_STYLES: google.maps.MapTypeStyle[] = [
  {
    featureType: "all",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "landscape.natural",
    elementType: "all",
    stylers: [
      {
        visibility: "simplified",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "all",
    stylers: [
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "all",
    stylers: [
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "all",
    stylers: [
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "road.highway.controlled_access",
    elementType: "all",
    stylers: [
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "all",
    stylers: [
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "all",
    stylers: [
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "transit",
    elementType: "all",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "all",
    stylers: [
      {
        visibility: "on",
      },
    ],
  },
];

export const AvailabilityMap = (props: IAvailabilityMapProps) => {
  const {
    lodgingIdInFocus,
    setSearchedMapBound,

    history,
    isPreview,
    onClick: onClickHandler,
    setViewHotelsNearLocation,
    viewHotelsNearLocation,
    onClick,
    lodgings,
    setLodgingIdInFocus,
    // TODO: Implement and integrate filters
    // resetFilters,
  } = props;

  const { matchesMobile, matchesDesktop } = useDeviceTypes();
  const [mapRef, setMapRef] = React.useState<google.maps.Map | null>(null);
  const [isMapMoved, setIsMapMoved] = React.useState(false);
  const [viewHotelsNearCoordinates, setViewHotelsNearCoordinates] =
    React.useState<ICoordinates | null>(null);

  const [mapHeight, setMapHeight] = React.useState<string>();

  useEffect(() => {
    if (!matchesMobile && lodgingIdInFocus) {
      const coords = lodgings.find(
        (lodging) => lodging.lodging.id === lodgingIdInFocus
      )?.lodging.location.coordinates;

      if (coords) {
        mapRef?.panTo({
          lat: coords.lat,
          lng: coords.lon,
        });
        // ensure map tooltip is also in view
        mapRef?.panBy(0, -200);
        setIsMapMoved(true);
      }
    }
    return undefined;
  }, [matchesMobile, lodgingIdInFocus]);

  // clear map searches when map unmounts
  useEffect(() => {
    return () => {
      if (!matchesMobile) {
        setSearchedMapBound(null);
        setViewHotelsNearLocation(null);
      }
    };
  }, []);

  useEffect(() => {
    // do not show view hotels near if map is in preview mode or search by map
    if (isPreview) {
      return;
    }
    if (viewHotelsNearLocation) {
      const geocoder = new google.maps.Geocoder();
      geocoder
        .geocode({
          placeId: (viewHotelsNearLocation.id as IIdLodgings).lodgingSelection
            .placeId,
        })
        .then(({ results }) => {
          setIsMapMoved(false);
          setViewHotelsNearCoordinates({
            lat: results[0].geometry.location.lat(),
            lon: results[0].geometry.location.lng(),
          });
          // trackEvent({
          //   eventName: "view_hotels_near",
          //   ...viewHotelsNearAvailabilityProperties,
          // });
          // fetchInitialHotelAvailability(history, false, true);
        });
    }
  }, [viewHotelsNearLocation]);

  const updateMapHeight = () => {
    if (matchesDesktop) {
      // note: it has to subtract the height of header
      setMapHeight("100%");
    } else if (isPreview) {
      setMapHeight("130px");
    } else {
      setMapHeight(`${window.innerHeight}px`);
    }
  };
  const checkForDrag = () => {
    onClickHandler && onClickHandler();
  };

  const onMoveMap = () => {
    setIsMapMoved(true);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMapHeight);
    return () => {
      window.removeEventListener("resize", updateMapHeight);
    };
  }, []);

  useEffect(() => {
    updateMapHeight();
  }, [matchesDesktop, isPreview]);

  const onClickRedoSearchButton = () => {
    setIsMapMoved(false);
    // trackEvent({
    //   eventName: SEARCH_THIS_AREA,
    //   properties: {},
    // });

    const mapBoundFromGoogleMaps = mapRef?.getBounds();

    if (mapBoundFromGoogleMaps) {
      const newMapBound: BoundingBox = {
        northEast: convertGoogleMapCoordsToICoordinates(
          mapBoundFromGoogleMaps.getNorthEast()
        ),
        southWest: convertGoogleMapCoordsToICoordinates(
          mapBoundFromGoogleMaps.getSouthWest()
        ),
        LocationDescriptor: LocationDescriptorEnum.BoundingBox,
      };

      setSearchedMapBound(newMapBound);
      setViewHotelsNearCoordinates(null);
      // fetchViewHotelsNearLocationCategories("");
      // fetchInitialHotelAvailability(history, true);
      // TODO: Implement and integrate filters
      // resetFilters([HotelAvailabilityFilterType.Neighborhood]);
    }
  };

  const showSearchButton = isMapMoved && !isPreview && !lodgingIdInFocus;

  return (
    <Box
      className="lodging-availability-map-holder"
      onClick={(e) => {
        if (
          !(e.target instanceof HTMLInputElement) &&
          !(e.target instanceof HTMLButtonElement)
        ) {
          setLodgingIdInFocus(null);
        }
        checkForDrag();
      }}
    >
      {showSearchButton && (
        <Button
          className={clsx("lodging-availability-map-search-button", {
            "view-hotels-near-enabled": true,
          })}
          onClick={onClickRedoSearchButton}
        >
          <Icon name={IconName.MagnifyingGlass} />
          <Typography className="lodging-availability-map-search-button-text">
            Search this area
          </Typography>
        </Button>
      )}

      <GoogleMap
        id={clsx("lodging-availability-map", {
          mobile: matchesMobile,
          preview: isPreview,
        })}
        mapContainerStyle={{
          height: mapHeight,
          width: "100%",
          ...(isPreview && { borderRadius: "4px" }),
        }}
        zoom={11}
        onDragEnd={onMoveMap}
        onZoomChanged={onMoveMap}
        onLoad={() => setIsMapMoved(false)}
        options={{
          clickableIcons: false,
          zoomControl: isPreview ? false : matchesDesktop,
          streetViewControl: false,
          mapTypeControl: false,
          disableDoubleClickZoom: true,
          styles: UPDATED_MAP_STYLES,
          fullscreenControl: matchesDesktop,
          ...(isPreview && { gestureHandling: "none" }),
        }}
      >
        <AvailabilityMapContent
          mapProps={props}
          mapRef={mapRef}
          setMapRef={setMapRef}
          history={history}
          viewHotelsNearCoordinates={viewHotelsNearCoordinates}
        />
        {isPreview && (
          <ActionButton
            className={"show-map-button"}
            onClick={() => onClick && onClick()}
            message={
              <Box className="toggle-map-button-text">View Hotels on Map</Box>
            }
          />
        )}
      </GoogleMap>
    </Box>
  );
};
