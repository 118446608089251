import axios, { AxiosResponse } from "axios";
import { ConvertUsdToAllRewardsRequest, RewardsPrice } from "redmond";

import { config } from "../../config";

import { rewardsApiPrefix } from "../paths";

const END_POINT: string = `${rewardsApiPrefix}/convert/redeem/usdToAllRewards`;

export const convertUsdToAllRewards = (
  request: ConvertUsdToAllRewardsRequest
): Promise<{ [key: string]: RewardsPrice }> =>
  new Promise(async (resolve, reject) => {
    try {
      const res: AxiosResponse<{ [key: string]: RewardsPrice }> = await axios.put(END_POINT, request, {
        baseURL: config.baseURL,
      });

      resolve((res.data as any).value);
    } catch (e) {
      reject(e);
    }
  });
