import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { MobileTripReview } from "./component";
import { IStoreState } from "../../../../reducers/types";

const mapStateToProps = (_state: IStoreState) => ({});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MobileTripReviewConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedMobileTripReview = withRouter(
  connector(MobileTripReview)
);
