import { createSelector } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { IStoreState } from "../../../../reducers/types";
import { TravelWalletOffer } from "redmond";

export const getTravelWalletOffers = (state: IStoreState) =>
  state.packagesTravelWallet.offers;

export const getActiveTravelWalletOffers = createSelector(
  getTravelWalletOffers,
  (offers) => {
    return offers.filter((offer) => {
      return dayjs().isAfter(dayjs(offer?.saleEventStartOn));
    });
  }
);

export const getTravelWalletCredit = (state: IStoreState) =>
  state.packagesTravelWallet.credit;

export const getCreditBreakdown = (state: IStoreState) =>
  state.packagesTravelWallet.creditBreakdown;

export const getTravelCreditsHistoryTransaction = (state: IStoreState) =>
  state.packagesTravelWallet.creditsHistoryTransactions;

export const getActiveCrossSellOffers = createSelector(
  getTravelWalletOffers,
  (travelWalletOffers): TravelWalletOffer[] => {
    const activeCrossSellOffers = travelWalletOffers.filter((offer) => {
      return offer.imageId && offer.imageId.startsWith("CrossSellOffer");
    });
    return activeCrossSellOffers;
  }
);
