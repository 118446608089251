import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { ShopErrorModal } from "./component";
import {
  getHotelShopCallError,
  hasHotelShopFailed,
  isMissingProductInfo,
} from "../../reducer/selectors";
import { fetchPackageHotelShop } from "../../actions/actions";
import { IStoreState } from "../../../../reducers/types";

const mapStateToProps = (state: IStoreState) => {
  return {
    hasFailed: hasHotelShopFailed(state),
    missingRoomProductInfo: isMissingProductInfo(state),
    shopError: getHotelShopCallError(state),
  };
};

const mapDispatchToProps = {
  fetchPackageHotelShop,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type ShopErrorModalConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedShopErrorModal = withRouter(connector(ShopErrorModal));
