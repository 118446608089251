import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../../reducers/types";
import {
  getRewardsAccounts,
  getSelectedAccountReferenceId,
} from "../../../rewards/reducer";
import { getNightCount } from "../../../search/reducer";
import {
  fetchDistanceFromLocationCategories,
  setDistanceFromLocation,
} from "../../actions/actions";

import { getDistanceFromLocation } from "../../reducer/selectors";
import { HotelShopExpandedMap } from "./component";

const mapStateToProps = (state: IStoreState) => {
  return {
    rewardsAccountId: getSelectedAccountReferenceId(state) || "",
    distanceFromLocation: getDistanceFromLocation(state),
    nightCount: getNightCount(state),
    isVentureX: !!getRewardsAccounts(state).find((acc) =>
      acc.productDisplayName.includes("Venture X")
    ),
  };
};

export const mapDispatchToProps = {
  setDistanceFromLocation,
  fetchDistanceFromLocationCategories,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type HotelShopExpandedMapConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedHotelShopExpandedMap = connector(HotelShopExpandedMap);
