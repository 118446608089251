import { useGoogleMap, OverlayView } from "@react-google-maps/api";
import { PaneNames } from "@react-google-maps/api/dist/components/dom/OverlayView";
import { useDeviceTypes, HotelMapPin } from "halifax";
import { ICoordinates, Lodging, LodgingCollectionEnum } from "redmond";
import { IAvailabilityMapProps } from "../../component";

import * as H from "history";

import React, { useMemo } from "react";
import Box from "@material-ui/core/Box";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { Typography } from "@material-ui/core";
import { AvailabilityMapPricePin } from "../AvailabilityMapPricePin";
import { convertICoordinatesToGoogleMapCoords } from "../../googleMapsHelpers";

const MAP_LODGING_COUNT_LIMIT = 105;
const LODGING_HISTORY_COUNT = 3;

export const AvailabilityMapContent = (props: {
  mapProps: IAvailabilityMapProps;
  mapRef: google.maps.Map | null;
  setMapRef: React.Dispatch<React.SetStateAction<google.maps.Map | null>>;
  history: H.History;
  viewHotelsNearCoordinates?: ICoordinates | null;
}) => {
  const {
    mapProps: {
      lodgings,
      searchLocation,
      lodgingIdInFocus,
      lodgingIdHovered,
      searchedMapBound,
      nightCount,
      setLodgingIdInFocus,
      isPreview,
      viewHotelsNearLocation,
      packagesByLodgingId,
    },
    mapRef,
    setMapRef,
    viewHotelsNearCoordinates,
  } = props;

  const { matchesDesktop } = useDeviceTypes();
  if (!lodgings) return null;

  const [showViewHotelsNearLabel, setShowViewHotelsNearLabel] =
    React.useState<boolean>(!!viewHotelsNearLocation);
  const [bounds, setBounds] = React.useState<
    google.maps.LatLngBounds | null | undefined
  >(null);
  // note: on hotel availability screen, followUp search requests are being made constantly;
  // it should recenter mapRef only on the first searchLocation update
  const initialLocationSearched = React.useRef(false);
  React.useEffect(() => {
    initialLocationSearched.current = false;
  }, [searchLocation]);

  // note: on hotel availability screen, followUp search requests are being made constantly;
  // it should recenter mapRef only on the first searchLocation update
  const initialViewHotelsLocationSearch = React.useRef(false);
  React.useEffect(() => {
    initialViewHotelsLocationSearch.current = false;
    if (viewHotelsNearCoordinates) {
      setShowViewHotelsNearLabel(true);
    }
  }, [viewHotelsNearCoordinates]);

  const googleMap = React.useRef(useGoogleMap());

  React.useEffect(() => {
    setMapRef(googleMap.current);

    // note: when mapRef?.getBounds() is used directly in lodgings.filter, it won't cause the component to re-render
    // when actual bounds are changed; assigning mapRef?.getBounds() to a state and have it updated in a listener
    // seems to solve the issue.
    googleMap.current?.addListener("bounds_changed", () => {
      setBounds(googleMap.current?.getBounds());
    });
  }, []);

  React.useEffect(() => {
    // The order of the if / else statements matters here.
    // If we have viewHotelsNearCoordinates the user has search a point of interest on the map
    // and that should take precedence over their previously search location
    // If we have a searchedMapBound the user has just searched in a specific location on the map
    // and that should take precedence over their previously searched location and their point of interest.
    if (mapRef && searchedMapBound) {
      setLodgingIdInFocus(null);
      const center: ICoordinates = {
        lat:
          (searchedMapBound.northEast.lat + searchedMapBound.southWest.lat) / 2,
        lon:
          (searchedMapBound.northEast.lon + searchedMapBound.southWest.lon) / 2,
      };
      const searchLocationCoordinates =
        convertICoordinatesToGoogleMapCoords(center);
      mapRef.setCenter(searchLocationCoordinates);
    } else if (
      mapRef &&
      viewHotelsNearCoordinates &&
      !initialViewHotelsLocationSearch.current
    ) {
      setLodgingIdInFocus(null);
      const searchLocationCoordinates = convertICoordinatesToGoogleMapCoords(
        viewHotelsNearCoordinates
      );
      mapRef.setCenter(searchLocationCoordinates);
      initialViewHotelsLocationSearch.current = true;
    } else if (mapRef && searchLocation && !initialLocationSearched.current) {
      setLodgingIdInFocus(null);
      // const searchLocationCoordinates = convertICoordinatesToGoogleMapCoords(
      //   searchLocation.coordinates
      // );
      const searchLocationCoordinates = convertICoordinatesToGoogleMapCoords(
        searchLocation.coordinates
      );
      mapRef.setCenter(searchLocationCoordinates);
      initialLocationSearched.current = true;
    }
  }, [
    viewHotelsNearCoordinates,
    searchLocation,
    setLodgingIdInFocus,
    searchedMapBound,
    mapRef,
  ]);

  // [Packages TO-DO]: Revisit this to see if necessary bc no LC/PC in packages
  const premierCollectionDeduplicatedLodgings = useMemo(() => {
    // filter out premier collection versions
    return lodgings.filter(
      (lodging) => lodging.lodgingCollection !== LodgingCollectionEnum.Premier
    );
  }, [lodgings]);

  const lodgingInFocus = React.useMemo(
    () => lodgings?.find((lodging) => lodging.lodging.id === lodgingIdInFocus),
    [lodgings, lodgingIdInFocus]
  );

  // [BP-2119] Do not move map when hotel on list hovered
  // const lodgingHovered = React.useMemo(
  //   () => lodgings?.find((lodging) => lodging.lodging.id === lodgingIdHovered),
  //   [lodgings, lodgingIdHovered]
  // );

  // Keep the last 3 hotels in focus or hovered on the map
  const lodgingHistoryCount = LODGING_HISTORY_COUNT;
  const [lodgingIdsPreviouslyShown, setLodgingIdsPreviouslyShown] =
    React.useState<string[]>([]);

  const useAddToLodgingIdsPreviouslyShown = (lodgingId: string | null) =>
    React.useEffect(() => {
      if (lodgingId) {
        setLodgingIdsPreviouslyShown((oldList) => {
          const newList = [
            ...oldList.filter((id) => id !== lodgingId),
            lodgingId,
          ];
          // Only take the newest 3 hotels in the history
          return newList.slice(-lodgingHistoryCount);
        });
      }
    }, [lodgingId]);

  useAddToLodgingIdsPreviouslyShown(lodgingIdInFocus);
  useAddToLodgingIdsPreviouslyShown(lodgingIdHovered);

  const useRecenterMap = ({
    lodging,
    disabled,
    viewHotelsNearCoordinates,
    viewPortRestricted,
  }: {
    lodging?: Lodging;
    viewHotelsNearCoordinates?: ICoordinates;
    disabled?: boolean;
    viewPortRestricted?: boolean;
  }) => {
    return React.useEffect(() => {
      if (disabled) {
        return;
      }

      if (!lodging || !viewHotelsNearCoordinates) {
        return;
      }

      if (!mapRef) {
        return;
      }

      const projection = mapRef.getProjection();
      if (!projection) {
        return;
      }

      const lodgingLatLng = convertICoordinatesToGoogleMapCoords(
        lodging
          ? lodging.lodging.location.coordinates
          : viewHotelsNearCoordinates
      );
      const lodgingPoint = projection.fromLatLngToPoint(lodgingLatLng);
      if (!lodgingPoint) return;
      // Move center down by 150 pixels to make some space for the 300-pixel-tall hotel details box.
      if (matchesDesktop) {
        const scale = Math.pow(2, mapRef.getZoom() || 1);
        lodgingPoint.y = lodgingPoint.y - 150 / scale;
      }
      // Move center up by window.innerHeight/4px (using the innerHeight instead of a fixed px due to different mobile sizes) to make space for the different heights of the hotel box on the bottom when a lodging is selected
      if (!matchesDesktop && lodgingIdInFocus) {
        const scale = Math.pow(2, mapRef.getZoom() || 1);
        lodgingPoint.y = lodgingPoint.y + window.innerHeight / 4 / scale;
      }
      const newLatLng = projection.fromPointToLatLng(lodgingPoint);
      if (
        newLatLng &&
        (!viewPortRestricted || !mapRef.getBounds()?.contains(newLatLng))
      ) {
        mapRef.panTo(newLatLng);
      }
    }, [lodging, disabled, viewPortRestricted, viewHotelsNearCoordinates]);
  };

  if (!isPreview) {
    useRecenterMap({ lodging: lodgingInFocus });
    // Do not recenter based on lodgingHovered when lodgingInFocus still exists.
    // [BP-2119] - do not move map when list hovered
    // useRecenterMap({
    //   lodging: lodgingHovered,
    //   disabled: !!lodgingInFocus,
    //   viewPortRestricted: true,
    // });
  }

  const renderPricePin = React.useCallback(
    (mapPaneName: PaneNames, lodging?: Lodging) => {
      if (
        !lodging ||
        !packagesByLodgingId?.[lodging?.lodging.id]?.packageDetails.pricing
      ) {
        return null;
      }

      const coordinates = convertICoordinatesToGoogleMapCoords(
        lodging.lodging.location.coordinates
      );

      return (
        <OverlayView
          key={lodging.lodging.id}
          mapPaneName={mapPaneName}
          position={coordinates}
        >
          <AvailabilityMapPricePin
            isPreview={isPreview}
            isDesktop={matchesDesktop}
            lodging={lodging}
            isInFocus={lodgingIdInFocus === lodging.lodging.id}
            isHovered={
              lodgingIdHovered === lodging.lodging.id ||
              lodgingIdInFocus === lodging.lodging.id
            }
            isPreviouslyShown={lodgingIdsPreviouslyShown.includes(
              lodging.lodging.id
            )}
            nightCount={nightCount}
            setLodgingIdInFocus={setLodgingIdInFocus}
          />
        </OverlayView>
      );
    },
    [
      isPreview,
      lodgingIdInFocus,
      lodgingIdHovered,
      lodgingIdsPreviouslyShown,
      nightCount,
      setLodgingIdInFocus,
      packagesByLodgingId,
    ]
  );

  return (
    <>
      {premierCollectionDeduplicatedLodgings
        .filter((lodging) => {
          if (!bounds) {
            return undefined;
          }

          return bounds.contains(
            convertICoordinatesToGoogleMapCoords(
              lodging.lodging.location.coordinates
            )
          );
        })
        .map((lodging, newIndex) => {
          const mustShow =
            lodging.lodging.id === lodgingIdInFocus ||
            lodging.lodging.id === lodgingIdHovered ||
            lodgingIdsPreviouslyShown.includes(lodging.lodging.id);

          if (newIndex > MAP_LODGING_COUNT_LIMIT && !mustShow) {
            return null;
          }

          return renderPricePin(OverlayView.OVERLAY_MOUSE_TARGET, lodging);
        })}
      {/* TODO: re-enable POI map pin once we know how to get POI coordinates */}
      {viewHotelsNearCoordinates ? (
        <OverlayView
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          position={convertICoordinatesToGoogleMapCoords(
            viewHotelsNearCoordinates
          )}
        >
          <>
            {showViewHotelsNearLabel && viewHotelsNearLocation ? (
              <Box className="view-hotels-near-info-label-container">
                <Typography className="view-hotels-near-info-label">
                  {viewHotelsNearLocation.label.split(",")[0]}
                </Typography>
                <FontAwesomeIcon
                  className="icon info-box-close-button"
                  icon={faTimesCircle}
                  onClick={() => setShowViewHotelsNearLabel(false)}
                />
              </Box>
            ) : null}
            <HotelMapPin
              onClick={() =>
                setShowViewHotelsNearLabel(!showViewHotelsNearLabel)
              }
            />
          </>
        </OverlayView>
      ) : null}
    </>
  );
};
