import { takeLatest } from "redux-saga/effects";

import {
  FETCH_DISTANCE_FROM_LOCATION_CATEGORIES,
  FETCH_PACKAGE_HOTEL_SHOP,
} from "../actions/constants";

import fetchPackageHotelShopSaga from "./fetchPackageHotelShopSaga";
import { fetchDistanceFromLocations } from "./fetchDistanceFromLocationAutocompleteSaga";

export function* watchFetchHotelShopSaga() {
  yield takeLatest(FETCH_PACKAGE_HOTEL_SHOP, fetchPackageHotelShopSaga);
}

export function* watchFetchDistanceFromLocationCategories() {
  yield takeLatest(
    FETCH_DISTANCE_FROM_LOCATION_CATEGORIES,
    fetchDistanceFromLocations
  );
}
