import { race, takeLatest, take, call } from "redux-saga/effects";
import { actionTypes } from "../actions";
import { fetchPackagesAvailabilitySaga } from "./fetchPackagesAvailabilitySaga";
import { IFetchPackagesAvailability } from "../actions/actions";

export function* watchFetchPackagesAvailability() {
  yield takeLatest(
    actionTypes.FETCH_INITIAL_PACKAGES_AVAILABILITY,
    fetchPackagesAvailabilitySaga
  );
  yield takeLatest(
    actionTypes.FETCH_MORE_PACKAGES_AVAILABILITY,
    function* (action: IFetchPackagesAvailability) {
      yield race({
        task: call(fetchPackagesAvailabilitySaga, action),
        cancel: take([
          actionTypes.STOP_FETCH_MORE_PACKAGES_AVAILABILITY,
          actionTypes.FETCH_INITIAL_PACKAGES_AVAILABILITY,
        ]),
      });
    }
  );
}
