import React from "react";
import {
  TravelerSelectWorkflow,
  TravelerSelectStep,
  ActionButton,
  isAdult,
} from "halifax";
import { PersonId, SELECT_TRAVELERS } from "redmond";
import clsx from "clsx";

import "./styles.scss";
import { HotelBookPassengerSelectionConnectorProps } from "./container";
import * as constants from "./textConstants";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";
import { config } from "../../../../api/config";
import {
  FlightPassengerSelectors,
  ParentState,
  useCheckoutState,
  useCheckoutStateSelector,
  useChildMachineSelector,
} from "@capone/checkout";
import { LodgingSelectors } from "../../state/selectors";
import { personToIPerson } from "../../../../utils/personToIPerson";
import { Event, TEvent } from "../../state/events";
import { PackagesMachineContext } from "../../state/types";
import { Box, Typography } from "@material-ui/core";
import { PackagesPriceBreakdown } from "../PriceBreakdown";

export interface IHotelBookPassengerSelectionProps
  extends HotelBookPassengerSelectionConnectorProps {
  isMobile?: boolean;
  hasValidationError?: boolean;
  setHasValidationError?: (hasError: boolean) => void;
}

export const HotelBookPassengerSelection = ({
  isMobile,
  hasValidationError,
  setHasValidationError,
}: IHotelBookPassengerSelectionProps) => {
  const [_state, send] = useCheckoutState<TEvent, PackagesMachineContext>();

  const travelers = useChildMachineSelector(
    ParentState.passengerInformation,
    FlightPassengerSelectors.getUserPassengers
  );

  const selectedTravelerId = useCheckoutStateSelector(
    LodgingSelectors.getSelectedPassengerIdParent
  );

  const handleSelectPassenger = (passengerId: PersonId) => {
    send({
      type: Event.SELECT_PASSENGER,
      passengerId,
      singleTravelerWorkflow: true,
    });
  };

  const canContinue = isMobile || !!selectedTravelerId;

  const handleContinue = () => {
    if (canContinue) {
      setHasValidationError?.(false);
      send(Event.NEXT);
    } else {
      setHasValidationError?.(true);
    }
  };

  const handleBack = () => {
    send(Event.PREVIOUS);
  };

  React.useEffect(() => {
    if (travelers.length === 1) {
      handleSelectPassenger(travelers[0].id);
    }
  }, [travelers]);

  React.useEffect(() => {
    if (canContinue) {
      setHasValidationError?.(false);
    }
  }, [canContinue]);

  return (
    <>
      <TravelerSelectWorkflow
        readOnly
        hideTravelerActions
        displayModalSubtitle
        showGenderField
        showNationalityField
        singleTravelerWorkflow
        buttonClassName="b2b"
        className={clsx("packages-hotel-book-passenger-selection-root", {
          error: hasValidationError,
        })}
        errorMessage={constants.ADD_TRAVELER_ERROR_MESSAGE}
        isMobile={isMobile}
        progress={
          isMobile ? TravelerSelectStep.TravelerSelect : TravelerSelectStep.Main
        }
        setProgress={() => {}}
        selectedTravelerIds={selectedTravelerId ? [selectedTravelerId] : []}
        setSelectedTravelerIds={(travelerIds: string[]) => {
          trackEvent({
            eventName: SELECT_TRAVELERS,
            properties: {},
          });
          handleSelectPassenger(travelerIds[0]);
        }}
        titles={{
          travelerInfoTitle: isMobile
            ? constants.TRAVELER_INFO_TEXT
            : constants.TRAVELER_INFO_TITLE_SUBHEADING,
          travelerInfoSubtitle: isMobile
            ? constants.TRAVELER_INFO_TITLE_SUBHEADING
            : constants.TRAVELER_INFO_SUBTITLE,
          frequentFlyerTitle: constants.FREQUENT_FLYER_TITLE,
          additionalInfoTitle: constants.ADDITIONAL_INFO_TITLE,
          adultTitle: constants.ADULT_TITLE,
          childTitle: constants.CHILD_TITLE,
          infantSeatTitle: constants.INFANT_SEAT_TITLE,
          infantLapTitle: constants.INFANT_LAP_TITLE,
          addTravelers: isMobile
            ? constants.TRAVELER_INFO_TEXT
            : constants.TRAVELER_INFO_TITLE_SUBHEADING,
          completeTravelerProfileTitle: constants.COMPLETE_PROFILE_TITLE,
          completeTravelerProfileSubtitle: constants.COMPLETE_PROFILE_SUBTITLE,
        }}
        travelers={travelers
          .filter((person) => isAdult(person.dateOfBirth))
          .map(personToIPerson)}
        tenant={config.TENANT}
        trackEvent={trackEvent}
        onContinue={handleContinue}
        onGoBack={handleBack}
        mobileTravelerRowType="checkbox"
        selectionScreenHeaderElement={
          <PackagesPriceBreakdown isMobile dropdown />
        }
        customHeader={
          <Box className="packages-hotel-book-passenger-mobile-header">
            <Typography variant="h3" className="pax-header-primary-title">
              {constants.WHOS_CHECKING_IN_TITLE}
            </Typography>
            <hr />
            <Typography variant="h4" className="pax-header-secondary-title">
              {constants.TRAVELER_INFO_TITLE_SUBHEADING}
            </Typography>
            <Typography className="pax-header-subtitle">
              {constants.TRAVELER_INFO_SUBTITLE}
            </Typography>
          </Box>
        }
        showMobilePopoverTransition={false}
      />
      {!isMobile && (
        <>
          <Typography
            className={clsx("packages-hotel-pax-select-notice", {
              error: hasValidationError,
            })}
          >
            {constants.HOTEL_TRAVELER_NOTICE}
          </Typography>
          <ActionButton
            onClick={handleContinue}
            message={constants.CONTINUE_TEXT}
            className="packages-continue-button"
          />
        </>
      )}
    </>
  );
};
