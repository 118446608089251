import { put, putResolve, select } from "redux-saga/effects";
import { actions } from "../actions";
import Logger from "../../../utils/logger";
import {
  FinalizePackageResponse,
  PackagedRoom,
  Platform,
  SafePackageDetails,
} from "redmond";
import { selectedPackageByRateId } from "../../hotel-shop/reducer";
import { IStoreState } from "../../../reducers/types";
import { getSelectedReturnFlightPackage } from "../../flight-shop/reducer";
import { finalizeFlightLodging } from "../../../api/v0/itinerary-review/finalizeFlightLodging";

export default function* finalizePackageSaga() {
  try {
    const state: IStoreState = yield select();
    let selectedPackage: PackagedRoom | undefined =
      yield selectedPackageByRateId(state);
    let selectedFlightPackage: SafePackageDetails | undefined =
      yield getSelectedReturnFlightPackage(state);
    const lodgingQuoteRequest = selectedPackage?.opaqueQuoteRequest;
    const flightPackageToken = selectedFlightPackage?.opaquePackageToken;

    if (!lodgingQuoteRequest || !flightPackageToken) {
      throw new Error("Hotel and flight tokens must be present.");
    }

    const response: FinalizePackageResponse = yield finalizeFlightLodging({
      opaqueQuoteRequest: lodgingQuoteRequest,
      opaquePackageToken: flightPackageToken,
      platform: Platform.Desktop,
    });

    yield putResolve(actions.setFinalizePackageResponse(response));
  } catch (e) {
    Logger.debug(e);
    yield put(actions.setFinalizePackageResponseCallStateFailed(e));
  }
}
