import { Box, Tooltip, Typography } from "@material-ui/core";
import dayjs from "dayjs";
import {
  removeTimezone,
  AirlineIcon,
  formatInterval,
  Icon,
  IconName,
} from "halifax";
import React from "react";

import {
  getReviewCardHeaderWithType,
  getSliceIndex,
  getStopsString,
} from "./constants";
import { uniqBy } from "lodash-es";
import { FlightSummaryPanelConnectorProps } from "./container";
import "./styles.scss";

interface IFlightSummaryPanelProps extends FlightSummaryPanelConnectorProps {
  departure?: boolean;
}
export const FlightSummaryPanel = (props: IFlightSummaryPanelProps) => {
  const { departure = true, tripDetails, airports } = props;
  if (!tripDetails) return null;

  const sliceIndex = getSliceIndex(departure, tripDetails);
  const tripSlice = tripDetails.slices[sliceIndex];
  // get all operating airlines & add tooltips because only logos will be shown
  const airlines: any[] = tripSlice.segmentDetails.map((sd) => ({
    ...sd.operatingAirline,
    tooltip: `1 or more segments operated by ${sd.operatingAirline.name}`,
  }));

  // put marketing airline as first without tooltip
  airlines.unshift(tripSlice.segmentDetails[0].marketingAirline);

  // remove duplicates
  const uniqSegmentAirlines = uniqBy(airlines, "code");

  const { type, description } = getReviewCardHeaderWithType(
    departure,
    airports[tripSlice.destinationCode]
      ? airports[tripSlice.destinationCode].cityName
      : tripSlice.destinationName,
    removeTimezone(tripSlice.departureTime)
  );

  return (
    <Box className="flight-summary-panel-root">
      <Box className="airline-details-with-title">
        <Typography className="card-header">
          <span className="bold">{type}</span>
          <span
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          ></span>
        </Typography>
        <Box className="airline-details">
          <Box className="airline-details-left-container">
            <Box className="flight-times-wrapper">
              <Typography variant="body1" className="flight-timespan">
                {`${dayjs(removeTimezone(tripSlice.departureTime)).format(
                  "h:mm A"
                )}`}
              </Typography>
              <Box className="flight-time-icon">
                <Icon name={IconName.B2BAirplaneIcon} />
                <Box className="flight-line" />
                <Box className="flight-line-circle" />
              </Box>
              <Typography variant="body1" className="flight-timespan">
                {`${dayjs(removeTimezone(tripSlice.arrivalTime)).format(
                  "h:mm A"
                )}`}
              </Typography>
            </Box>

            <Box className="card-airline-container">
              {uniqSegmentAirlines.map((airline, index) => (
                <>
                  {/* Only operating airlines should have a tooltip */}
                  <Tooltip
                    arrow
                    title={airline.tooltip}
                    key={`airline-tooltip-${index}-${airline.name}`}
                  >
                    <span>
                      <AirlineIcon airlineCode={airline.code} />
                    </span>
                  </Tooltip>
                  {/* Only first airline (marketing) should show name */}
                  {index === 0 && (
                    <Typography
                      variant="body2"
                      key={`airline-name-${index}-${airline.name}`}
                    >
                      {airline.name}
                    </Typography>
                  )}
                </>
              ))}
            </Box>
          </Box>
          <Box className="airline-details-right-container">
            <Typography variant="body1" className="flight-timespan">
              {formatInterval(
                dayjs(tripSlice.arrivalTime).diff(
                  dayjs(tripSlice.departureTime),
                  "minute",
                  true
                )
              )}
            </Typography>
            <Typography variant="body2">
              {getStopsString(tripSlice.stops)}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
