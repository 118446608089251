import { Cfar } from "redmond";
import { getTotalPriceText } from "halifax";
import {
  LodgingAvailabilityCheckInTime,
  LodgingAvailabilityCheckInTimeEnum,
} from "redmond/hotels-module/interfaces";
import dayjs from "dayjs";

export const BACK_TO_RESULTS = "Back to results";

export const getCfarSecondaryText = (cfarCancellation: Cfar) =>
  `Refund amount: ${getTotalPriceText({
    price: cfarCancellation.refundAmount.fiat,
  })}`;

export const SHOW_ALL_AMENITIES_TEXT = "More Amenities";

export const getEarnText = (
  earnRate: string | number,
  productDisplayName: string,
  currency: string
) => {
  const multiplier = currency.includes("cash") ? "%" : "x";
  return `Plus, earn <span class="font-bold">${earnRate}${multiplier} ${currency}</span> on hotels with your ${productDisplayName} account.`;
};

export const getMinNightStayBannerText = (minNightOfStay: number) => {
  return `This property requires a <strong>minimum ${minNightOfStay}-night</strong> stay to receive Premier Collection benefits. If you're staying for 1 night, you'll still earn 10X miles through Capital One Travel.`;
};

export const CHECK_IN_INSTRUCTIONS_TITLE =
  "Scheduled check-in and check-out time";

export const formattedCheckIn = (
  date: Date,
  time?: LodgingAvailabilityCheckInTime
) => {
  return `<strong>Check-in:</strong> ${dayjs(date).format(
    "dddd, MMMM D, YYYY"
  )}${
    time && time.CheckInTime == LodgingAvailabilityCheckInTimeEnum.Restricted
      ? " at " + dayjs(time.checkInTimeFrom, "HH:mm").format("h:mm A")
      : ""
  }`;
};

export const formattedCheckOut = (date: Date, time?: string) =>
  `<strong>Checkout:</strong> ${dayjs(date).format("dddd, MMMM D, YYYY")}${
    time ? " at " + dayjs(time, "HH:mm").format("h:mm A") : ""
  }`;
