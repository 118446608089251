import { actions, actionTypes } from "../actions";
import {
  ITripTerminus,
  IMonthBucket,
  ITimeRange,
  FareclassShelfBrandName,
  SliceStopCountFilter,
  IResult,
  ICategorizedResponse,
} from "redmond";
import { ITripTerminusCategory } from "../types";

export interface IFilterState {
  maxPriceFilter: number;
  fareclassOptionFilter: FareclassOptionFilter;
  stopsOption: SliceStopCountFilter;
}
export interface IPackagesSearchState extends IFilterState {
  openDatesModal: boolean;
  origin: ITripTerminus | null;
  originCategories: ITripTerminusCategory[] | null;
  originCategoriesLoading: boolean | null;
  destination: IResult | null;
  destinationCategories: ICategorizedResponse[] | null;
  destinationCategoriesLoading: boolean | null;
  awaitingRefetch: boolean;
  currency: string | null;
  calendarQueryOrigin: ITripTerminus | null;
  calendarQueryDestination: ITripTerminus | null;
  departureMonths: IMonthBucket[] | null;
  departureDate: Date | null;
  returnDate: Date | null;
  hasOriginAutocompleteError: boolean;
  hasDestinationAutocompleteError: boolean;
  adultsCount: number;
  children: number[];
  infants: { age: number; inSeat: boolean }[];
}

export type FareclassOptionFilter = {
  [key in FareclassShelfBrandName]: boolean;
};

export const initialFareclassOptionFilter: FareclassOptionFilter = {
  basic: false,
  standard: false,
  enhanced: false,
  premium: false,
  luxury: false,
};

export const noLCCFareclassOptionFilter: FareclassOptionFilter = {
  basic: false,
  standard: true,
  enhanced: true,
  premium: true,
  luxury: true,
};

export const INITIAL_MAX_PRICE = 0;
// number of minutes in a day = 60 min/hour * 24 hour = 1440 min
export const TIME_RANGE_MAX = 1439;

export const MAXIMUM_COUNT = 6;
export const MINIMUM_ADULT_COUNT = 1;

export const initialTimeRange: ITimeRange = {
  min: 0,
  // note: time measured in minutes
  max: TIME_RANGE_MAX,
};

export const initialFilterOptions: IFilterState = {
  stopsOption: SliceStopCountFilter.ANY_NUMBER,
  maxPriceFilter: 0,
  fareclassOptionFilter: initialFareclassOptionFilter,
};

export const initialState: IPackagesSearchState = {
  originCategories: [],
  destinationCategories: [],
  originCategoriesLoading: false,
  destinationCategoriesLoading: false,
  openDatesModal: false,
  origin: null,
  destination: null,
  awaitingRefetch: false,
  currency: null,
  calendarQueryOrigin: null,
  calendarQueryDestination: null,
  departureMonths: null,
  departureDate: null,
  returnDate: null,
  hasOriginAutocompleteError: false,
  hasDestinationAutocompleteError: false,
  adultsCount: 1,
  children: [],
  infants: [],
  ...initialFilterOptions,
};

export function reducer(
  state: IPackagesSearchState = initialState,
  action: actions.PackagesSearchActions
): IPackagesSearchState {
  switch (action.type) {
    case actionTypes.SET_OPEN_DATES_MODAL:
      return {
        ...state,
        openDatesModal: action.openDatesModal,
      };

    case actionTypes.SET_ORIGIN:
      const { origin } = action;
      return { ...state, origin, awaitingRefetch: true };

    case actionTypes.SET_ORIGIN_CATEGORIES:
      return {
        ...state,
        originCategories: action.originCategories,
        originCategoriesLoading: false,
        hasOriginAutocompleteError: false,
      };

    case actionTypes.FETCH_ORIGIN_CATEGORIES:
      return { ...state, originCategories: [], originCategoriesLoading: true };

    case actionTypes.SET_DESTINATION:
      const { destination } = action;
      return { ...state, destination, awaitingRefetch: true };

    case actionTypes.SET_DESTINATION_CATEGORIES:
      return {
        ...state,
        destinationCategories: action.destinationCategories,
        destinationCategoriesLoading: false,
        hasDestinationAutocompleteError: false,
      };

    case actionTypes.FETCH_DESTINATION_CATEGORIES:
      return {
        ...state,
        destinationCategories: [],
        destinationCategoriesLoading: true,
      };

    case actionTypes.FETCH_DEPARTURE_CALENDAR:
      return {
        ...state,
        currency: null,
        departureMonths: null,
        calendarQueryOrigin: action.origin,
        calendarQueryDestination: action.destination,
      };

    case actionTypes.SET_DEPARTURE_CALENDAR:
      return {
        ...state,
        currency:
          typeof action.currency === "undefined" ? null : action.currency,
        departureMonths:
          typeof action.departureMonths === "undefined"
            ? null
            : action.departureMonths,
      };

    case actionTypes.SET_DEPARTURE_DATE:
      return {
        ...state,
        departureDate: action.date ? action.date : null,
        awaitingRefetch: true,
      };

    case actionTypes.SET_RETURN_DATE:
      return {
        ...state,
        returnDate: action.date ? action.date : null,
        awaitingRefetch: true,
      };

    case actionTypes.SET_STOPS_OPTION:
      return { ...state, stopsOption: action.stopsOption };

    case actionTypes.SET_FARECLASS_OPTION_FILTER:
      return {
        ...state,
        fareclassOptionFilter: action.fareclassOptionFilter,
      };

    case actionTypes.SET_ORIGIN_AUTOCOMPLETE_ERROR: {
      return {
        ...state,
        hasOriginAutocompleteError: action.error,
        originCategoriesLoading: false,
      };
    }
    case actionTypes.SET_DESTINATION_AUTOCOMPLETE_ERROR: {
      return {
        ...state,
        hasDestinationAutocompleteError: action.error,
        destinationCategoriesLoading: false,
      };
    }
    case actionTypes.SET_TRAVELERS:
      return {
        ...state,
        adultsCount: action.travelers.adultsCount,
        children: action.travelers.children,
        infants: action.travelers.infants,
      };
    case actionTypes.SET_OPEN_DATES_MODAL:
      return {
        ...state,
        openDatesModal: action.openDatesModal,
      };

    default:
      return state;
  }
}

export * from "./selectors";
