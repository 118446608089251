export const CONTACT_INFO_TITLE = "Your contact information";
export const CONTACT_INFO_TITLE_MOBILE = "Contact information";
export const CONTACT_INFO_SUBTITLE =
  "This information is used to notify you about any updates or changes to your trip.";

export const CONTACT_INFO_NOTICE =
  "You must select at least 1 traveler and enter your contact information to continue.";

export const CONTINUE_TEXT = "Continue";
export const SAVING_TEXT = "Saving";
