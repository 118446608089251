import { AlgomerchTag } from "redmond";

export const scoreFactorTitles = {
  airline: "Airline",
  fareClass: "Fare Class",
  price: "Price",
  duration: "Duration",
  stops: "Stops",
};

type AlgomerchTitleMap = {
  [key in AlgomerchTag]: {
    title: string;
    description: string;
    factors: string;
  };
};
export const algomerchTitles: AlgomerchTitleMap = {
  [AlgomerchTag.Cheapest]: {
    title: "Best Price",
    description: "Travel more (for less).",
    factors:
      "We sift through hundreds of <strong>flight prices</strong> to find the absolute best price.",
  },
  [AlgomerchTag.BestFlight]: {
    title: "Best Overall",
    description: "Let data decide.",
    factors:
      "<strong>Price</strong>, <strong>quality</strong>, and <strong>total flight time</strong> are considered to find the flight with the highest flight score and best overall value.",
  },
  [AlgomerchTag.Fastest]: {
    title: "Fastest",
    description: "Get from A to B without losing any Zzz’s.",
    factors:
      "<strong>Duration</strong> and <strong>number of stops</strong> are considered to identify the fastest flight.",
  },
  [AlgomerchTag.BestQuality]: {
    title: "Best Quality",
    description: "Sit back. Stretch out.",
    factors:
      "<strong>Airline</strong>, <strong>fare class</strong>, and <strong>stops</strong> are considered to find the flight with the comfiest flying experience. ",
  },
};

export const ALGOMERCH_INFO_TITLE = "How are flights scored?";
