export const TITLE = "Passenger Validation";
export const SUBTITLE =
  "Enter Passport details for all travelers. Ensure all information matches your Travel ID.";
export const PASSENGER_NAME_PREFIX = "•";

export const getTitle = (numTravelers: number, index: number) =>
  `${TITLE} (${index + 1} of ${numTravelers})`;

export const getPassengerName = (name: string, travelerCount?: number) =>
  `${
    !!travelerCount ? `Traveler ${travelerCount}:` : PASSENGER_NAME_PREFIX
  } ${name}`;

export const PASSPORT_MODAL_ENHANCEMENT_TITLE =
  "This airline requires that you add your passport details to complete your booking.";

export const PASSPORT_MODAL_ENHANCEMENT_SUBTITLE =
  "Ensure all information matches your travel ID.";
