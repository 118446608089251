// Getters
export const FETCH_TRAVEL_WALLET_DETAILS =
  "packagesTravelWallet/FETCH_TRAVEL_WALLET_DETAILS";
export type FETCH_TRAVEL_WALLET_DETAILS = typeof FETCH_TRAVEL_WALLET_DETAILS;

// Setters
export const SET_TRAVEL_WALLET_OFFERS =
  "packagesTravelWallet/SET_TRAVEL_WALLET_OFFERS";
export type SET_TRAVEL_WALLET_OFFERS = typeof SET_TRAVEL_WALLET_OFFERS;

export const SET_FETCH_TRAVEL_WALLET_DETAILS_CALL_STATE_FAILED =
  "packagesTravelWallet/SET_FETCH_TRAVEL_WALLET_DETAILS_CALL_STATE_FAILED";
export type SET_FETCH_TRAVEL_WALLET_DETAILS_CALL_STATE_FAILED =
  typeof SET_FETCH_TRAVEL_WALLET_DETAILS_CALL_STATE_FAILED;

export const SET_TRAVEL_WALLET_CREDIT =
  "packagesTravelWallet/SET_TRAVEL_WALLET_CREDIT";
export type SET_TRAVEL_WALLET_CREDIT = typeof SET_TRAVEL_WALLET_CREDIT;

export const FETCH_TRAVEL_WALLET_CREDIT_HISTORY =
  "packagesTravelWallet/FETCH_TRAVEL_WALLET_CREDIT_HISTORY";

export type FETCH_TRAVEL_WALLET_CREDIT_HISTORY =
  typeof FETCH_TRAVEL_WALLET_CREDIT_HISTORY;

export const SET_FETCH_TRAVEL_WALLET_CREDIT_HISTORY_CALL_STATE_SUCCESS =
  "packagesTravelWallet/SET_FETCH_TRAVEL_WALLET_CREDIT_HISTORY_CALL_STATE_SUCCESS";

export type SET_FETCH_TRAVEL_WALLET_CREDIT_HISTORY_CALL_STATE_SUCCESS =
  typeof SET_FETCH_TRAVEL_WALLET_CREDIT_HISTORY_CALL_STATE_SUCCESS;

export const SET_FETCH_TRAVEL_WALLET_CREDIT_HISTORY_CALL_STATE_FAILURE =
  "packagesTravelWallet/SET_FETCH_TRAVEL_WALLET_CREDIT_HISTORY_CALL_STATE_FAILURE";

export type SET_FETCH_TRAVEL_WALLET_CREDIT_HISTORY_CALL_STATE_FAILURE =
  typeof SET_FETCH_TRAVEL_WALLET_CREDIT_HISTORY_CALL_STATE_FAILURE;

export const SET_TRAVEL_WALLET_CREDIT_HISTORY =
  "packagesTravelWallet/SET_TRAVEL_WALLET_CREDIT_HISTORY";

export type SET_TRAVEL_WALLET_CREDIT_HISTORY =
  typeof SET_TRAVEL_WALLET_CREDIT_HISTORY;
