import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { RouteComponentProps } from "react-router";
import {
  HotelShopRoomTypePickerVariant,
  TrackingEventControlType,
} from "halifax";
import { IStoreState } from "../../../../reducers/types";
import { getSearchedNightCount } from "../../../availability/reducer";
import {
  getRewardsAccountWithLargestEarnForBanner,
  getSelectedAccountReferenceIdIfRedemptionEnabled,
} from "../../../rewards/reducer";
import {
  getAdultsCount,
  getChildren,
  getDepartureDate,
  getFareclassOptionFilter,
  getInfants,
  getOrigin,
  getReturnDate,
  getStopsOption,
} from "../../../search/reducer";

import { fetchTravelWalletDetails } from "../../../travel-wallet/actions/actions";
import { PackageHotelShopCallState } from "../../reducer/state";
import {
  getCheapestLodgingRoomProduct,
  getHotelShopCallState,
  getPackageSelectedLodging,
  hasHotelShopFailed,
} from "../../reducer";
import { DesktopShop } from "./component";
import { RoomInfoProductsWithTransformedIndexes } from "redmond";

const mapStateToProps = (state: IStoreState) => ({
  fromDate: getDepartureDate(state),
  untilDate: getReturnDate(state),
  lodging: getPackageSelectedLodging(state),
  cheapestProduct: getCheapestLodgingRoomProduct(state),
  hotelShopCallInProgress:
    getHotelShopCallState(state) === PackageHotelShopCallState.InProcess,
  adultsCount: getAdultsCount(state),
  selectedAccountReferenceId:
    getSelectedAccountReferenceIdIfRedemptionEnabled(state),
  largestValueAccount: getRewardsAccountWithLargestEarnForBanner(state),
  hotelShopCallState: getHotelShopCallState(state),
  nightCount: getSearchedNightCount(state),
  hasFailed: hasHotelShopFailed(state),
  origin: getOrigin(state),
  infants: getInfants(state),
  children: getChildren(state),
  stopsOption: getStopsOption(state),
  fareClassOptionFilter: getFareclassOptionFilter(state),
});

export const mapDispatchToProps = {
  fetchTravelWalletDetails: fetchTravelWalletDetails,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type DesktopShopConnectorProps = ConnectedProps<typeof connector>;

export interface IDesktopShopProps
  extends DesktopShopConnectorProps,
    RouteComponentProps {
  roomInfoProductsType?: HotelShopRoomTypePickerVariant;
  handleReadyToRedirect?: (
    trackingEventControl?: TrackingEventControlType
  ) => void;
  setRoomInfoProduct?: (
    roomInfoProduct: RoomInfoProductsWithTransformedIndexes
  ) => void;
  setRoomProductIndex?: (roomProductIndex: number) => void;
}

export const ConnectedDesktopShop = withRouter(connector(DesktopShop));
