import React from "react";
import { MobileHotelPhotoModalConnectorProps } from "./container";

import "./styles.scss";
import {
  ActionLink,
  CloseButtonIcon,
  getImagesArray,
  HotelAddress,
  HotelName,
  MobilePopoverCard,
  PhotoLayout,
} from "halifax";
import clsx from "clsx";
import { MobileHotelShopRoomTypePickerPanel } from "../MobileHotelShopRoomTypePickerPanel";

export interface MobileHotelPhotoModalProps
  extends MobileHotelPhotoModalConnectorProps {
  openModal: boolean;
  setOpenModal: (open: boolean) => void;
}

export const MobileHotelPhotoModal = (props: MobileHotelPhotoModalProps) => {
  const { lodging, openModal, setOpenModal } = props;
  const [imagesArray, setImagesArray] = React.useState<string[]>([]);

  React.useEffect(() => {
    getImagesArray(lodging?.lodging.media ?? [], setImagesArray);
  }, [lodging, setImagesArray]);

  const filteredImagesArray = React.useMemo(
    () =>
      // The filter method is used to remove empty items in imagesArray
      imagesArray.filter((imageTuple) => !!imageTuple),
    [imagesArray]
  );

  return (
    <MobilePopoverCard
      className="mobile-hotel-photo-modal-popup"
      open={openModal}
      onClose={(event: React.MouseEvent) => {
        event.stopPropagation();
        setOpenModal(false);
      }}
      topRightButton={
        <ActionLink
          className={clsx("mobile-hotel-photo-modal-popup-button", "close")}
          content={<CloseButtonIcon />}
          label="Close"
          onClick={() => setOpenModal(false)}
        />
      }
      fullScreen
    >
      <div className="mobile-hotel-photo-modal-header">
        <HotelName name={lodging?.lodging?.name} />
        <HotelAddress address={lodging?.lodging.address} />
      </div>
      <div
        id="mobile-photo-gallery"
        className="mobile-hotel-photo-modal-container"
      >
        <PhotoLayout
          imgUrls={filteredImagesArray}
          scrollableTarget="mobile-photo-gallery"
        />
      </div>
      <MobileHotelShopRoomTypePickerPanel onContinueClicked={() => {}} />
    </MobilePopoverCard>
  );
};
