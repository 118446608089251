import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../../reducers/types";
import { actions } from "../../actions";

import {
  getDistanceFromLocation,
  getDistanceFromLocationCategories,
  getDistanceFromLocationCategoriesLoading,
} from "../../reducer/selectors";
import { MobileDistanceFromLocationSearchModal } from "./component";

const mapDispatchToProps = {
  setLocation: actions.setDistanceFromLocation,
  fetchLocationCategories: actions.fetchDistanceFromLocationCategories,
};
const mapStateToProps = (state: IStoreState) => ({
  location: getDistanceFromLocation(state),
  locationCategories: getDistanceFromLocationCategories(state),
  loading: !!getDistanceFromLocationCategoriesLoading(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MobileDistanceFromLocationSearchModalConnectorProps =
  ConnectedProps<typeof connector>;

export const ConnectedMobileDistanceFromLocationSearchModal = connector(
  MobileDistanceFromLocationSearchModal
);
