import { ConnectedProps, connect } from "react-redux";
// import { IStoreState } from "../../reducers/types";
import { withRouter } from "react-router";
import { PackagesHotelsAvailability } from "./component";
import { fetchTravelWalletDetails } from "../travel-wallet/actions/actions";
import {
  fetchInitialPackagesAvailability,
  stopFetchMorePackagesAvailability,
} from "./actions/actions";
import { IStoreState } from "../../reducers/types";
import {
  getPackageAvailabilitySearchDestinationLocationResult,
  getPackagesAvailabilityCallState,
  getPackagesAvailabilityDepartureDate,
  getPackagesAvailabilityReturnDate,
} from "./reducer";
import { getRewardsAccountWithLargestEarnForBanner } from "../rewards/reducer";

const mapStateToProps = (state: IStoreState) => {
  return {
    packagesAvailabilityCallState: getPackagesAvailabilityCallState(state),
    largestValueAccount: getRewardsAccountWithLargestEarnForBanner(state),
    location: getPackageAvailabilitySearchDestinationLocationResult(state),
    fromDate: getPackagesAvailabilityDepartureDate(state),
    untilDate: getPackagesAvailabilityReturnDate(state),
  };
};

const mapDispatchToProps = {
  fetchTravelWalletDetails,
  fetchInitialPackagesAvailability,
  stopFetchMorePackagesAvailability,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PackagesHotelsAvailabilityConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedPackagesHotelsAvailability = withRouter(
  connector(PackagesHotelsAvailability)
);
