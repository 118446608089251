import clsx from "clsx";
import { GenericInfoPopup, Icon, IconName, useDeviceTypes } from "halifax";
import React, { useState } from "react";
import { RouteComponentProps } from "react-router";
import {
  MODAL_ALERT,
  ModalButtonType,
  ModalCategoryType,
  ModalScreens,
} from "redmond";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";

import { PATH_HOME } from "../../../../utils/paths";
import { PackageHotelShopCallError } from "../../reducer/state";
import { ShopErrorModalConnectorProps } from "./container";
import "./styles.scss";
import * as textConstants from "./textConstants";

export interface IAvailabilityErrorModalProps
  extends ShopErrorModalConnectorProps,
    RouteComponentProps {
  returnToAvailability: () => void;
}

export const ShopErrorModal = (props: IAvailabilityErrorModalProps) => {
  const {
    hasFailed,
    history,
    fetchPackageHotelShop,
    missingRoomProductInfo,
    shopError,
    returnToAvailability,
  } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { matchesMobile } = useDeviceTypes();

  const modalEventProperties = {
    type: "hotels_shop_error",
    primary_button: textConstants.SEARCH_AGAIN_BUTTON,
    secondary_button: textConstants.GO_BACK_BUTTON,
    screen: ModalScreens.HOTELS_SHOP,
    category: ModalCategoryType.TROUBLE,
  };

  React.useEffect(() => {
    if (hasFailed) {
      setIsOpen(true);
      trackEvent({
        eventName: MODAL_ALERT,
        properties: { ...modalEventProperties },
      });
    }
  }, [hasFailed]);

  const hotelUnavailable =
    shopError === PackageHotelShopCallError.NoAvailability;

  const buttons = hotelUnavailable
    ? [
        {
          buttonText: textConstants.BACK_TO_RESULTS_BUTTON,
          buttonWrapperClassName: clsx(
            "availability-error-modal-button",
            "primary"
          ),
          onClick: () => {
            setIsOpen(false);
            trackEvent({
              eventName: MODAL_ALERT,
              properties: {
                ...modalEventProperties,
                button_choice: ModalButtonType.SECONDARY,
              },
            });
            returnToAvailability();
          },
        },
      ]
    : [
        {
          buttonText: textConstants.GO_BACK_BUTTON,
          buttonWrapperClassName: clsx(
            "availability-error-modal-button",
            "secondary"
          ),
          onClick: () => {
            setIsOpen(false);
            trackEvent({
              eventName: MODAL_ALERT,
              properties: {
                ...modalEventProperties,
                button_choice: ModalButtonType.SECONDARY,
              },
            });
            history.push(PATH_HOME);
          },
        },
        {
          buttonText: textConstants.SEARCH_AGAIN_BUTTON,
          buttonWrapperClassName: clsx(
            "availability-error-modal-button",
            "primary"
          ),
          onClick: () => {
            setIsOpen(false);
            trackEvent({
              eventName: MODAL_ALERT,
              properties: {
                ...modalEventProperties,
                button_choice: ModalButtonType.SECONDARY,
              },
            });
            fetchPackageHotelShop(history);
          },
        },
      ];

  return (
    <GenericInfoPopup
      className="hotel-shop-error-modal-root"
      open={isOpen}
      image={<Icon className="error-icon" name={IconName.ErrorState} />}
      title={
        hotelUnavailable
          ? textConstants.UNAVAILABLE_DATES_ERROR_TITLE
          : missingRoomProductInfo
          ? textConstants.SHOP_MISSING_ROOMS_ERROR
          : textConstants.SHOP_ERROR_TITLE
      }
      subtitle={
        missingRoomProductInfo || hotelUnavailable
          ? ""
          : textConstants.SHOP_ERROR_SUBTITLE
      }
      buttons={buttons}
      isMobile={matchesMobile}
    />
  );
};
