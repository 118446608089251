import axios from "axios";
import { EarnRequestV2, EarnResponseV2 } from "redmond";

import { config } from "../../config";

import { rewardsApiPrefix } from "../paths";

const END_POINT: string = `${rewardsApiPrefix}/convert/productToEarn`;

export const productToEarnV2 = (
  request: EarnRequestV2
): Promise<EarnResponseV2> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await axios.put(END_POINT, request, {
        baseURL: config.baseURL,
      });

      resolve(res.data);
    } catch (e) {
      reject(e);
    }
  });
