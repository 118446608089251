import { Box } from "@material-ui/core";
import { IconName, RadioDropdown } from "halifax";
import React from "react";
import { FlightSortOption, ISortOptions } from "redmond";
import { defaultSortOptionsToDisplay } from "../../../../utils/sortAndFilter";
import { Icon } from "halifax";
import clsx from "clsx";

export interface ISortOptionSelectionProps {
  sortOption: FlightSortOption;
  setSortOption: (option: FlightSortOption) => void;
  showDropdownContentOnly?: boolean;
  isFlightListOptimizationExperiment?: boolean;
  icon?: IconName;
  title?: string;
  radioLabelPlacement?: "start" | "end";
  sortOptions?: ISortOptions[];
}

export const SortOptionSelection = (props: ISortOptionSelectionProps) => {
  const {
    sortOption,
    setSortOption,
    showDropdownContentOnly,
    icon,
    title,
    radioLabelPlacement,
    sortOptions = defaultSortOptionsToDisplay,
  } = props;

  const getDropdownLabel = () => {
    const label = sortOptions.find(
      (option) => option.value === sortOption
    )?.label;
    const [shortenedLabel] = label?.split(" ") || [];

    return (
      <>
        <strong>Sort:</strong> {shortenedLabel}
      </>
    );
  };

  return (
    <Box className="pkg-flight-shop-sort-container" tabIndex={0}>
      {!!title && showDropdownContentOnly && (
        <Box className="header-container">
          {icon && <Icon name={icon} />} {title}
        </Box>
      )}
      <RadioDropdown
        buttonClassName={clsx(
          "desktop-availability-sort-button",
          "b2b-shop-filter",
          "flight-availability-sort-button",

          "filter-experiment"
        )}
        dropdownLabel={getDropdownLabel()}
        options={sortOptions.map((option) => {
          return {
            value: option.value,
            label: option.label,
          };
        })}
        setOption={(option) => setSortOption(option as FlightSortOption)}
        selected={sortOption}
        groupAriaLabel={"Choose a Sort Option"}
        buttonAriaLabel={"Change Sort Option"}
        popoverClassName={clsx(
          "b2b",
          "flight-availability-sort-popover",
          "filter-experiment"
        )}
        showDropdownContentOnly={showDropdownContentOnly}
        dropdownIcon={<Icon name={IconName.Sort} />}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        radioLabelPlacement={radioLabelPlacement}
      />
    </Box>
  );
};
