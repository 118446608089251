import React, { useState } from "react";
import { Box, TextField, Typography } from "@material-ui/core";
import {
  IconName,
  MobileAutocompleteOptions,
  Icon,
  ActionLink,
  CloseButtonIcon,
  MobilePopoverCard,
  ActionButton,
} from "halifax";
import clsx from "clsx";

import * as textConstants from "./textConstants";
import { MobileDistanceFromLocationSearchModalConnectorProps } from "./container";
import { IResult } from "redmond";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./styles.scss";

export interface IMobileDistanceFromLocationSearchModalProps
  extends MobileDistanceFromLocationSearchModalConnectorProps {
  openLocationModal: boolean;
  setOpenLocationModal: (val: boolean) => void;
  className?: string;
}
export const MobileDistanceFromLocationSearchModal = (
  props: IMobileDistanceFromLocationSearchModalProps
) => {
  const {
    location,
    loading,
    setLocation,
    fetchLocationCategories,
    locationCategories,
    openLocationModal,
    setOpenLocationModal,
    className,
  } = props;

  const [locationSearchString, setLocationSearchString] = useState<string>("");
  const [localLocation, setLocalLocation] = React.useState<IResult | null>(
    null
  );

  React.useEffect(() => {
    location
      ? setLocationSearchString(location?.label)
      : locationSearchString && setLocationSearchString("");
    location
      ? setLocalLocation(location)
      : localLocation && setLocalLocation(null);
  }, [location]);

  const onLocationSearch = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setLocationSearchString(event.target.value);
    if (event.target.value) {
      fetchLocationCategories(event.target.value);
    }
  };

  const onLocationSelected = (value: any) => {
    setLocationSearchString(value.label);
    setLocalLocation && setLocalLocation(value);
  };

  const onClearLocationField = () => {
    setLocalLocation(null);
    setLocationSearchString("");
    fetchLocationCategories("");
  };

  const handleClose = () => {
    setLocationSearchString(location?.label ?? "");
    setLocalLocation(location);

    setOpenLocationModal(false);
  };

  const handleContinue = () => {
    setLocation(localLocation);
    handleClose();
  };

  const MobileSearchPopoverHeader = () => {
    return (
      <Box className="modal-header-container">
        <Typography className="header-title">
          {textConstants.MOBILE_DISTANCE_FROM_SEARCH_MODAL_HEADER}
        </Typography>
        <ActionLink
          className="modal-close-button"
          onClick={handleClose}
          content={<CloseButtonIcon className="close-button-icon" />}
          label="Close"
          showTappableArea
        />
      </Box>
    );
  };
  return (
    <MobilePopoverCard
      open={openLocationModal}
      className={clsx("mobile-distance-from-location-search-popup", className)}
      onClose={handleClose}
      headerElement={<MobileSearchPopoverHeader />}
    >
      <Box className="mobile-distance-from-location-search-modal-content">
        <Box className="mobile-distance-from-location-search-input-field">
          <Icon name={IconName.MagnifyingGlass} />
          <TextField
            className={clsx("origin-auto-complete", "mobile")}
            onChange={(value) => onLocationSearch(value)}
            value={locationSearchString}
            label={textConstants.AUTOCOMPLETE_LABEL}
            InputProps={{
              disableUnderline: true,
            }}
            autoFocus
          />
          {/* Note: Instead of conditionally rendering this icon component, a className of 'hidden' is conditionally used so it can take the style "visibility:hidden"
            This style will allocate space for the icon even when it's not shown - this prevents the sibling elements from changing when it's shown or hidden */}
          <FontAwesomeIcon
            className={clsx("icon", { hidden: !locationSearchString })}
            icon={faTimesCircle}
            onClick={onClearLocationField}
          />
        </Box>
        <MobileAutocompleteOptions
          value={null}
          valueCategories={locationCategories}
          setValue={(value: any) => onLocationSelected(value)}
          loading={loading}
          loadingText={textConstants.LOADING_TEXT}
          hideSidePadding
        />

        {localLocation && (
          <ActionButton
            className={clsx("mobile-autocomplete-continue-button", "b2b")}
            message={"Continue"}
            onClick={handleContinue}
          />
        )}
      </Box>
    </MobilePopoverCard>
  );
};
