import React from "react";
import { Box, Link, Typography } from "@material-ui/core";
import { ICoordinates, Lodging } from "redmond";
import {
  HotelName,
  HotelAddress,
  HotelMapPin,
  useDeviceTypes,
  ActionButton,
} from "halifax";
import { GoogleMap, OverlayView } from "@react-google-maps/api";
import clsx from "clsx";

import { convertICoordinatesToGoogleMapCoords } from "../../../availability/components/AvailabilityMap/googleMapsHelpers";
import "./styles.scss";

import { SHOW_ON_MAP } from "./textConstants";

export interface IHotelShopSmallMapProps {
  lodging: Lodging;
  setShowExpandedHotelMap?: (arg: boolean) => void;
}

export const HotelShopSmallMap = (props: IHotelShopSmallMapProps) => {
  const { lodging, setShowExpandedHotelMap } = props;
  const { matchesMobile } = useDeviceTypes();

  const centerWithOffsets: ICoordinates = {
    lat: lodging.lodging.location.coordinates.lat - 0.00015,
    lon: lodging.lodging.location.coordinates.lon - 0.003,
  };

  const center = convertICoordinatesToGoogleMapCoords(centerWithOffsets);

  return (
    <Box
      className={clsx("small-hotel-map-container", "search-distance-from", {
        mobile: matchesMobile,
      })}
    >
      <GoogleMap
        id="small-hotel-map"
        mapContainerStyle={{
          height: "100%",
          width: "100%",
          borderRadius: "8px",
        }}
        options={{
          clickableIcons: false,
          zoomControl: false,
          streetViewControl: false,
          mapTypeControl: false,
          draggable: false,
          fullscreenControl: false,
          draggableCursor: "pointer",
        }}
        center={center}
        zoom={16}
      >
        <SmallHotelMapContent {...props}></SmallHotelMapContent>
      </GoogleMap>

      {setShowExpandedHotelMap ? (
        <ActionButton
          className={"show-map-button b2b"}
          onClick={(event) => {
            event?.stopPropagation();
            setShowExpandedHotelMap(true);
          }}
          message={
            <Typography className="toggle-map-button-text">
              {SHOW_ON_MAP}
            </Typography>
          }
        />
      ) : (
        <Link component="button" className="small-hotel-map-label">
          <HotelName name={lodging?.lodging?.name} />
          <HotelAddress address={lodging.lodging.address} showFullAddress />
        </Link>
      )}
    </Box>
  );
};

const SmallHotelMapContent = (props: IHotelShopSmallMapProps) => {
  const { lodging } = props;
  const center = convertICoordinatesToGoogleMapCoords(
    lodging.lodging.location.coordinates
  );

  return (
    <>
      <OverlayView
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        position={center}
      >
        <HotelMapPin />
      </OverlayView>
    </>
  );
};
