import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import {
  setFareclassOptionFilter,
  setStopsOption,
} from "../../../search/actions/actions";
import {
  getStopsOption,
  getHasSetStopsOption,
  // getDestination,
  getOrigin,
  getDepartureDate,
  getFareclassOptionFilter,
} from "../../../search/reducer";
import { IStoreState } from "../../../../reducers/types";
import { FlightShopAllFiltersModal } from "./component";
import {
  setAirlineFilter,
  setAirportFilter,
  setDurationFilter,
  setFlightNumberFilter,
  setMaxPriceFilter,
  setOutboundArrivalTimeRange,
  setOutboundDepartureTimeRange,
  setReturnArrivalTimeRange,
  setReturnDepartureTimeRange,
  setSortOption,
} from "../../actions/actions";
import {
  getAirlineFilter,
  getHasSetAirlineFilter,
  allFlightShopFilterSelector,
  getHasSetOutboundTimeRange,
  getHasSetReturnTimeRange,
  getOutboundArrivalTimeRange,
  getOutboundDepartureTimeRange,
  getReturnArrivalTimeRange,
  getReturnDepartureTimeRange,
  getMinMaxFlightDurations,
  filteredFlightsSelector,
  getDurationFilter,
  getMaxPriceFilter,
  flightsSelector,
  getAirportFilter,
  getFlightNumberFilter,
  sortOptionSelector,
} from "../../reducer";
import dayjs from "dayjs";

const mapStateToProps = (state: IStoreState) => {
  const hasSetTimeRange =
    getHasSetOutboundTimeRange(state) || getHasSetReturnTimeRange(state);
  const { minDuration = 0, maxDuration = 0 } = getMinMaxFlightDurations(state);
  const origin = getOrigin(state);
  // const destination = getDestination(state);
  const airportMap = flightsSelector(state)?.airports;
  const originAirport =
    airportMap && origin ? airportMap[origin?.id.code.code] : undefined;
  // const destinationAirport =
  //   airportMap && destination
  //     ? airportMap[destination?.id.code.code]
  //     : undefined;

  const departureDate = getDepartureDate(state);
  const dayjsDate = departureDate ? dayjs(departureDate) : null;
  const departureDateString = dayjsDate ? dayjsDate.format("ddd, MMM D") : "";

  return {
    sortOption: sortOptionSelector(state),
    stopsOption: getStopsOption(state),
    hasSetStopsOption: getHasSetStopsOption(state),
    airlineFilter: getAirlineFilter(state),
    hasSetAirlineFilter: getHasSetAirlineFilter(state),
    flightShopFilters: allFlightShopFilterSelector(state),
    outboundDepartureTimeRange: getOutboundDepartureTimeRange(state),
    outboundArrivalTimeRange: getOutboundArrivalTimeRange(state),
    returnDepartureTimeRange: getReturnDepartureTimeRange(state),
    returnArrivalTimeRange: getReturnArrivalTimeRange(state),
    hasSetTimeRange,
    durationFilter: getDurationFilter(state),
    minDuration,
    maxDuration,
    flightCount: filteredFlightsSelector(state).length,
    maxPriceFilter: getMaxPriceFilter(state),
    airportFilter: getAirportFilter(state),
    flightNumberFilter: getFlightNumberFilter(state),
    origin,
    originAirport,
    departureDateString,
    fareclassOptionFilter: getFareclassOptionFilter(state),
  };
};

export const mapDispatchToProps = {
  setSortOption,
  setStopsOption,
  setAirlineFilter,
  setOutboundDepartureTimeRange,
  setOutboundArrivalTimeRange,
  setReturnDepartureTimeRange,
  setReturnArrivalTimeRange,
  setDurationFilter,
  setMaxPriceFilter,
  setAirportFilter,
  setFlightNumberFilter,
  setFareclassOptionFilter,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type FlightShopAllFiltersModalConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedFlightShopAllFiltersModal = withRouter(
  connector(FlightShopAllFiltersModal)
);
