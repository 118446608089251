import { FlightSortOption, ISortOptions } from "redmond";

export enum FlightShopSortAndFilterType {
  Sort,
  Fareclass,
  NumberOfStops,
  Airline,
  OutboundTimes,
  MaxPrice,
  Airport,
  FlightNumber,
  Duration,
}

export const defaultFiltersToDisplay: FlightShopSortAndFilterType[] = [
  FlightShopSortAndFilterType.Sort,
  FlightShopSortAndFilterType.NumberOfStops,
  FlightShopSortAndFilterType.Airline,
  FlightShopSortAndFilterType.OutboundTimes,
  FlightShopSortAndFilterType.MaxPrice,
  FlightShopSortAndFilterType.Duration,
  FlightShopSortAndFilterType.Airport,
  FlightShopSortAndFilterType.FlightNumber,
];

export const airCXV4FiltersToDisplay: FlightShopSortAndFilterType[] = [
  FlightShopSortAndFilterType.Sort,
  FlightShopSortAndFilterType.Fareclass,
  FlightShopSortAndFilterType.NumberOfStops,
  FlightShopSortAndFilterType.Airline,
  FlightShopSortAndFilterType.OutboundTimes,
  FlightShopSortAndFilterType.MaxPrice,
  FlightShopSortAndFilterType.Duration,
  FlightShopSortAndFilterType.Airport,
  FlightShopSortAndFilterType.FlightNumber,
];

export const filtersWithoutMaxPrice: FlightShopSortAndFilterType[] =
  defaultFiltersToDisplay.filter(
    (f) => f !== FlightShopSortAndFilterType.MaxPrice
  );

export const defaultSortOptionsToDisplay: ISortOptions[] = [
  {
    label: "Recommended",
    value: FlightSortOption.FareScore,
  },
  {
    label: "Price (Low to High)",
    value: FlightSortOption.Price,
  },
  {
    label: "Depart (Earliest to Latest)",
    value: FlightSortOption.DepartureTime,
  },
  {
    label: "Arrive (Earliest to Latest)",
    value: FlightSortOption.ArrivalTime,
  },
  {
    label: "Stops (Least to Most)",
    value: FlightSortOption.Stops,
  },
  {
    label: "Duration (Shortest to Longest)",
    value: FlightSortOption.Duration,
  },
];

export const airCXV3SortOptionsToDisplay: ISortOptions[] = [
  {
    label: "Recommended",
    value: FlightSortOption.FareScore,
  },
  {
    label: "Price (Low to High)",
    value: FlightSortOption.Price,
  },
  {
    label: "Stops (Least to Most)",
    value: FlightSortOption.Stops,
  },
  {
    label: "Duration (Shortest to Longest)",
    value: FlightSortOption.Duration,
  },
  {
    label: "Arrive (Earliest to Latest)",
    value: FlightSortOption.ArrivalTime,
  },
  {
    label: "Depart (Earliest to Latest)",
    value: FlightSortOption.DepartureTime,
  },
];

export const sortOptionsWithoutPrice: ISortOptions[] =
  defaultSortOptionsToDisplay.filter(
    (sortOption) => sortOption.value !== FlightSortOption.Price
  );
