import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { isCorpTenant } from "@capone/common";
import { IStoreState } from "../../../../reducers/types";
import {
  getRewardsAccountWithLargestEarnForBanner,
  getSelectedAccountReferenceIdIfRedemptionEnabled,
  getUserIsPrimaryCardHolder,
} from "../../../rewards/reducer";
import {
  getDepartureDate,
  getNightCount,
  getReturnDate,
} from "../../../search/reducer";
import {
  getHotelShopChosenProduct,
  getHotelShopChosenProductIndex,
  getHotelShopChosenRoomInfo,
  getHotelShopChosenRoomInfoIndex,
  getPackageLodgingRoomInfoProducts,
  getPackageRatesById,
  getPackageSelectedLodging,
} from "../../reducer";
import { MobileRoomPicker } from "./component";
import { actions } from "../../actions";
import { config } from "../../../../api/config";
import { getTravelersCount } from "../../../availability/reducer";

const mapStateToProps = (state: IStoreState) => {
  return {
    checkinDate: getDepartureDate(state),
    checkoutDate: getReturnDate(state),
    roomInfoProducts: getPackageLodgingRoomInfoProducts(state),
    chosenRoomInfoIndex: getHotelShopChosenRoomInfoIndex(state),
    chosenRoomInfo: getHotelShopChosenRoomInfo(state),
    chosenProductIndex: getHotelShopChosenProductIndex(state),
    chosenProduct: getHotelShopChosenProduct(state),
    selectedLodging: getPackageSelectedLodging(state),
    nightCount: getNightCount(state),
    rewardsKey:
      getSelectedAccountReferenceIdIfRedemptionEnabled(state) ?? undefined,

    largestValueAccount: getRewardsAccountWithLargestEarnForBanner(state),

    canEarnRewards:
      !isCorpTenant(config.TENANT) || getUserIsPrimaryCardHolder(state),
    packagesByRateId: getPackageRatesById(state),
    travelersCount: getTravelersCount(state),
  };
};

export const mapDispatchToProps = {
  selectRoomType: actions.selectRoomType,
  // fetchHotelShop: actions.fetchHotelShop,
  setSelectedRoomId: actions.setSelectedRoomId,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MobileRoomPickerConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedMobileRoomPicker = withRouter(
  connector(MobileRoomPicker)
);
