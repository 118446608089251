import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../../reducers/types";
import { getSelectedAccountReferenceIdIfRedemptionEnabled } from "../../../rewards/reducer";
import { getNightCount } from "../../../search/reducer";

import {
  getCheapestLodgingRoomProduct,
  getPackageSelectedLodging,
  getPackageRatesById,
} from "../../reducer/selectors";
import { DesktopHotelPhotoModal } from "./component";
import { getTravelersCount } from "../../../availability/reducer";

const mapStateToProps = (state: IStoreState) => {
  return {
    lodging: getPackageSelectedLodging(state) || undefined,
    roomProduct: getCheapestLodgingRoomProduct(state),
    rewardsKey:
      getSelectedAccountReferenceIdIfRedemptionEnabled(state) || undefined,
    nightCount: getNightCount(state),
    packageRatesById: getPackageRatesById(state),
    travelersCount: getTravelersCount(state),
  };
};

export const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type DesktopHotelPhotoModalConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedDesktopHotelPhotoModal = connector(
  DesktopHotelPhotoModal
);
