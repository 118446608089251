import { Box } from "@material-ui/core";
import { ActionButton, Header, HotelName, HotelStarRating } from "halifax";
import React from "react";
import { RewardsAccountSelection } from "../../../rewards/components";
import { IDesktopRewardsHeaderProps } from "./container";
import "./styles.scss";
import * as textConstants from "./textConstants";
import { goToAvailability } from "../../utils/queryStringHelpers";
import { TravelWalletDrawer } from "../../../travel-wallet/components";
export const DesktopRewardsHeader = (props: IDesktopRewardsHeaderProps) => {
  const {
    history,
    lodging,
    fromDate,
    untilDate,
    adultsCount,
    children,
    hotelShopCallInProgress,
    origin,
    infants,
    stopsOption,
    fareClassOptionFilter,
  } = props;

  const returnToAvailability = (
    prevSuccessHotelFromDate?: Date | null,
    prevSuccessHotelUntilDate?: Date | null
  ) => {
    goToAvailability({
      history,
      origin,
      lodging,
      fromDate,
      untilDate,
      adultsCount,
      children,
      infants,
      stopsOption,
      prevSuccessHotelFromDate,
      prevSuccessHotelUntilDate,
      fareClassOptionFilter,
    });
  };

  const LeftContent = () => {
    return (
      <Box className="desktop-hotel-shop-basic-info-contents">
        <ActionButton
          className="back-to-results"
          message={textConstants.BACK_TO_RESULTS}
          onClick={() => {
            returnToAvailability();
          }}
          defaultStyle="h4r-secondary"
        />
        <Box className="desktop-hotel-shop-header-details" tabIndex={0}>
          <HotelName
            name={lodging?.lodging?.name}
            isSkeleton={hotelShopCallInProgress}
          />
          <HotelStarRating
            starRating={lodging?.lodging?.starRating}
            city={lodging?.lodging?.city}
            isSkeleton={hotelShopCallInProgress}
          />
        </Box>
      </Box>
    );
  };

  return (
    <Header
      className="desktop-hotel-rewards-header-root"
      left={<LeftContent />}
      right={
        <Box className="desktop-hotel-shop-rewards-account-contents">
          <RewardsAccountSelection
            className="b2b hide-balance-border"
            popoverClassName="b2b"
          />
          {<TravelWalletDrawer />}
        </Box>
      }
    />
  );
};
