import { FareclassOptionFilter, FareclassShelfBrandName } from "redmond";

export function getSelectedFareClasses(
  fareClassOptionFilter: FareclassOptionFilter
): ReadonlyArray<FareclassShelfBrandName> {
  return fareClassOptionFilter
    ? Object.entries(fareClassOptionFilter)
        .filter(([, isSelected]) => isSelected)
        .map(([key]) => key as FareclassShelfBrandName)
    : [];
}

export function getSelectedFareClass(
  fareClassOptionFilter: FareclassOptionFilter
): FareclassShelfBrandName | undefined {
  const selectedFareClasses = getSelectedFareClasses(fareClassOptionFilter);

  return selectedFareClasses.length > 0 ? selectedFareClasses[0] : undefined;
}
