import { IGenericInfoPopupProps } from "halifax/build/GenericInfoPopup";
import { TokenizeCardErrors } from "redmond";
import {
  ADD_PAYMENT_METHOD_ERROR_TITLE,
  CAP_ONE_INVALID_CREDIT_CARD_SUBTITLE,
  CAP_ONE_INVALID_CREDIT_CARD_TITLE,
  EDIT_PAYMENT_METHOD,
  TRY_AGAIN_CTA,
} from "./textConstants";

export const getErrorModalProps = (
  tokenizeErrors: TokenizeCardErrors[],
  invalidCapOneCard: boolean,
  clearErrors: () => void
): Pick<IGenericInfoPopupProps, "title" | "subtitle" | "buttons"> => {
  if (tokenizeErrors.length) {
    return {
      title: ADD_PAYMENT_METHOD_ERROR_TITLE,
      subtitle: tokenizeErrors[0].message,
      buttons: [{ buttonText: TRY_AGAIN_CTA, onClick: clearErrors }],
    };
  }

  if (invalidCapOneCard) {
    return {
      title: CAP_ONE_INVALID_CREDIT_CARD_TITLE,
      subtitle: CAP_ONE_INVALID_CREDIT_CARD_SUBTITLE,
      buttons: [{ buttonText: EDIT_PAYMENT_METHOD, onClick: clearErrors }],
    };
  }

  return {
    title: ADD_PAYMENT_METHOD_ERROR_TITLE,
    buttons: [{ buttonText: TRY_AGAIN_CTA, onClick: clearErrors }],
  };
};
