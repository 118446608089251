import React from "react";
import { Box } from "@material-ui/core";
import { Icon, IconName } from "halifax";
import { IResult, ITripTerminus, RecentFlightSearch } from "redmond";
import {
  OriginAutocomplete,
  DestinationAutocomplete,
} from "../TerminusAutocomplete";
import { ISetOrigin, ISetDestination } from "../../../../actions/actions";
import "./styles.scss";
import clsx from "clsx";

interface IOriginDestinationSearchProps {
  origin: ITripTerminus | null;
  setOrigin: (origin: ITripTerminus | null) => ISetOrigin;
  destination: IResult | null;
  setDestination: (destination: IResult | null) => ISetDestination;
  hasMissingSearchInfoError?: boolean;
  disabled?: boolean;
  recentSearches?: RecentFlightSearch[];
  onRecentSearchClick?: (search: RecentFlightSearch) => void;
}

export const OriginDestinationSearch = ({
  origin,
  setOrigin,
  destination,
  setDestination,
  hasMissingSearchInfoError,
  disabled,
  recentSearches,
  onRecentSearchClick,
}: IOriginDestinationSearchProps) => {
  return (
    <Box
      className={clsx("packages-location-pickers", {
        isErrorState:
          (hasMissingSearchInfoError && !origin) ||
          (hasMissingSearchInfoError && !destination),
      })}
    >
      <OriginAutocomplete
        className="origin-auto-complete b2b"
        customIcon={
          <Icon name={IconName.B2BMapPin} ariaLabel="" aria-hidden={true} />
        }
        label="Where from?"
        value={origin}
        setValue={setOrigin}
        disabled={disabled}
        getOptionSelected={(
          option: ITripTerminus | null,
          value: ITripTerminus | null
        ) => {
          return (
            !!value &&
            !!option &&
            value.id.code.code === option.id.code.code &&
            value.label === option.label
          );
        }}
        hasMissingSearchInfoError={hasMissingSearchInfoError && !origin}
        recentSearches={recentSearches}
        onRecentSearchClick={
          onRecentSearchClick
            ? (recentSearch) =>
                onRecentSearchClick(recentSearch as RecentFlightSearch)
            : undefined
        }
      />

      <Box className="auto-complete-divider" />

      <DestinationAutocomplete
        className="destination-auto-complete b2b"
        customIcon={
          <Icon name={IconName.B2BMapPin} ariaLabel="" aria-hidden={true} />
        }
        label="Where to?"
        value={destination}
        setValue={setDestination}
        disabled={disabled}
        getOptionSelected={(option: IResult | null, value: IResult | null) => {
          return !!value && !!option && value.label === option.label;
        }}
        hasMissingSearchInfoError={hasMissingSearchInfoError && !destination}
      />
    </Box>
  );
};
