import dayjs from "dayjs";
import { TripSlice } from "redmond";

const OUTBOUND = "Outbound";
const RETURN = "Return";

export const getReviewCardHeader = (
  isDeparture: boolean,
  location: string,
  date: Date | string,
  parsable = false
) =>
  //Added semicolon to parse into two phrases
  `${isDeparture ? OUTBOUND : RETURN} to ${location} ${
    parsable ? ":" : ""
  } on ${dayjs(date).format("ddd, MMM D")}`;

export const getPlusDays = (trip: TripSlice): number => {
  return trip.segmentDetails
    .filter((s) => s.plusDays && s.plusDays > 0)
    .reduce((total, segment) => total + (segment.plusDays ?? 0), 0);
};
