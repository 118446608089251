import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { setStopsOption } from "../../../search/actions/actions";
import {
  getStopsOption,
  getHasSetStopsOption,
  getFareclassOptionFilter,
} from "../../../search/reducer";
import { IStoreState } from "../../../../reducers/types";
import { FlightShopFilters } from "./component";
import {
  setAirlineFilter,
  setDurationFilter,
  setMaxPriceFilter,
  setOutboundArrivalTimeRange,
  setOutboundDepartureTimeRange,
  setReturnArrivalTimeRange,
  setReturnDepartureTimeRange,
} from "../../actions/actions";
import {
  getAirlineFilter,
  getHasSetAirlineFilter,
  allFlightShopFilterSelector,
  getHasSetOutboundTimeRange,
  getHasSetReturnTimeRange,
  getOutboundArrivalTimeRange,
  getOutboundDepartureTimeRange,
  getReturnArrivalTimeRange,
  getReturnDepartureTimeRange,
  getMinMaxFlightDurations,
  filteredFlightsSelector,
  getDurationFilter,
  getMaxPriceFilter,
} from "../../reducer";

const mapStateToProps = (state: IStoreState) => {
  const hasSetTimeRange =
    getHasSetOutboundTimeRange(state) || getHasSetReturnTimeRange(state);
  const { minDuration = 0, maxDuration = 0 } = getMinMaxFlightDurations(state);
  return {
    stopsOption: getStopsOption(state),
    hasSetStopsOption: getHasSetStopsOption(state),
    airlineFilter: getAirlineFilter(state),
    hasSetAirlineFilter: getHasSetAirlineFilter(state),
    flightShopFilters: allFlightShopFilterSelector(state),
    outboundDepartureTimeRange: getOutboundDepartureTimeRange(state),
    outboundArrivalTimeRange: getOutboundArrivalTimeRange(state),
    returnDepartureTimeRange: getReturnDepartureTimeRange(state),
    returnArrivalTimeRange: getReturnArrivalTimeRange(state),
    hasSetTimeRange,
    durationFilter: getDurationFilter(state),
    minDuration,
    maxDuration,
    flightCount: filteredFlightsSelector(state).length,
    maxPriceFilter: getMaxPriceFilter(state),
    hasSetOutboundTimeRange: getHasSetOutboundTimeRange(state),
    hasSetReturnTimeRange: getHasSetReturnTimeRange(state),
    fareclassFilter: getFareclassOptionFilter(state),
  };
};

export const mapDispatchToProps = {
  setStopsOption,
  setAirlineFilter,
  setOutboundDepartureTimeRange,
  setOutboundArrivalTimeRange,
  setReturnDepartureTimeRange,
  setReturnArrivalTimeRange,
  setDurationFilter,
  setMaxPriceFilter,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type FlightShopFiltersConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedFlightShopFilters = withRouter(
  connector(FlightShopFilters)
);
