import { IconName } from "halifax";
import React from "react";

export const PACKAGES_INFO_MODAL_HEADING_TEXT = "Packages";
export const PACKAGES_INFO_MODAL_SUBHEADING_TEXT =
  "First select a hotel, then a flight, and save with select package rates.";

export const PACKAGES_INFO_MODAL_POINTS_HEADING = "Keep in mind";

export const PACKAGES_INFO_MODAL_POINTS: {
  icon: IconName;
  text: JSX.Element;
}[] = [
  {
    icon: IconName.PercentTag,
    text: (
      <>
        <strong>Look for the green labels to find package savings.</strong>{" "}
        Search results include a larger inventory and not all packages offer a
        discount.
      </>
    ),
  },
  {
    icon: IconName.DollarBillsStack,
    text: (
      <>
        <strong>Prices are shown "per traveler,"</strong> which is different
        from a typical booking.
      </>
    ),
  },
  {
    icon: IconName.UserAdd,
    text: (
      <>
        <strong>
          Include the total number of travelers in your party when you start
          your search.
        </strong>{" "}
        Otherwise, we can&rsquo;t display accurate pricing and availability.
      </>
    ),
  },
];

export const PACKAGES_INFO_MODAL_CTA_TEXT = "Search packages";
