import React from "react";
import { Box, Typography } from "@material-ui/core";
import { RouteComponentProps } from "react-router-dom";
import { PackagesTreesConnectorProps } from "./container";
import "./styles.scss";
import {
  Icon,
  IconName,
  InformationalModal,
  TreesConfirmation,
  useDeviceTypes,
} from "halifax";
import {
  TREES_BOLDED_MODAL_CTA_TEXT,
  TREES_MODAL_CTA_TEXT,
  TREES_MODAL_HEADER,
  TREES_MODAL_IMG_LOCATION,
  TREES_MODAL_SUBTITLE,
  TREES_MODAL_TITLE,
} from "./textConstants";
import clsx from "clsx";

export interface IPackageTreesCardProps
  extends RouteComponentProps,
    PackagesTreesConnectorProps {
  isConfirmationScreen?: boolean;
}

export const PackageTreesCard = ({
  isConfirmationScreen = false,
}: IPackageTreesCardProps) => {
  const [treeModalOpen, setTreeModalOpen] = React.useState(false);
  const { matchesMobile } = useDeviceTypes();

  if (matchesMobile && !isConfirmationScreen)
    return (
      <Box className="packages-checkout-trees-root mobile without-image">
        <Box className="trees-description-title">
          <Icon className="trees-icon" name={IconName.TreesIcon} />
          <InformationalModal
            image={TreesConfirmation}
            header={TREES_MODAL_HEADER}
            title={TREES_MODAL_TITLE}
            onClose={() => setTreeModalOpen(false)}
            subtitle={TREES_MODAL_SUBTITLE}
            imageLocation={TREES_MODAL_IMG_LOCATION}
            icon={<Icon className="trees-icon" name={IconName.TreesIcon} />}
            openModal={treeModalOpen}
            setOpenModal={setTreeModalOpen}
            modalLinkCopy={TREES_MODAL_CTA_TEXT}
            boldedModalLinkCopy={TREES_BOLDED_MODAL_CTA_TEXT}
            isMobile={matchesMobile}
          />
        </Box>
      </Box>
    );

  return (
    <Box
      className={clsx("packages-checkout-trees-root with-image", {
        mobile: matchesMobile,
      })}
    >
      <Box
        className="trees-img-container"
        style={{ backgroundImage: `url(${TreesConfirmation})` }}
      />
      <Box className="trees-description-container">
        <Box className="trees-description-title">
          <Icon className="trees-icon" name={IconName.TreesIcon} />
          <InformationalModal
            image={TreesConfirmation}
            header={TREES_MODAL_HEADER}
            title={TREES_MODAL_TITLE}
            onClose={() => setTreeModalOpen(false)}
            subtitle={TREES_MODAL_SUBTITLE}
            imageLocation={TREES_MODAL_IMG_LOCATION}
            icon={<Icon className="trees-icon" name={IconName.TreesIcon} />}
            openModal={treeModalOpen}
            setOpenModal={setTreeModalOpen}
            modalLinkCopy={TREES_MODAL_CTA_TEXT}
            boldedModalLinkCopy={TREES_BOLDED_MODAL_CTA_TEXT}
            isMobile={matchesMobile}
          />
        </Box>
        <Typography variant="body2">
          For every booking, we commit to planting trees to help with forest
          restoration programs.
        </Typography>
      </Box>
    </Box>
  );
};
