import axios from "axios";
import { TrackEventRequest } from "redmond";
import { config } from "../config";
import { analyticsEventApi } from "./paths";
import { getUserTrackingProperties } from "@capone/common";
import { store } from "../../../../../cap1-application/b2b-base/src/app/store";

const END_POINT: string = analyticsEventApi;

export const trackEvent = (req: TrackEventRequest): Promise<boolean> =>
  new Promise(async (resolve, reject) => {
    try {
      req.properties = {
        ...req.properties,
        ...getUserTrackingProperties(
          config.TENANT,
          store.getState().rootReducer
        ),
        has_travel_credit: req.properties.has_credits,
      };
      const res = await axios.post(END_POINT, req, {
        baseURL: config.baseURL,
      });

      const responseBody = res.data;
      resolve(responseBody);
    } catch (e) {
      console.error(e);
      reject(e);
    }
  });
