import axios from "axios";
import { config } from "../../config";

import { flightTripDetailsApiPrefix } from "../paths";
import { OpaqueToken, TripDetails } from "redmond";

export const fetchPackagesTripDetails = (
  tripId: string,
  opaquePackageToken: OpaqueToken
): Promise<TripDetails> =>
  new Promise(async (resolve, reject) => {
    const req = {
      opaquePackageToken: opaquePackageToken,
      tripId: { value: tripId },
    };
    try {
      const res = await axios.post(flightTripDetailsApiPrefix, req, {
        baseURL: config.baseURL,
      });

      resolve(res.data.value);
    } catch (e) {
      reject(e);
    }
  });
