export const FETCH_PACKAGE_HOTEL_SHOP =
  "packageHotelShop/FETCH_PACKAGE_HOTEL_SHOP";
export type FETCH_PACKAGE_HOTEL_SHOP = typeof FETCH_PACKAGE_HOTEL_SHOP;

export const SET_PACKAGE_HOTEL_SHOP_RESULTS =
  "packageHotelShop/SET_PACKAGE_HOTEL_SHOP_RESULTS";
export type SET_PACKAGE_HOTEL_SHOP_RESULTS =
  typeof SET_PACKAGE_HOTEL_SHOP_RESULTS;

export const SET_PACKAGE_HOTEL_SHOP_CALL_STATE_FAILED =
  "packageHotelShop/SET_PACKAGE_HOTEL_SHOP_CALL_STATE_FAILED";
export type SET_PACKAGE_HOTEL_SHOP_CALL_STATE_FAILED =
  typeof SET_PACKAGE_HOTEL_SHOP_CALL_STATE_FAILED;

export const SELECT_LODGING = "packageHotelShop/SELECT_LODGING";
export type SELECT_LODGING = typeof SELECT_LODGING;

export const SELECT_PACKAGE_BY_LODGING_ID =
  "packageHotelShopp/SELECT_PACKAGE_BY_LODGING_ID";
export type SELECT_PACKAGE_BY_LODGING_ID = typeof SELECT_PACKAGE_BY_LODGING_ID;

export const SET_DATE_RANGE = "packageHotelShopp/SET_DATE_RANGE";
export type SET_DATE_RANGE = typeof SET_DATE_RANGE;

export const ACKNOWLEDGE_PACKAGE_HOTEL_SHOP_FAILURE =
  "packageHotelShop/ACKNOWLEDGE_HOTEL_SHOP_FAILURE";
export type ACKNOWLEDGE_PACKAGE_HOTEL_SHOP_FAILURE =
  typeof ACKNOWLEDGE_PACKAGE_HOTEL_SHOP_FAILURE;

export const SET_PACKAGE_HOTEL_SHOP_PROGRESS =
  "packageHotelShop/SET_PACKAGE_HOTEL_SHOP_PROGRESS";
export type SET_PACKAGE_HOTEL_SHOP_PROGRESS =
  typeof SET_PACKAGE_HOTEL_SHOP_PROGRESS;

export const SET_SELECTED_ROOM_ID = "packageHotelShop/SET_SELECTED_ROOM_ID";
export type SET_SELECTED_ROOM_ID = typeof SET_SELECTED_ROOM_ID;

export const SELECT_ROOM_TYPE = "packageHotelShop/SELECT_ROOM_TYPE";
export type SELECT_ROOM_TYPE = typeof SELECT_ROOM_TYPE;

export const FETCH_DISTANCE_FROM_LOCATION_CATEGORIES =
  "packageHotelShop/FETCH_DISTANCE_FROM_LOCATION_CATEGORIES";
export type FETCH_DISTANCE_FROM_LOCATION_CATEGORIES =
  typeof FETCH_DISTANCE_FROM_LOCATION_CATEGORIES;

export const SET_DISTANCE_FROM_LOCATION_CATEGORIES =
  "packageHotelShop/SET_DISTANCE_FROM_LOCATION_CATEGORIES";
export type SET_DISTANCE_FROM_LOCATION_CATEGORIES =
  typeof SET_DISTANCE_FROM_LOCATION_CATEGORIES;

export const SET_DISTANCE_FROM_LOCATION =
  "packageHotelShop/SET_DISTANCE_FROM_LOCATION";
export type SET_DISTANCE_FROM_LOCATION = typeof SET_DISTANCE_FROM_LOCATION;
