import React from "react";

export const PRICE_DROPDOWN_LABEL_TEXT = (appliedLabel?: string) =>
  !!appliedLabel ? (
    <>
      <span className="filter-name">Max Price:</span> {appliedLabel}
    </>
  ) : (
    <span className="filter-name">Price</span>
  );
export const PRICE_SLIDER_HEADER_TEXT = "Set max price";
export const PRICE_SLIDER_HEADER_TEXT_EXP = "Max price";
export const PRICE_SLIDER_SUBTITLE_TEXT = "Round-trip, per traveler";
