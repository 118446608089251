import React from "react";
import { AlgomerchModal, useDeviceTypes } from "halifax";
import { AlgomerchTag } from "redmond";

import * as constants from "./constants";

export interface IFlightAlgomerchModalProps {
  selectedCategory: AlgomerchTag;
  setSelectedCategory: React.Dispatch<React.SetStateAction<AlgomerchTag>>;
  openModal: boolean;
  onClose: () => void;
}

// TODO: Maybe move this to cap1-components
export const FlightAlgomerchModal = (props: IFlightAlgomerchModalProps) => {
  const { selectedCategory, setSelectedCategory, openModal, onClose } = props;
  const { matchesMobile } = useDeviceTypes();

  return (
    <AlgomerchModal
      openModal={openModal}
      onClose={onClose}
      algomerchInfoTitle={constants.ALGOMERCH_INFO_TITLE}
      scoreFactorTitles={constants.scoreFactorTitles}
      algomerchTitles={constants.algomerchTitles}
      selectedCategory={selectedCategory}
      setSelectedCategory={setSelectedCategory}
      isMobile={matchesMobile}
      className="b2b-algomerch-modal"
    />
  );
};
