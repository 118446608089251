import React from "react";
import { FARE_CLASSES } from "../../../PackagesSearchControl/components/FareclassOptionSelection/textConstants";

export const FARECLASS_BUTTON_LABEL = (appliedLabel?: string) => {
  if (!!appliedLabel) {
    return <span className="filter-name">{FARE_CLASSES[appliedLabel]}</span>;
  } else {
    return <span className="filter-name">Fare class</span>;
  }
};
export const POPOVER_CTA_LABEL = "Apply";
export const RESET_CTA_TEXT = "Reset";
export const FARECLASS_HEADER_TEXT = "Fare class";
