import { FinalizePackageResponse } from "redmond";
import { actions, actionTypes } from "../actions";

export interface IPackageItineraryReviewState {
  finalizePackageResponse?: FinalizePackageResponse;
}

export const initalState: IPackageItineraryReviewState = {
  finalizePackageResponse: undefined,
};

export const reducer = (
  state: IPackageItineraryReviewState = initalState,
  action: actions.PackageItineraryReviewActions
): IPackageItineraryReviewState => {
  switch (action.type) {
    case actionTypes.SET_FINALIZE_PACKAGE_RESPONSE: {
      return {
        ...state,
        finalizePackageResponse: action.finalizeResponse,
      };
    }
    default:
      return { ...state };
  }
};
