import React from "react";
import { Icon, IconName, SwitchButton } from "halifax";
import "./styles.scss";

interface NonstopToggleProps {
  checked: boolean;
  onClick: (checked: boolean) => void;
}

export const NonStopToggle = ({ checked, onClick }: NonstopToggleProps) => {
  return (
    <div className="nonstop-toggle-root">
      <Icon name={IconName.StopsFilterIcon} />
      <SwitchButton
        content="Nonstop only"
        checked={checked}
        onClick={onClick}
      />
    </div>
  );
};
