import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import { AvailabilityFilter } from "./component";

import { IStoreState } from "../../../../reducers/types";
import {
  setAmenitiesFilter,
  setFreeCancelFilter,
  setHotelNameFilter,
  setLoyaltyProgramsFilter,
  setMaxPriceFilter,
  setPackagesHotelsAvailabilitySortOption,
  setPolicyFilter,
  setStarRatingsFilter,
} from "../../actions/actions";
import {
  getAppliedHotelAvailabilityFilterCount,
  getHotelAvailabilityAmenitiesFilter,
  getHotelAvailabilityCurrency,
  getHotelAvailabilityFreeCancelFilter,
  getHotelAvailabilityHotelNameFilter,
  getHotelAvailabilityLoyaltyProgramsFilter,
  getHotelAvailabilityMaxPriceFilter,
  getHotelAvailabilityMinMaxPriceRange,
  getHotelAvailabilityPolicyFilter,
  getHotelAvailabilityStarRatingsFilter,
  getPackagesAvailabilityCallState,
  getPackagesAvailabilityLodgingsSortOption,
  hasChangedHotelAvailabilityAmenitiesFilter,
  hasChangedHotelAvailabilityPolicyFilter,
  hasChangedHotelAvailabilityStarRatingsFilter,
} from "../../reducer";

const mapStateToProps = (state: IStoreState) => {
  return {
    starRatings: getHotelAvailabilityStarRatingsFilter(state),
    hasChangedStarRatingsFilter:
      hasChangedHotelAvailabilityStarRatingsFilter(state),
    amenities: getHotelAvailabilityAmenitiesFilter(state),
    hasChangedAmenitiesFilter:
      hasChangedHotelAvailabilityAmenitiesFilter(state),
    currency: getHotelAvailabilityCurrency(state),
    maxPrice: getHotelAvailabilityMaxPriceFilter(state),
    minMaxPriceRange: getHotelAvailabilityMinMaxPriceRange(state),
    hasFreeCancelFilter: getHotelAvailabilityFreeCancelFilter(state),
    hotelName: getHotelAvailabilityHotelNameFilter(state),
    isInPolicy: getHotelAvailabilityPolicyFilter(state),
    hasChangedPolicyFilter: hasChangedHotelAvailabilityPolicyFilter(state),
    loyaltyPrograms: getHotelAvailabilityLoyaltyProgramsFilter(state),
    appliedFilterCount: getAppliedHotelAvailabilityFilterCount(state),
    packagesAvailabilityCallState: getPackagesAvailabilityCallState(state),
    sortOption: getPackagesAvailabilityLodgingsSortOption(state),
  };
};

const mapDispatchToProps = {
  setStarRatingsFilter,
  setAmenitiesFilter,
  setMaxPriceFilter,
  setFreeCancelFilter,
  setHotelNameFilter,
  setPackagesHotelsAvailabilitySortOption,
  setPolicyFilter,
  setLoyaltyProgramsFilter,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type AvailabilityFilterConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedAvailabilityFilter = withRouter(
  connector(AvailabilityFilter)
);
