import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { PackagesRewardsAndPaymentConnectorProps } from "./container";
import {
  CardPaymentSelectors,
  cartQuoteSelectors,
  getNestedChildState,
  PaymentInformationChildState,
  RewardsAndPayment,
  RewardsPaymentEventTypes,
  RewardsPaymentSelectors,
  useCheckoutState,
  useCheckoutStateSelector,
  WalletEventTypes,
  WalletSelectors,
} from "@capone/checkout";
import { config } from "../../../../api/config";
import { PackagesMachineContext } from "../../state/types";
import { Event, TEvent } from "../../state/events";
import {
  ActionButton,
  B2BSpinner,
  BackButton,
  GenericInfoPopup,
  getHSPEarnOfferDescription,
  Icon,
  IconName,
  LoadingPopup,
  MobileFloatingButton,
  MobilePopoverCard,
  PoweredByHopper,
  TEST_CARD_LAST_FOURS,
  useDeviceTypes,
} from "halifax";
import {
  TokenizeCardErrors,
  TravelProductEnum,
  TravelWalletCredit,
  TravelWalletOffer,
} from "redmond";
import { getErrorModalProps } from "./utils";
import clsx from "clsx";
import {
  CARD_VERIFY_TEXT,
  MOBILE_HEADING_SUBTITLE_TEXT,
  MOBILE_HEADING_TITLE_TEXT,
  NO_SELECTED_CARD_TEXT,
  NO_SELECTED_REWARDS_TEXT,
  REVIEW_TRIP_CTA_TEXT,
} from "./textConstants";
import "./styles.scss";
import { ClientContext } from "../../../../App";
import { CONFIRM_AND_BOOK_CTA_TEXT } from "../textConstants";
import { productToEarnV2 } from "../../../../api/v1/rewards/productToEarn";
import {
  getPackageFlightCardTotal,
  getPackageHotelCardTotal,
} from "../../state/selectors/common";
import { Box, Typography } from "@material-ui/core";
import { PackagesPriceBreakdown } from "../PriceBreakdown";

export interface IPackagesRewardsAndPaymentProps
  extends RouteComponentProps,
    PackagesRewardsAndPaymentConnectorProps {
  isMobile?: boolean;
}

export const PackagesRewardsAndPayment = ({
  rewardsAccounts,
  isMobile,
}: IPackagesRewardsAndPaymentProps) => {
  const [validationErrorType, setValidationErrorType] = React.useState<
    "rewards" | "card" | undefined
  >();

  const { sessionInfo } = React.useContext(ClientContext);

  const { matchesMobile } = useDeviceTypes();

  const [tokenizeErrors, setTokenizeErrors] = React.useState<
    TokenizeCardErrors[]
  >([]);
  const [invalidCapOneCardError, setInvalidCapOneCardError] =
    React.useState(false);

  const [state, send] = useCheckoutState<TEvent, PackagesMachineContext>();

  const nestedChildState = getNestedChildState(state.value);

  /* selectors */

  const paymentMethods = useCheckoutStateSelector(
    CardPaymentSelectors.getPaymentMethods
  );

  const offers = useCheckoutStateSelector(WalletSelectors.getOffers);
  const credits = useCheckoutStateSelector(WalletSelectors.getCredits);
  const offerToApply = useCheckoutStateSelector(
    WalletSelectors.getSelectedOffer
  );
  const creditsToApply = useCheckoutStateSelector(
    WalletSelectors.getTravelWalletCreditToApply
  );
  const isTravelCreditPaymentOnly = useCheckoutStateSelector(
    WalletSelectors.getIsTravelCreditPaymentOnly
  );
  const isTravelWalletOfferPaymentOnly = useCheckoutStateSelector(
    WalletSelectors.getIsTravelWalletOfferPaymentOnly
  );
  const isStackedTravelWalletPaymentOnly = useCheckoutStateSelector(
    WalletSelectors.getIsStackedTravelWalletPaymentOnly
  );
  const creditTransactionHistory = useCheckoutStateSelector(
    WalletSelectors.getCreditTransactionHistory
  );
  const maxApplicableTravelWalletCredit = useCheckoutStateSelector(
    WalletSelectors.getMaxApplicableCreditAmount
  );

  const paymentMethodsLoading = useCheckoutStateSelector(
    CardPaymentSelectors.getIsPaymentLoading
  );
  const selectedPaymentMethodId = useCheckoutStateSelector(
    CardPaymentSelectors.getSelectedPaymentMethodId
  );
  const isPaymentError = useCheckoutStateSelector(
    CardPaymentSelectors.getIsPaymentError
  );
  const addPaymentMethodModalOpen = useCheckoutStateSelector(
    CardPaymentSelectors.getOpenAddPaymentMethodModal
  );

  const selectedRewardsAccountId = useCheckoutStateSelector(
    RewardsPaymentSelectors.getSelectedAccountId
  );
  const cartBreakdownBalance = useCheckoutStateSelector(
    cartQuoteSelectors.getCartBreakdownBalance
  );
  const rewardsPaymentInFiatCurrency = useCheckoutStateSelector(
    RewardsPaymentSelectors.getRewardsFiatAmountToApply
  );
  const rewardsPaymentInRewardsCurrency = useCheckoutStateSelector(
    RewardsPaymentSelectors.getRewardsAmountToApply
  );
  const isCreditCardPaymentRequired = useCheckoutStateSelector(
    CardPaymentSelectors.getIsCreditCardPaymentRequired
  );

  const isCostCoveredBySelectedPaymentMethods = useCheckoutStateSelector(
    CardPaymentSelectors.getIsCostCoveredBySelectedPaymentMethods
  );
  const earnValueByRewardsAccountId = useCheckoutStateSelector(
    RewardsPaymentSelectors.getEarnValueByRewardsAccount
  );
  const rewardsPaymentAllowed = useCheckoutStateSelector(
    RewardsPaymentSelectors.getRewardsPaymentAllowed
  );

  const flightTotal = useCheckoutStateSelector(getPackageFlightCardTotal);
  const hotelTotal = useCheckoutStateSelector(getPackageHotelCardTotal);

  /* action callbacks */

  const verifyPaymentMethod = (token: string) =>
    send({ type: Event.VERIFY_PAYMENT_METHOD, token });

  const deletePaymentMethod = (paymentId: string) =>
    send({ type: Event.DELETE_PAYMENT_METHOD, paymentMethod: { paymentId } });

  const setSelectedPaymentMethodId = (
    paymentMethodId: string,
    rewardsAccountId?: string
  ) => {
    send({
      type: Event.SET_SELECTED_PAYMENT_METHOD_ID,
      paymentMethod: { paymentId: paymentMethodId, rewardsAccountId },
    });
  };

  const handlePaymentMethodAdd = (token: string, last4: string) => {
    const account = rewardsAccounts.find(
      (account) =>
        account.lastFour === last4 ||
        account.lastFourVirtualCardNumbers?.includes(last4)
    );
    let matchingRewardsAccount = account;

    const isTestCard =
      window.__mclean_env__.ENV !== "production" &&
      TEST_CARD_LAST_FOURS.includes(last4);
    if (isTestCard) {
      matchingRewardsAccount = rewardsAccounts[0];
    }

    if (!!matchingRewardsAccount || isTestCard) {
      verifyPaymentMethod(token);
      setSelectedPaymentMethodId(token);
    } else {
      send(Event.CLOSE_PAYMENT_FORM);
      setInvalidCapOneCardError(true);
    }
  };

  const handleClearErrors = () => {
    send(Event.CLEAR_PAYMENT_ERROR);
    setTokenizeErrors([]);
    setInvalidCapOneCardError(false);
  };

  const handleClickAddPaymentMethod = () => send(Event.OPEN_PAYMENT_FORM);

  const handleCloseAddPaymentMethod = () => send(Event.CLOSE_PAYMENT_FORM);

  const setSelectedRewardsAccountId = (accountId?: string) =>
    send({
      type: RewardsPaymentEventTypes.SET_SELECTED_REWARDS_ACCOUNT_ID,
      selectedAccountId: accountId,
    });

  const setRewardsPaymentAmount = (amount: number) =>
    send({
      type: RewardsPaymentEventTypes.SET_REWARDS_AMOUNT_TO_PAY,
      rewardsAmountToPay: amount,
    });

  const setOfferToApply = (offer: TravelWalletOffer) =>
    offer
      ? send({ type: WalletEventTypes.SET_SELECTED_OFFER, offerId: offer.id })
      : send(WalletEventTypes.REMOVE_SELECTED_OFFER);

  const setCreditAmountToApply = (credits: TravelWalletCredit) =>
    send({
      type: WalletEventTypes.SET_CREDIT_AMOUNT_TO_APPLY,
      amount: credits?.amount?.amount || 0,
    });

  const canContinue =
    isMobile ||
    (isCostCoveredBySelectedPaymentMethods && !!selectedRewardsAccountId);

  const handleContinue = () => {
    if (canContinue) {
      setValidationErrorType(undefined);
      send(Event.NEXT);
    } else {
      setValidationErrorType(
        (() => {
          switch (true) {
            case !selectedRewardsAccountId:
              return "rewards";
            case isCreditCardPaymentRequired && !selectedPaymentMethodId:
              return "card";
            default:
              return undefined;
          }
        })()
      );
    }
  };

  const handleBack = () => {
    send(Event.PREVIOUS);
  };

  const errorModalOpen =
    !!tokenizeErrors.length || invalidCapOneCardError || isPaymentError;

  const errorModalProps = getErrorModalProps(
    tokenizeErrors,
    invalidCapOneCardError,
    handleClearErrors
  );

  // calculate earn for all accounts when totals change
  React.useEffect(() => {
    if (flightTotal !== undefined && hotelTotal !== undefined) {
      Promise.all(
        rewardsAccounts.map((account) =>
          productToEarnV2({
            account: account.accountReferenceId,
            earnItems: {
              [TravelProductEnum.Flights]: {
                amountUsd: flightTotal,
              },
              [TravelProductEnum.Hotels]: {
                amountUsd: hotelTotal,
                earnOfferDescription: getHSPEarnOfferDescription(offerToApply),
              },
            },
          })
            .then((res) => {
              send({
                type: RewardsPaymentEventTypes.SET_EARN_BY_REWARDS_ACCOUNT_ID,
                accountReferenceId: account.accountReferenceId,
                earnResponse: res,
              });
            })
            .catch((e) =>
              console.warn(
                `Failed to fetch earn for account ${account.accountReferenceId}: ${e}`
              )
            )
        )
      );
    }
  }, [flightTotal, hotelTotal, offerToApply]);

  React.useEffect(() => {
    if (
      (validationErrorType === "rewards" && selectedRewardsAccountId) ||
      (validationErrorType === "card" &&
        isCreditCardPaymentRequired &&
        selectedPaymentMethodId)
    ) {
      setValidationErrorType(undefined);
    }
  }, [
    selectedRewardsAccountId,
    isCreditCardPaymentRequired,
    selectedPaymentMethodId,
    validationErrorType,
  ]);

  const renderContent = () => (
    <RewardsAndPayment
      rewardsAccounts={rewardsAccounts}
      paymentMethods={paymentMethods}
      loadingPaymentMethods={paymentMethodsLoading}
      spreedlyEnvironmentKey={config.spreedlyEnvironmentKey}
      selectedPaymentMethodId={selectedPaymentMethodId}
      verifyingPaymentMethod={
        nestedChildState === PaymentInformationChildState.verify
      }
      isMobile={matchesMobile}
      onRemovePaymentMethod={deletePaymentMethod}
      onSelectPaymentMethod={setSelectedPaymentMethodId}
      onAddPaymentMethod={handlePaymentMethodAdd}
      onAddPaymentMethodError={setTokenizeErrors}
      onClickAddPaymentMethod={handleClickAddPaymentMethod}
      closePaymentModal={handleCloseAddPaymentMethod}
      addPaymentModalOpen={addPaymentMethodModalOpen}
      selectedRewardsAccountId={selectedRewardsAccountId}
      setSelectedRewardsAccountId={setSelectedRewardsAccountId}
      total={
        cartBreakdownBalance
          ? {
              ...cartBreakdownBalance,
              rewards: cartBreakdownBalance.accountSpecific,
            }
          : undefined
      }
      rewardsPaymentInFiatCurrency={rewardsPaymentInFiatCurrency}
      rewardsPaymentInRewardsCurrency={rewardsPaymentInRewardsCurrency}
      setRewardsPaymentAmount={setRewardsPaymentAmount}
      isCreditCardPaymentRequired={isCreditCardPaymentRequired}
      offers={offers}
      credits={credits}
      offerToApply={offerToApply}
      creditsToApply={creditsToApply}
      isTravelCreditPaymentOnly={isTravelCreditPaymentOnly}
      isTravelWalletOfferPaymentOnly={isTravelWalletOfferPaymentOnly}
      isStackedTravelWalletPaymentOnly={isStackedTravelWalletPaymentOnly}
      firstName={sessionInfo?.userInfo.firstName || ""}
      creditTransactionHistory={creditTransactionHistory}
      setOfferToApply={setOfferToApply}
      setCreditToApply={setCreditAmountToApply}
      maxApplicableTravelWalletCredit={maxApplicableTravelWalletCredit}
      cardPaymentDisabled={!isCreditCardPaymentRequired}
      earnValueByRewardsAccountId={earnValueByRewardsAccountId}
      rewardsDisabled={!rewardsPaymentAllowed}
      rewardsError={
        validationErrorType === "rewards" ? NO_SELECTED_REWARDS_TEXT : undefined
      }
      cardError={
        validationErrorType === "card" ? NO_SELECTED_CARD_TEXT : undefined
      }
      product={TravelProductEnum.Packages}
    />
  );

  return (
    <>
      {isMobile ? (
        <MobilePopoverCard
          open
          className="packages-book-mobile-payment-popover"
          fullScreen
          onClose={() => {}}
          topLeftButton={
            <BackButton
              className="packages-book-mobile-payment-popover-back-button"
              onClick={handleBack}
            />
          }
          headerElement={<PackagesPriceBreakdown isMobile dropdown />}
        >
          <Box className="packages-book-mobile-payment-header">
            <Typography className="mobile-payment-header-title" variant="h3">
              {MOBILE_HEADING_TITLE_TEXT}
            </Typography>
            <Typography className="mobile-payment-header-subtitle">
              {MOBILE_HEADING_SUBTITLE_TEXT}
            </Typography>
          </Box>
          <hr className="heading-rule" />
          {renderContent()}
          {isCostCoveredBySelectedPaymentMethods && (
            <MobileFloatingButton
              className="packages-book-mobile-review-trip-button"
              onClick={handleContinue}
              wrapperClassName="b2b"
            >
              {REVIEW_TRIP_CTA_TEXT}
            </MobileFloatingButton>
          )}
        </MobilePopoverCard>
      ) : (
        <>
          {renderContent()}
          <ActionButton
            onClick={handleContinue}
            message={CONFIRM_AND_BOOK_CTA_TEXT}
            className="packages-continue-button book"
          />
        </>
      )}
      <GenericInfoPopup
        open={errorModalOpen}
        image={<Icon className="error-icon" name={IconName.ErrorState} />}
        {...errorModalProps}
      />
      <LoadingPopup
        classes={[
          clsx("packages-book-validating-card-modal", {
            mobile: matchesMobile,
          }),
        ]}
        indicatorSize={"small"}
        indicator={B2BSpinner}
        open={nestedChildState === PaymentInformationChildState.verify}
        popupSize={"small"}
        message={CARD_VERIFY_TEXT}
        footer={PoweredByHopper}
        textAlign={"center"}
        fullScreen={isMobile}
        verticalAlignment={isMobile ? "center" : undefined}
      />
    </>
  );
};
