import dayjs from "dayjs";
import {
  FlightFare,
  Flights,
  OutboundFares,
  SafePackageDetails,
  TripSummary,
} from "redmond";

export interface IFlightListData {
  slice: string;
  fares: any; //Todo: add type
}

export const orderByRecommended = (filteredFlights: TripSummary[]) => {
  return filteredFlights
    .sort(
      (a, b) =>
        Math.max(...b.tripFares.map((f) => f.fareScore.value)) -
        Math.max(...a.tripFares.map((f) => f.fareScore.value))
    )
    .map((flight) => flight.tripId);
};

export const orderByPrice = (filteredFlights: TripSummary[]) => {
  return filteredFlights
    .sort(
      (a, b) =>
        Math.min(...a.tripFares.map((f) => f.amount.fiat.value)) -
        Math.min(...b.tripFares.map((f) => f.amount.fiat.value))
    )
    .map((flight) => flight.tripId);
};

export const orderByDepartureTime = (
  filteredFlights: TripSummary[],
  tripSliceKey: string
) => {
  return filteredFlights
    .sort((a, b) =>
      dayjs(a[tripSliceKey]!.departureTime).diff(
        dayjs(b[tripSliceKey]!.departureTime)
      )
    )
    .map((flight) => flight.tripId);
};

export const orderByArrivalTime = (
  filteredFlights: TripSummary[],
  tripSliceKey: string
) => {
  return filteredFlights
    .sort((a, b) =>
      dayjs(a[tripSliceKey]!.arrivalTime).diff(
        dayjs(b[tripSliceKey]!.arrivalTime)
      )
    )
    .map((flight) => flight.tripId);
};

export const orderByStops = (
  filteredFlights: TripSummary[],
  tripSliceKey: string
) => {
  return filteredFlights
    .sort((a, b) => a[tripSliceKey].stops - b[tripSliceKey].stops)
    .map((flight) => flight.tripId);
};

export const orderByDuration = (
  filteredFlights: TripSummary[],
  tripSliceKey: string
) => {
  return filteredFlights
    .sort((a, b) => {
      const aMin = dayjs(a[tripSliceKey].arrivalTime).diff(
        dayjs(a[tripSliceKey].departureTime),
        "minute",
        true
      );
      const bMin = dayjs(b[tripSliceKey].arrivalTime).diff(
        dayjs(b[tripSliceKey].departureTime),
        "minute",
        true
      );

      return aMin - bMin;
    })
    .map((flight) => flight.tripId);
};

/// Flight Shop V2
const groupSouthwestLast = (
  sortedFlightListData: IFlightListData[],
  flights: Flights
) => {
  return sortedFlightListData.sort((a, b) =>
    flights.slices[a.slice].marketingAirline === "WN"
      ? 1
      : flights.slices[b.slice].marketingAirline === "WN"
      ? -1
      : 0
  );
};

export const orderByRecommendedV2 = (
  filteredFlights: IFlightListData[],
  flights: Flights
) => {
  const orderedFlightListData = filteredFlights.sort(
    (a, b) =>
      flights.slices[b.slice].highestScore.value -
      flights.slices[a.slice].highestScore.value
  );
  return groupSouthwestLast(orderedFlightListData, flights);
};

export const orderByPriceV2 = (
  filteredFlights: IFlightListData[],
  packagesByFareSlice: {
    [key: string]: SafePackageDetails;
  }
) => {
  return filteredFlights.sort(
    (a, b) =>
      Math.min(
        ...a.fares.map(
          (f: any) =>
            packagesByFareSlice[
              (f as OutboundFares).fareSlice ?? (f as FlightFare).return
            ].pricing.pricePerTraveler.fiat.value || Infinity
        )
      ) -
      Math.min(
        ...b.fares.map(
          (f: any) =>
            packagesByFareSlice[
              (f as OutboundFares).fareSlice ?? (f as FlightFare).return
            ].pricing.pricePerTraveler.fiat.value || Infinity
        )
      )
  );
};

export const orderByDepartureTimeV2 = (
  filteredFlights: IFlightListData[],
  flights: Flights
) => {
  const orderedFlightListData = filteredFlights.sort((a, b) =>
    dayjs(flights.slices[a.slice].departure).diff(
      dayjs(flights.slices[b.slice].departure)
    )
  );
  return groupSouthwestLast(orderedFlightListData, flights);
};

export const orderByArrivalTimeV2 = (
  filteredFlights: IFlightListData[],
  flights: Flights
) => {
  const orderedFlightListData = filteredFlights.sort((a, b) =>
    dayjs(flights.slices[a.slice].arrival).diff(
      dayjs(flights.slices[b.slice].arrival)
    )
  );
  return groupSouthwestLast(orderedFlightListData, flights);
};

export const orderByStopsV2 = (
  filteredFlights: IFlightListData[],
  flights: Flights
) => {
  const orderedFlightListData = filteredFlights.sort(
    (a, b) => flights.slices[a.slice].stops - flights.slices[b.slice].stops
  );
  return groupSouthwestLast(orderedFlightListData, flights);
};

export const orderByDurationV2 = (
  filteredFlights: IFlightListData[],
  flights: Flights
) => {
  const orderedFlightListData = filteredFlights.sort((a, b) => {
    const aMin = getDifferenceByMinute(a, flights);
    const bMin = getDifferenceByMinute(b, flights);
    return aMin - bMin;
  });
  return groupSouthwestLast(orderedFlightListData, flights);
};

export const getDifferenceByMinute = (
  flight: IFlightListData,
  flights: Flights
) => {
  return dayjs(flights.slices[flight.slice].arrival).diff(
    dayjs(flights.slices[flight.slice].departure),
    "minute",
    true
  );
};
