import { fetchFlightAutocomplete } from "./fetchFlightAutocomplete";
import { IResponse, ITripTerminus, LocationQueryEnum } from "redmond";

export const fetchFlightLocation = async (locationCode: string) => {
  try {
    const { categories: categorizedLocations }: IResponse =
      await fetchFlightAutocomplete({
        l: locationCode,
        LocationQuery: LocationQueryEnum.Label,
      });

    const allLocations = categorizedLocations.flatMap(
      (locations) => locations.results
    ) as ReadonlyArray<ITripTerminus>;

    const correspondingLocations = allLocations.filter(
      (location) => location.id.code.code === locationCode
    );

    return correspondingLocations[0];
  } catch {
    return null;
  }
};
