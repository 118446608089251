import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { IStoreState } from "../../../../reducers/types";

import { ReviewHotelItinerary } from "./component";

import {
  getHotelShopChosenProduct,
  getHotelShopChosenRoomInfo,
  getPackageSelectedLodging,
  getSelectedPackageByLodgingId,
} from "../../../hotel-shop/reducer";
import { getDepartureDate, getReturnDate } from "../../../search/reducer";

const mapStateToProps = (state: IStoreState) => {
  return {
    selectedLodging: getPackageSelectedLodging(state),
    checkIn: getDepartureDate(state),
    checkOut: getReturnDate(state),
    chosenProduct: getHotelShopChosenProduct(state),
    selectedPackageByLodging: getSelectedPackageByLodgingId(state),
    roomInfo: getHotelShopChosenRoomInfo(state),
  };
};

export const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type ReviewHotelItineraryConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedReviewHotelItinerary = withRouter(
  connector(ReviewHotelItinerary)
);
