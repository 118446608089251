import React, { useState } from "react";
import { CorpLodging, Lodging } from "redmond";
import { Box, Button } from "@material-ui/core";
import clsx from "clsx";

import "./styles.scss";
import { CategorySummary } from "redmond/hotels-module/interfaces";

interface DebuggingPanelProps {
  lodgings: Lodging[] | CorpLodging[];
}
export const CorporateDebuggingPanel = (props: DebuggingPanelProps) => {
  if (!props.lodgings) {
    return null;
  }
  const [isToggled, setIsToggled] = useState(false);
  const [hotelFilter, setHotelFilter] = useState("");
  let filteredLodgings = props.lodgings;

  if (hotelFilter) {
    filteredLodgings = props.lodgings.filter((lodging) => {
      return lodging.lodging.name
        .toLowerCase()
        .includes(hotelFilter.toLowerCase());
    });
  }

  return (
    <Box
      className={clsx("corp-debugging-pannel-availability", {
        collapsed: isToggled,
      })}
    >
      <Button
        className="corp-debugging-pannel-availability__button"
        onClick={() => setIsToggled(!isToggled)}
      >
        {isToggled ? "Show" : "Hide"}
      </Button>
      {!isToggled && (
        <Box>
          <label> Search for a hotel name</label>{" "}
          <input
            type="text"
            onKeyUp={(event) => setHotelFilter(event.currentTarget.value)}
          ></input>
          <hr />
        </Box>
      )}
      {!isToggled &&
        filteredLodgings.map((lodging) => {
          const providerName =
            lodging?.price?.providerName == "Expedia"
              ? "other 3p"
              : lodging?.price?.providerName;
          return (
            <Box className="corp-debugging-pannel-availability__inner-box">
              <div>
                <h3>{lodging.lodging.name}</h3>
              </div>
              <div>
                <strong>Provider name</strong> <br />
                {providerName}
                {lodging?.trackingPropertiesV2?.properties?.chain
                  ? ` (chain: ${lodging?.trackingPropertiesV2?.properties?.chain})`
                  : ""}
              </div>
              <div>
                <strong>Free cancelation</strong> <br />
                {lodging.isFreeCancel ? "✅" : "❌"}
              </div>
              <div>
                <strong>Loyalty eligible</strong> <br />
                {lodging.loyaltyProgramCode ? "✅" : "❌"}
              </div>
              <div>
                <strong>Total Price including taxes and fees</strong>
                <br />
                {lodging.price?.breakdown.total?.fiat?.currencySymbol}
                {lodging.price?.breakdown.total?.fiat?.value}{" "}
                {lodging.price?.breakdown.total?.fiat?.currencyCode}
              </div>
              <div>
                <strong>Total Price excluding taxes and fees</strong>
                <br />
                {
                  (lodging.price?.breakdown as CategorySummary)?.subTotal.fiat
                    .currencySymbol
                }
                {
                  (lodging.price?.breakdown as CategorySummary)?.subTotal.fiat
                    .value
                }{" "}
                {
                  (lodging.price?.breakdown as CategorySummary)?.subTotal.fiat
                    .currencyCode
                }
              </div>
              <hr />
            </Box>
          );
        })}
    </Box>
  );
};
