import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../../../../reducers/types";

import { AvailabilityMapPricePin } from "./component";
import { getSelectedAccountReferenceIdIfRedemptionEnabled } from "../../../../../rewards/reducer";
import {
  getPackageAvailabilitySearchDestinationLocationResult,
  getPackageHotelQueryParams,
  getPackagesByLodgingId,
  getPackagesViewedHotelListProperties,
  getTravelersCount,
} from "../../../../reducer";
import { getOrigin } from "../../../../../search/reducer";
const mapStateToProps = (state: IStoreState) => {
  return {
    accountReferenceId: getSelectedAccountReferenceIdIfRedemptionEnabled(state),
    packagesByLodgingId: getPackagesByLodgingId(state),
    travelersCount: getTravelersCount(state),
    packageHotelQueryParams: getPackageHotelQueryParams(state),
    origin: getOrigin(state),
    searchedLocation:
      getPackageAvailabilitySearchDestinationLocationResult(state),
    packagesViewedHotelListProperties:
      getPackagesViewedHotelListProperties(state),
  };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type ConnectedAvailabilityMapPricePinProps = ConnectedProps<
  typeof connector
>;

export const ConnectedAvailabilityMapPricePin = connector(
  AvailabilityMapPricePin
);
