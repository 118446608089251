import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import {
  getAdultsCount,
  getChildren,
  getDepartureDate,
  getDestination,
  getInfants,
  getOrigin,
  getReturnDate,
  getStopsOption,
} from "../../../search/reducer";
import { IStoreState } from "../../../../reducers/types";
import { MobileShopHeader } from "./component";
import { getIsOutgoing } from "../../reducer/selectors";
import { getPackageSelectedLodging } from "../../../hotel-shop/reducer";
import { setPackagesFlightShopProgress } from "../../actions/actions";

const mapStateToProps = (state: IStoreState) => {
  return {
    origin: getOrigin(state),
    destination: getDestination(state),
    fromDate: getDepartureDate(state),
    untilDate: getReturnDate(state),
    adultsCount: getAdultsCount(state),
    children: getChildren(state),
    infants: getInfants(state),
    stopsOption: getStopsOption(state),
    selectedLodging: getPackageSelectedLodging(state),
    isOutgoing: getIsOutgoing(state),
  };
};

const mapDispatchToProps = {
  setPackagesFlightShopProgress,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MobileShopHeaderConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedMobileShopHeader = withRouter(
  connector(MobileShopHeader)
);
