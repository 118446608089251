import { debounce } from "lodash-es";
import { trackEvent } from "../api/v0/analytics/trackEvent";
import {
  VIEWED_CORP_RATE_DESCRIPTOR,
  ViewedCorpRateDescriptorEntryPoints,
} from "redmond";

export const eventsToListen = {
  HOST_THEME_CHANGED: "HOST_THEME_CHANGED",
  HOST_LOCALE_CHANGED: "HOST_LOCALE_CHANGED",
};

export const eventsToDispatch = {};

export const dispatchEvent = (eventName: string, data: any) =>
  window.dispatchEvent(new CustomEvent(eventName, { detail: data }));

export const onOpenCompareBarTooltip = (
  entryPoint: ViewedCorpRateDescriptorEntryPoints
) =>
  debounce(
    () =>
      trackEvent({
        eventName: VIEWED_CORP_RATE_DESCRIPTOR,
        properties: {
          entry_point: entryPoint,
        },
      }),
    2000
  );
