import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import { PackageDetailsPanel } from "./component";
import { IStoreState } from "../../../../reducers/types";
import { getDestination, getOrigin } from "../../../search/reducer";

export const mapStateToProps = (state: IStoreState) => {
  return {
    origin: getOrigin(state),
    destination: getDestination(state),
  };
};

export const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PackagesDetailsPanelConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedPackageDetailsPanel = withRouter(
  connector(PackageDetailsPanel)
);
