import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "@material-ui/core";
import clsx from "clsx";
import { ActionLink } from "halifax";
import React from "react";
import { RouteComponentProps } from "react-router";
import {
  PATH_BOOK,
  PATH_FLIGHT_SHOP,
  PATH_HOTELS_AVAILABILITY,
  PATH_HOTEL_SHOP,
  PATH_REVIEW,
} from "../../../../utils/paths";
import * as constants from "./constants";
import { PackagesShopProgressBarConnectorProps } from "./container";
import "./styles.scss";
import {
  goToAvailability,
  goToBookConfirmation,
  goToHotelShop,
  goToFlightShop,
  goToReview,
} from "../../../hotel-shop/utils/queryStringHelpers";
import { FlightShopStep } from "redmond";

interface IPackagesShopProgressBarProps
  extends PackagesShopProgressBarConnectorProps,
    RouteComponentProps {
  isMobile?: boolean;
  innerRef?: React.LegacyRef<HTMLDivElement>;
}

enum PackagesShopStep {
  HOTEL_AVAILABILITY = "hotel-availability",
  HOTEL_DETAILS = "hotel-details",
  FLIGHT_OUTBOUND_SHOP = "flight-outbound-shop",
  FLIGHT_RETURN_SHOP = "flight-return-shop",
  REVIEW_PACKAGE = "review-package",
  CONFIRM_AND_BOOK = "confirm-and-book",
}

enum CompareStepsResult {
  Before,
  Same,
  After,
  Unknown,
}

export const PackagesShopProgressBar = (
  props: IPackagesShopProgressBarProps
) => {
  const {
    history,
    isMobile,
    selectedLodging,
    origin,
    departureDate,
    returnDate,
    travelers,
    stopsOption,
    setPackagesFlightShopProgress,
    fareClassOptionFilter,
    flightShopProgress,
    innerRef,
  } = props;

  const isChoosingReturnFlight = () => {
    return flightShopProgress !== FlightShopStep.ChooseDeparture;
  };

  const ProgressButtons = ({
    progresses,
  }: {
    progresses: PackagesShopStep[];
  }) => {
    const selectedStep: PackagesShopStep = (() => {
      switch (history.location.pathname) {
        case PATH_HOTELS_AVAILABILITY:
        default:
          return PackagesShopStep.HOTEL_AVAILABILITY;

        case PATH_HOTEL_SHOP:
          return PackagesShopStep.HOTEL_DETAILS;

        case PATH_FLIGHT_SHOP:
          if (isChoosingReturnFlight()) {
            return PackagesShopStep.FLIGHT_RETURN_SHOP;
          } else {
            return PackagesShopStep.FLIGHT_OUTBOUND_SHOP;
          }

        case PATH_REVIEW:
          return PackagesShopStep.REVIEW_PACKAGE;

        case PATH_BOOK:
          return PackagesShopStep.CONFIRM_AND_BOOK;
      }
    })();

    return (
      <>
        {progresses.map((progress: PackagesShopStep, index: number) => {
          return (
            <React.Fragment key={index}>
              <PackagesShopProgressButton
                key={`button-${index}`}
                selectedStep={selectedStep}
                goToAvailability={() => {
                  setPackagesFlightShopProgress(FlightShopStep.ChooseDeparture);
                  goToAvailability({
                    history,
                    lodging: selectedLodging,
                    fromDate: departureDate,
                    untilDate: returnDate,
                    origin,
                    adultsCount: travelers.adultsCount,
                    children: travelers.children,
                    infants: travelers.infants,
                    stopsOption,
                    fareClassOptionFilter,
                  });
                }}
                goToHotelShop={() => {
                  setPackagesFlightShopProgress(FlightShopStep.ChooseDeparture);
                  goToHotelShop({ history });
                }}
                goToFlightShopOutbound={() => {
                  setPackagesFlightShopProgress(FlightShopStep.ChooseDeparture);
                  goToFlightShop({ history });
                }}
                goToFlightShopReturn={() => {
                  goToFlightShop({ history });
                }}
                goToReview={() => {
                  goToReview({ history });
                }}
                goToBookConfirmation={() => {
                  goToBookConfirmation({ history });
                }}
                progress={progress}
              />
              {index < progresses.length - 1 && (
                <FontAwesomeIcon
                  key={`separator-${index}`}
                  className="progress-button-separator"
                  icon={faChevronRight}
                />
              )}
            </React.Fragment>
          );
        })}
      </>
    );
  };

  const getProgresses = (): PackagesShopStep[] => {
    const progresses: PackagesShopStep[] = [
      PackagesShopStep.HOTEL_AVAILABILITY,
      PackagesShopStep.HOTEL_DETAILS,
      PackagesShopStep.FLIGHT_OUTBOUND_SHOP,
      PackagesShopStep.FLIGHT_RETURN_SHOP,
      PackagesShopStep.REVIEW_PACKAGE,
      PackagesShopStep.CONFIRM_AND_BOOK,
    ];

    return progresses;
  };

  return (
    <div
      className={clsx("packages-shop-progress-bar-root", { mobile: isMobile })}
      ref={innerRef}
    >
      <Box className="packages-shop-progress-bar-container">
        <ProgressButtons progresses={getProgresses()} />
      </Box>
    </div>
  );
};

interface IPackagesShopProgressButtonProps {
  selectedStep: PackagesShopStep;
  progress: PackagesShopStep;
  goToAvailability?: () => void;
  goToHotelShop?: () => void;
  goToFlightShopOutbound?: () => void;
  goToFlightShopReturn?: () => void;
  goToReview?: () => void;
  goToBookConfirmation?: () => void;
}

const PackagesShopProgressButton = (
  props: IPackagesShopProgressButtonProps
) => {
  const {
    selectedStep,
    progress,
    goToAvailability,
    goToHotelShop,
    goToFlightShopOutbound,
    goToFlightShopReturn,
    goToReview,
    goToBookConfirmation,
  } = props;

  const getProgressText = (progress: PackagesShopStep) => {
    switch (progress) {
      case PackagesShopStep.HOTEL_AVAILABILITY:
      default:
        return constants.HOTEL_AVAILABILITY_TEXT;

      case PackagesShopStep.HOTEL_DETAILS:
        return constants.HOTEL_DETAILS_TEXT;

      case PackagesShopStep.FLIGHT_OUTBOUND_SHOP:
        return constants.FLIGHT_OUTBOUND_SHOP;

      case PackagesShopStep.FLIGHT_RETURN_SHOP:
        return constants.FLIGHT_RETURN_SHOP;

      case PackagesShopStep.REVIEW_PACKAGE:
        return constants.REVIEW_PACKAGE;

      case PackagesShopStep.CONFIRM_AND_BOOK:
        return constants.CONFIRM_AND_BOOK;
    }
  };

  const compareSteps = (
    step1: PackagesShopStep,
    step2: PackagesShopStep
  ): CompareStepsResult => {
    const stepOrdering: PackagesShopStep[] = [
      PackagesShopStep.HOTEL_AVAILABILITY,
      PackagesShopStep.HOTEL_DETAILS,
      PackagesShopStep.FLIGHT_OUTBOUND_SHOP,
      PackagesShopStep.FLIGHT_RETURN_SHOP,
      PackagesShopStep.REVIEW_PACKAGE,
      PackagesShopStep.CONFIRM_AND_BOOK,
    ];

    const index1 = stepOrdering.findIndex((s) => s === step1);
    const index2 = stepOrdering.findIndex((s) => s === step2);

    if (index1 === -1 || index2 === -1) {
      return CompareStepsResult.Unknown;
    } else if (index1 === index2) {
      return CompareStepsResult.Same;
    } else if (index1 < index2) {
      return CompareStepsResult.Before;
    } else {
      return CompareStepsResult.After;
    }
  };

  const isDisabled = (
    progress: PackagesShopStep,
    selectedStep: PackagesShopStep
  ) => {
    if (compareSteps(selectedStep, progress) === CompareStepsResult.Before) {
      return true;
    }

    return false;
  };

  const handleOnClick = () => {
    if (compareSteps(selectedStep, progress) === CompareStepsResult.Same) {
      return;
    }

    switch (progress) {
      case PackagesShopStep.HOTEL_AVAILABILITY:
        if (goToAvailability) goToAvailability();
        break;
      case PackagesShopStep.HOTEL_DETAILS:
        if (goToHotelShop) goToHotelShop();
        break;
      case PackagesShopStep.FLIGHT_OUTBOUND_SHOP:
        if (goToFlightShopOutbound) goToFlightShopOutbound();
        break;
      case PackagesShopStep.FLIGHT_RETURN_SHOP:
        if (goToFlightShopReturn) goToFlightShopReturn();
        break;
      case PackagesShopStep.REVIEW_PACKAGE:
        if (goToReview) goToReview();
        break;
      case PackagesShopStep.CONFIRM_AND_BOOK:
        if (goToBookConfirmation) goToBookConfirmation();
        break;
    }
  };

  return (
    <ActionLink
      className={clsx(
        "packages-shop-progress-button",
        { selected: selectedStep === progress },
        "b2b"
      )}
      onClick={handleOnClick}
      content={getProgressText(progress)}
      disabled={isDisabled(progress, selectedStep)}
    />
  );
};
