import React from "react";
import { DesktopHotelPhotoModalConnectorProps } from "./container";
import { Button } from "@material-ui/core";

import "./styles.scss";
import {
  ActionButton,
  DesktopPopupModal,
  getImagesArray,
  HotelName,
  HotelStarRating,
  PhotoLayout,
  Icon,
  IconName,
  PackageHotelPriceAndRewardsDisplay,
} from "halifax";
import { Skeleton } from "@material-ui/lab";
import clsx from "clsx";

export interface DesktopHotelPhotoModalProps
  extends DesktopHotelPhotoModalConnectorProps {
  isSkeleton?: boolean;
}

export const DesktopHotelPhotoModal = (props: DesktopHotelPhotoModalProps) => {
  const {
    lodging,
    roomProduct,
    nightCount,
    isSkeleton,
    rewardsKey,
    packageRatesById,
    travelersCount,
  } = props;
  const [imagesArray, setImagesArray] = React.useState<string[]>([]);
  const [openModal, setOpenModal] = React.useState<boolean>(false);

  React.useEffect(() => {
    getImagesArray(lodging?.lodging.media ?? [], setImagesArray);
  }, [lodging, setImagesArray]);

  const filteredImagesArray = React.useMemo(
    () =>
      // The filter method is used to remove empty items in imagesArray
      imagesArray.filter((imageTuple) => !!imageTuple),
    [imagesArray]
  );

  return (
    <div className="desktop-hotel-photo-modal">
      {isSkeleton && (
        <Skeleton className="desktop-hotel-photo-modal-main-image-container skeleton" />
      )}
      {!isSkeleton && (
        <div
          className="desktop-hotel-photo-modal-main-image-container"
          onClick={() => {
            setOpenModal(!openModal);
          }}
        >
          <div className="desktop-hotel-photo-modal-main-image-section-left">
            <img
              className="main-image-left"
              src={filteredImagesArray[0]}
              alt="hotel-1"
            />
          </div>
          <div className="desktop-hotel-photo-modal-main-image-section-right">
            <div className="main-image-right top-image">
              <img src={filteredImagesArray[1]} alt="hotel-2" />
            </div>
            <div className="main-image-right bottom-image">
              <img src={filteredImagesArray[2]} alt="hotel-3" />
            </div>
          </div>
          <Button
            className="show-all-photos-button"
            onClick={() => {
              setOpenModal(true);
            }}
          >
            <Icon name={IconName.ShowAllImagesIcon} />
            <span className="show-all-photos-button-label">
              See all photos ({filteredImagesArray.length})
            </span>
          </Button>
        </div>
      )}
      <DesktopPopupModal
        className={clsx("desktop-hotel-photo-modal-popup", "b2b")}
        open={openModal}
        invisibleBackdrop={false}
        onClose={(event: React.MouseEvent) => {
          event.stopPropagation();
          setOpenModal(false);
        }}
      >
        <div
          id="desktop-hotel-photo-modal-container"
          className="desktop-hotel-photo-modal-container"
        >
          <div id="photo-gallery" className="photo-gallery">
            <PhotoLayout
              imgUrls={filteredImagesArray}
              scrollableTarget="photo-gallery"
            />
          </div>
          <div className="photo-gallery-hotel-info">
            <HotelStarRating
              starRating={lodging?.lodging?.starRating}
              city={lodging?.lodging?.city}
              isSkeleton={isSkeleton}
            />
            <HotelName name={lodging?.lodging?.name} isSkeleton={isSkeleton} />
            {!!roomProduct && !!lodging && roomProduct.rateId?.value && (
              <PackageHotelPriceAndRewardsDisplay
                pricing={
                  packageRatesById[roomProduct.rateId?.value].packageDetails
                    .pricing
                }
                nightCount={nightCount}
                travelersCount={travelersCount}
                rewardsKey={rewardsKey}
              />
            )}
            <ActionButton
              className="desktop-photo-modal-choose-room-cta"
              onClick={() => {
                setOpenModal(false);
                setTimeout(() => {
                  document
                    .getElementsByClassName("reserve-cta")[0]
                    ?.scrollIntoView({ behavior: "smooth", block: "end" });
                }, 300);
              }}
              message={<span className="button-text">Choose Room</span>}
            />
          </div>
        </div>
      </DesktopPopupModal>
    </div>
  );
};
