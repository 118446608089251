export const BASIC_TEXT = "Basic";
export const BASIC_DETAILS_TEXT =
  "Typically the best priced options. These are basic fares with restrictive policies and few amenities.";

export const STANDARD_TEXT = "Standard";
export const STANDARD_DETAILS_TEXT =
  "Typically main and economy fares. These include amenities like carry-on bags and seat selection.";

export const ENHANCED_TEXT = "Enhanced";
export const ENHANCED_DETAILS_TEXT =
  "These fares include more legroom, priority boarding, free beverages and snacks.";

export const PREMIUM_TEXT = "Premium";
export const PREMIUM_DETAILS_TEXT =
  "These fares include cradle or reclined seats, priority boarding, premium snacks and beverages.";

export const LUXURY_TEXT = "Luxury";
export const LUXURY_DETAILS_TEXT =
  "Luxury reclined seats with personalized services and premium meals. Priority boarding also included.";
