import React from "react";
import { Airport } from "redmond";

export const TIMES_OUTBOUND_HEADER_TEXT = "Times - Outbound";
export const TIMES_RETURN_HEADER_TEXT = "Times - Return";

export const DEPARTURE_TEXT = "Departure";
export const ARRIVAL_TEXT = "Arrival";

export const TIME = (appliedLabel?: string) =>
  !!appliedLabel ? (
    <>
      <span className="filter-name">Time:</span> {appliedLabel}
    </>
  ) : (
    <span className="filter-name">Time</span>
  );

export const getTimeRangeSubtitle = (
  originLabel: string,
  destinationLabel: string,
  originAirport?: Airport,
  destinationAirport?: Airport,
  isDeparture?: boolean,
  isReturn?: boolean
) => {
  const shortOriginLabel = originLabel.split(",")[0];
  const originLocation = originAirport
    ? originAirport.cityName
    : shortOriginLabel;

  const shortDestinationLabel = destinationLabel?.split(",")[0];
  const destinationLocation = destinationAirport
    ? destinationAirport?.cityName
    : shortDestinationLabel;

  switch (isDeparture) {
    case true:
      if (isReturn) {
        return `Departure from <strong>${destinationLocation}</strong>`;
      }
      return `Departure from <strong>${originLocation}</strong>`;
    case false:
      if (isReturn) {
        return `Arrive in <strong>${originLocation}</strong>`;
      }
      return `Arrive in <strong>${destinationLocation}</strong>`;
    default:
      return undefined;
  }
};
