import { assign } from "xstate";
import { PackagesMachineContext } from "../types";
import { SelectPassengerEvent } from "../events";
import {
  cartHelpers,
  ContactSelectors,
  FlightPassengerSelectors,
  getHasPriceChanged,
  getObjectKeysAsObject,
  ParentState,
  PriceChangeType,
  setContextWithKey,
} from "@capone/checkout";
import { LodgingSelectors } from "../selectors";
import { Product, ProductOpaqueValue } from "@b2bportal/purchase-api";
import {
  BedTypeEnum,
  HotelQuoteScheduleRequest,
  HotelStarRatingEnum,
  LodgingCollectionEnum,
} from "redmond";
import dayjs from "dayjs";

export const LodgingActions = {
  selectPassenger: assign(
    (ctx: PackagesMachineContext, event: SelectPassengerEvent) => {
      const passengerId = event.data?.id || event.passengerId;

      const newPassengerInfoCtx = {
        ...ctx[ParentState.lodgingPassengerInformation],
      };

      newPassengerInfoCtx.selectedPassengerId = passengerId;

      return {
        ...ctx,
        [ParentState.lodgingPassengerInformation]: newPassengerInfoCtx,
      };
    }
  ),
  addLodgingProduct: assign(
    (context: PackagesMachineContext, _event: unknown) => {
      const selectedUserId = LodgingSelectors.getSelectedPassengerIdParent({
        context,
      });
      if (!selectedUserId) {
        throw new Error("No hotel guest selected, unable to add hotel product");
      }
      const selectedProduct = LodgingSelectors.getSelectedRoomProduct({
        context,
      });

      if (!selectedProduct) {
        throw new Error("No selected product");
      }

      if (!context.finalizePackageResponse) {
        throw new Error("finalizePackageResponse not populated");
      }

      const contactInformation = ContactSelectors.getContactInformation({
        context,
      });

      const fromDate = LodgingSelectors.getHotelFromDate({ context });
      const untilDate = LodgingSelectors.getHotelUntilDate({ context });
      const selectedLodging = LodgingSelectors.getSelectedLodging({ context });
      const roomInfo = LodgingSelectors.getSelectedRoom({ context });
      const flightPAX =
        FlightPassengerSelectors.getAllSelectedPassengerIdsParent({
          context,
        });

      const params: HotelQuoteScheduleRequest = {
        token: "00000000-0000-0000-0000-000000000000",
        quoteRequest:
          context.finalizePackageResponse?.lodgingOpaqueQuoteRequest,
        personId: selectedUserId,
        phoneNumber: contactInformation.phoneNumber,
        emailAddress: contactInformation.email,
        dates:
          fromDate && untilDate
            ? {
                from: dayjs(fromDate).format("YYYY-MM-DD"),
                until: dayjs(untilDate).format("YYYY-MM-DD"),
              }
            : undefined,
        id: selectedLodging?.lodging.id,
        name: selectedLodging?.lodging.name,
        city: selectedLodging?.lodging.city,
        country: selectedLodging?.lodging.country,
        starRating: selectedLodging?.lodging.starRating
          ? HotelStarRatingEnum[selectedLodging?.lodging.starRating]
          : undefined,
        providerName: selectedLodging?.price?.providerName,
        bedTypes: {
          description: "",
          ...roomInfo?.roomInfo.beds.bedTypes,
          bedTypes:
            roomInfo?.roomInfo.beds.bedTypes.map((bedType) => ({
              ...bedType,
              bedType: BedTypeEnum[bedType.bedType],
            })) || [],
        },
        lodgingCollection: LodgingCollectionEnum.NoCollection,
        additionalGuests: flightPAX.filter(
          (personId) => personId !== selectedUserId
        ),
      };

      const hotelProduct: ProductOpaqueValue = {
        type: Product.Hotel,
        value: params,
      };
      const ctxWithHotel = cartHelpers.addQuoteProduct(hotelProduct, context);

      return setContextWithKey(
        ctxWithHotel,
        `${ParentState.cartQuote}.customerEmail`,
        contactInformation?.email
      );
    }
  ),

  checkForPriceChange: assign((context: PackagesMachineContext, _event) => {
    const selectedRoom = context.lodgingShop?.selectedRoom?.products?.[0];
    const shopPricing = selectedRoom?.total?.fiat;
    const quotedPricing = LodgingSelectors.getQuotedLodgingProductPricingV1({
      context,
    });

    const predictedTotal = shopPricing ? shopPricing.value : 0;
    const priceQuoteTotal = quotedPricing?.tripTotal?.fiat?.value
      ? quotedPricing.tripTotal.fiat.value
      : 0;

    const difference = Math.abs(priceQuoteTotal - predictedTotal);

    // Tenant defines allowed price change difference in respective .env file
    // const tenantDifferenceAllowed = getEnvVariables(
    //   "hotelPriceChangeDiff"
    // ) as string;
    // const allowed = tenantDifferenceAllowed.split(",");
    const allowedValue =
      // allowed?.[0] ? parseInt(allowed[0], 10) :
      Infinity;
    const allowedCurrency =
      // allowed?.[1] ? allowed[1] :
      "USD";
    const allowedDifference = {
      value: allowedValue,
      currencyCode: allowedCurrency,
    };

    const actualDifference = {
      value: difference,
      currencyCode: quotedPricing?.tripTotal?.fiat?.currencyCode
        ? quotedPricing.tripTotal.fiat.currencyCode
        : "USD",
    };
    const hasDifference = getHasPriceChanged(
      allowedDifference,
      actualDifference
    );

    const differenceString = difference.toFixed();
    const priceChange: PriceChangeType = {
      hasDifference,
      predictedTotal,
      priceQuoteTotal,
      ...(hasDifference
        ? {
            difference,
            differenceString,
          }
        : {}),
    };

    return setContextWithKey(context, `${ParentState.cartQuote}.priceChange`, {
      ...context.cartQuote.priceChange,
      priceChange,
    });
  }),
};

export const LodgingActionTypes = getObjectKeysAsObject(LodgingActions);
